import React,{Component} from "react";
import Create_element from "../create_element";
import style from  "./scss.module.scss"
import LargeButton from "../../../../../components/LargeButton";
import IconLens from '../../../../assets/icon-trash.835e6e0e.svg'
import {useTranslation} from "react-i18next";

const DeleteElementsModal = ({isOpen, onCancel=()=>null , onDelete=()=>null})=>{

       const {t} = useTranslation();

        let class_name = "configure_course_modal_wrapper ";
        class_name += isOpen ? "configure_course_modal_open" : "";

        return(
            <div className={`${class_name} ${style.configure_course_modal_wrapper}`} onClick={(e) => {
                if(e.target.className === class_name){
                    onCancel();
                }
            }}>

                <div className={`NE_body ${style.NE_body}`}>
                    <div className="NE_text">
                        <h3 className="NE_text_body"></h3>
                        <h3 className={`NE_text_body _close_button ${style._close_button}`} onClick={()=>{
                            onCancel();
                        }} >x</h3>
                    </div>
                    <div className="NE_body_center" />
                    <div className="NE_search_bar_">
                        <h1 className={style.msg_title} >
                            <img src={IconLens} alt={"delete"}/>
                            {t("moderator.delete_element")}
                        </h1>
                        <div className="NE_icon_input NE_icon_input_success NE_icon_input_error">
                            <form action="">
                                <div className="form-actions">
                                    <div className="form-action">
                                        <LargeButton value={"Annuler"}  onClick={onCancel}/>
                                    </div>
                                    <div className="form-action">
                                        <LargeButton  button={"dark"} value={"Supprimer"} onClick={onDelete} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        );

}

export  default DeleteElementsModal;
