import React, { Component, useEffect } from "react";
import './index.css'
import NormalButton from "../../../components/NormalButton";
import { connect } from "react-redux";
import addIcon from '../configure-course/assets/add_element.svg'
import cloneIcon from '../../assets/icon-clone.svg'
import icon from './assets/icon.svg'

import add from './assets/add.svg'
import icon_module from './assets/module.svg'
import icon_trophy from './assets/trophy.svg'
import icon_document from './assets/documents.svg'
import icon_evaluation from './assets/evaluation.svg'
import addEnabled from './assets/add-enabled.svg';
import { Translation } from "react-i18next";
// import styles from "./style.module.css"
import CloneElementModal from "../configure-course/components/clone-element-modal";
import Header from "../../../components/Header";
import { loadAllCourses, loadCourseById } from "../../../redux/moderator/courses-list/action-creators";
import {
    addBusinessGame,
    addCourse,
    addCourseDocumentsElement,
    addDocuments,
    addElement,
    addElementToCourse,
    addModule,
    addNotion,
    addQuiz,
    changeCoursePropValue,
    changeCoursePropValueById,
    changeCurrentCourseElementPropValue,
    changeCurrentCoursePropValue,
    changeCurrentCourseValidationPropValue,
    changeCurrentElementPropValue,
    changeDocumentPropValue,
    changeElementPropValue,
    changeModulePropValue,
    changeNotionPropValue,
    changeOrderPropValue,
    changeQuizPropValue,
    deleteCurrentCourseDocumentsElementItem,
    editTypElement,
    loadAllCategories,
    loadAllGames,
    loadCourseContent,
    pushCurrentElement,
    pushElement,
    setCurrentStep
} from "../../../redux/moderator/configure-course/actions-creators"
import CoursePublishOverview from "../../../components/CoursePublishOverview";
import DocumentsUploader from "../../../components/DocumentsUploader";
import ToggleBarButton from "../../../components/ToggleBarButton";
import CollapseCardContainer from "../../../components/CollapseCardContainer";
import NormalInput from "../../../components/NormalInput";
import LargeButton from "../../../components/LargeButton";
import Textarea from "../../../components/Textarea";
import ChooseBusinessGameModal from "../../../components/ChooseBusinessGameModal";
import DraftEditor from "../../../components/DraftEditor";
import { uid } from "uid";
import ImagesUploader from "../../../components/ImagesUploader";
import Dropdown2 from "../../../components/Dropdown2";
import history from "../../../../utils/history";
import DocumentsCard from "./components/DocumentsCard";
import EvaluationCard from "./components/EvaluationCard";
import { description_schema, image_size_schema, title_schema } from "../../../validation/validations";
import BusinessGameModal from "./components/business-game-modal";
import Create_element from "./components/create_element";
import CreateElementsModal from "./components/create-elements-modal";
import ModuleCard from "./components/ModuleCard";
import BusinessCard from "./components/BusinessCard";
import * as PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import Loader from "../../../components/Loader";



function CreateCourseComponent(props) {
    const {
        onChange,
        onChange1,
        onImageChange,
        onClick,
        onClick1,
        requiredImg,
        onSave,
        editMode
    } = props;
    const { register, formState: { errors }, handleSubmit, control, setValue } = useForm();
    const onSubmit = data => {
        props.onSave(data);
        props.onClick1();
    };

    useEffect(() => {

        if (props.initial !== null) {
            setValue("title", props.initial?.title);
            setValue("description", props.initial?.description);
            setValue("category", props.initial?.category || {});
        }

    }, [props.initial]);



    return (
        <Translation>
            {t =>
                <>

                    <div className="create_course_form_wrapper2">
                        <h1>{t("moderator.create_course.create_course_text")}</h1>

                        <div className="form-field">
                            <Controller
                                control={control}
                                name="title"
                                rules={{
                                    required: {
                                        value: true,
                                        message: t("moderator.create_course.title_required_msg")
                                    }
                                }}

                                render={({
                                    field,
                                }) => (
                                    <NormalInput
                                        {...field}
                                        isValid={props.currentCourseValidation?.title.is_valid}
                                        message={errors.Title?.message}
                                        onChange={(e) => {
                                            onChange(e);
                                            field.onChange(e)
                                        }}
                                        //  value={props.currentCourse.title}
                                        label={t("moderator.create_course.course_title_text")}
                                        type="text"
                                        id="title"
                                        placeholder={t("moderator.create_course.course_title_placeholder")}

                                    />
                                )}
                            />


                        </div>
                        <div className="form-field">
                            {/*<Dropdown onChange={(e) => {}} label="Category" category={[]} id="category"/>*/}
                            {props.categoriesDropdownItems.length > 0 &&
                                <Controller
                                    control={control}
                                    name="category"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: t("moderator.create_course.category_required_msg")
                                        }
                                    }}
                                    render={({
                                        field,
                                    }) => (<>
                                        <Dropdown2
                                            {...field}
                                            label={t("moderator.create_course.course_category_text")}
                                            placeholder={t("moderator.create_course.course_category_placeholder")}
                                            items={props.categoriesDropdownItems}
                                            onChange={(e) => {
                                                onChange1(e);
                                                field.onChange(e);
                                            }}
                                        />
                                        <p style={{ color: "red" }}>{errors.Category?.message}</p>
                                    </>
                                    )}
                                />

                            }
                        </div>
                        <div className="form-field">

                            <Controller
                                control={control}
                                name="description"
                                rules={{
                                    required: {
                                        value: true,
                                        message: t("moderator.create_course.description_required_msg")
                                    }
                                }}

                                render={({
                                    field,
                                }) => (<>
                                    <Textarea
                                        {...field}
                                        isValid={props.currentCourseValidation?.description.is_valid}
                                        message={props.currentCourseValidation?.description.error}
                                        onChange={(e) => {
                                            props.onChange2(e);
                                            field.onChange(e)
                                        }}
                                        label={t("moderator.create_course.course_desc_text")}
                                        id="description"
                                        placeholder={t("moderator.create_course.course_desc_placeholder")} />
                                </>
                                )}
                                defaultValue={props.currentCourse?.description}
                            />


                        </div>
                        <div className="form-field">
                            <div className="NE_images_uploader">
                                <Controller
                                    control={control}
                                    name="img"
                                    rules={{
                                        required: {
                                            value: requiredImg,
                                            message: t("moderator.create_course.upload_img_required_msg")
                                        }
                                    }}

                                    render={({
                                        field,
                                    }) => (<>
                                        <ImagesUploader client='moderator' onImageChange={(e) => {
                                            onImageChange(e);
                                            field.onChange(e);
                                        }} />
                                        <p style={{ color: "red" }}>{errors.img?.message}</p>
                                    </>
                                    )}
                                />

                            </div>
                        </div>
                        <div className={`${props.className_actions} form-actions`}>
                            <div className="form-action">
                                <NormalButton value={t("moderator.create_course.cancel_button")} onClick={onClick} />
                            </div>
                            <div className="form-action">
                                <NormalButton button="dark" value={!editMode ? t("moderator.create_course.create_button") : t('moderator.edit_course.save_button')}
                                    onClick={handleSubmit(onSubmit)}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        </Translation>
    );
}


CreateCourseComponent.propTypes = {
    currentCourseValidation: PropTypes.any,
    onChange: PropTypes.func,
    currentCourse: PropTypes.any,
    categoriesDropdownItems: PropTypes.any,
    onChange1: PropTypes.func,
    onChange2: PropTypes.func,
    onImageChange: PropTypes.func,
    onClick: PropTypes.func,
    onClick1: PropTypes.func,
    className_actions: PropTypes.any,
    initial: PropTypes.any,
    requiredImg: PropTypes.bool,
    onSave: PropTypes.func,
};

CreateCourseComponent.defaultProps = {
    onChange: () => null,
    onChange1: () => null,
    onChange2: () => null,
    onImageChange: () => null,
    onClick: () => null,
    onClick1: () => null,
    onSave: () => null,
    className_actions: "",
    initial: null,
    requiredImg: true,
    editMode: false
};

class ConfigureCourse extends Component {

    INITIAL_STATE = {
        is_create_user_modal_open: false
    }

    constructor(props) {
        super(props);
        this.state = {
            order: 0,
            is_games_modal_open: false,
            step: 2,
            documents: '',
            numChildren: 0,
            quizValue: '',
            isChecked: false,
            isBoxVisible: {
                op1: 'question_option',
                op2: 'question_option question_option_selected',
            },
            isOpen: false,
            is_create_user_modal_open: false,
            is_business_modal_open: false,
            show_create_elements_modal: false,
            current_element: {},
            showButtons: true,
            isLoading: false
        }
    }

    componentDidMount() {
        // Dispatch action to load all course categories
        this.props.loadAllCategories();
        this.props.loadAllGames();

        // Get course_id from location state
        if (this.props.location.state) {
            const { course_id } = this.props.location.state;

            if (course_id) {
                // Dispatch action to set current step
                this.props.setCurrentStep("CONFIGURE_ELEMENT");

                // Dispatch action to load course
                this.props.loadCourseContent(course_id);
            }
        } else {
            this.props.setCurrentStep("CREATE_COURSE");
        }

    }


    //Handle Previous Step
    previousStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        })
    }
    //Handle Next Step
    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        })
        if (step === -1) {
            this.handleAddElementToCourse({
                id: 0,
                title: '',
                description: ''
            });
        }
    }


    changeStep = (step) => {
        this.setState({
            step: step
        })
        if (step === 3) {
            this.handleModuleChangePropValue()
        }
    }


    handleAddClientModalCancel = () => {
        this.setState({
            is_create_user_modal_open: false
        })
    }

    handleBusinessModalCancel = () => {
        this.setState({
            is_business_modal_open: false
        })
    }

    showModalView = () => {
        this.setState({
            is_create_user_modal_open: true
        })
    }

    showBusinessModalView = () => {
        this.setState({
            is_business_modal_open: true
        })
    }

    handleAddElementToCourse = (obj) => {
        const { addElementToCourse, changeCoursePropValue } = this.props;
        const { _elements } = this.props.moderator_configure_course
        // changeCoursePropValue("_elements", obj)
        changeCoursePropValue("_elements", [..._elements, obj])

        console.log('obj ', obj)

    }

    //Element ------ start
    handleAddElementClick = () => {

        // Dispatch action to set current step
        this.props.setCurrentStep("CREATE_ELEMENT");

        // Dispatch action to affect a new id to the current element
        const currentElementId = uid();
        this.props.changeCurrentElementPropValue("id", currentElementId);

        // const {step} = this.state;
        // const {changeCurrentElementPropValue} = this.props
        //
        //
        //
        // changeCurrentElementPropValue("id", currentElementId);
        // this.setState({
        //     step: step + 1
        // })
    }

    handleCurrentElementPropValueChange = (propName, propValue) => {
        const { changeCurrentElementPropValue } = this.props
        changeCurrentElementPropValue(propName, propValue);
    };

    handleCreateElementClick = () => {
        const { setCurrentStep, pushCurrentElement } = this.props;


        setCurrentStep("CONFIGURE_ELEMENT");
        const { step } = this.state;
        pushCurrentElement();
        this.setState({
            step: step + 1
        })

    }
    //Element ------  end

    handleModuleChangePropValue = () => {
        const { changeModulePropValue } = this.props;
        const { element_order } = this.props.moderator_configure_course;
        const id = Number(this.props.match.params.id);
        const { courses_list } = this.props.moderator_courses_list;
        const current_course = courses_list?.filter(item => item.course.id === id)[0]

        // Dispatch action to change Module prop value
        changeModulePropValue("title", current_course.course.title);
        changeModulePropValue("description", current_course.course.description);
        changeModulePropValue("course_id", current_course.course.id);
        changeModulePropValue("element_order", element_order);

    }


    // HandleDocument Start
    handleDocumentChangePropValue = (propName, propValue) => {
        const { changeDocumentPropValue } = this.props;

        // console.log(this.props.changePropValue, ' here');

        // Dispatch action to change login prop value

        changeDocumentPropValue(propName, propValue);

    };


    // this function is used for document and notion
    handleDocumentChange = (rawName, raw) => {

        const id = Number(this.props.match.params.id);
        const { changeDocumentPropValue } = this.props;
        const { document } = this.props.moderator_configure_course


        changeDocumentPropValue("course_id", id);
        changeDocumentPropValue("attached_documents", [...document.attached_documents, {
            "title": rawName,
            "file_path": raw
        }]);

    }

    addNotion = () => {
        let class_name = "element_adder ";
        class_name += "element_adder";
        return (
            <div className={class_name}>
                <div className="create_course_form_wrapper">
                    <div className="form-field">
                        <NormalInput id="title" onChange={(event) => {
                            this.handleAddElementToCourse("title", event.target.value)
                        }}
                            name='title' label={"Title"} isValid={""} message={""} type="text"
                            placeholder={"Element title"} />
                    </div>
                    <div className="form-field">
                        <div className="NE_normal_textarea NE_normal_textarea_success NE_normal_textarea_error">
                            <Textarea id="description" name='description' onChange={(event) => {
                                this.handleAddElementToCourse("description", event.target.value)
                            }} label={"Description"} isValid={""} message={""} type="text"
                                placeholder={"Description about the course"} />
                        </div>
                    </div>
                    <div className="form-actions">
                        <div className="form-action">
                            <LargeButton value={"Annuler"} />
                        </div>
                        <div className="form-action">
                            <LargeButton button={"dark"}
                                value={"Create Element"} onClick={this.nextStep} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    handleDocumentSubmit = () => {
        const id = Number(this.props.match.params.id);
        const { document, element_order } = this.props.moderator_configure_course;
        const { changeDocumentPropValue } = this.props;

        if (element_order === 0) {
            this.props.addDocuments({
                document
            });
        } else {
            changeDocumentPropValue("element_order", Number(element_order));
            this.props.addDocuments({
                document
            });
        }
        changeDocumentPropValue("element_order", Number(element_order) + 1);

    }
    // HandleDocument End


    // HandleNotion Start
    handleNotionChangePropValue = (propName, propValue) => {
        const { changeNotionPropValue } = this.props;

        // Dispatch action to change notion prop value
        changeNotionPropValue(propName, propValue);

    };

    handleDocumentNotionChange = (rawName, raw) => {

        const id = Number(this.props.match.params.id);
        const { changeNotionPropValue } = this.props;
        const { notion } = this.props.moderator_configure_course


        // changeNotionPropValue("course_id", id);
        changeNotionPropValue("attached_documents", [...notion.attached_documents, {
            "title": rawName,
            "file_path": raw
        }]);

    }

    getNotionDataFromChild = (data) => {
        const { module } = this.props.moderator_configure_course;
        const { changeNotionPropValue } = this.props;

        changeNotionPropValue('content', data);

    }

    handleNotionSubmit = () => {
        const { notion, element_order } = this.props.moderator_configure_course;
        const { changeOrderPropValue } = this.props;

        if (element_order === 0) {
            this.props.addNotion({
                notion
            });
        } else {
            changeOrderPropValue("element_order", Number(element_order));
            this.props.addNotion({
                notion
            });
        }
        changeOrderPropValue("element_order", Number(element_order) + 1);
    }

    // HandleNotion End


    //Handle Quiz start


    handleQuizChangePropValue = (propName, propValue) => {
        const { changeQuizPropValue } = this.props;
        changeQuizPropValue(propName, propValue);
        // const {quiz, quizs} = this.props.moderator_configure_course;
        // Dispatch action to change notion prop value
        // changeDocumentPropValue("attached_documents",[...document.attached_documents, {"title": rawName, "file_path": raw}]);
        // changeQuizPropValue("questions",...quiz.questions, {"title": });


    };

    onAddChild = () => {
        const { quiz } = this.props.moderator_configure_course;
        const { changeQuizPropValue } = this.props;


        changeQuizPropValue("questions", [...quiz.questions, {
            "editMode": false,
            "title": "q1",
            "element_order": 1,
            "options": [
                {
                    "content": "q1 op1",
                    "is_correct": 0,
                    "element_order": 1
                }
            ]
        }
        ])

        // console.log(quiz , ' ' , quiz)
    }

    handleQuestionEdit = (e) => {
        const { changeQuizPropValue } = this.props;
        // changeQuizPropValue("questions", [...quiz.questions, {
        //     "editMode": true,
        // }
        // ])

    }

    handleRemove = (e) => {
        // console.log(e.target)
    }

    handleCreateCourseClick = () => {
        const { current_course } = this.props.moderator_configure_course;

        // Dispatch action to create course
        this.props.addCourse(current_course);
    }

    handleElementTypeToggleBarItemClick = (selected_item, element) => {
        const { editTypElement } = this.props;


        if (selected_item.value === "BUSINESS_GAME")
            return this.setState({
                is_business_modal_open: true,
                current_element: element
            });

        if (selected_item.value === "MODULE") {
            const current_course = this.props.moderator_configure_course.current_course

            this.setState({
                isLoading: true
            })

            this.props.addModule({
                ...element,
                course_id: current_course.id,
                element_order: current_course.elements.findIndex((e) => e._id === element._id),
            }, () => {
                editTypElement({ ...element, type: selected_item.value, icon: selected_item.icon });
                this.setState({
                    isLoading: false
                })
            })
        } else {
            editTypElement({ ...element, type: selected_item.value, icon: selected_item.icon })
        }

    }

    handleModuleElementToggleBarItemClick = (selected_item) => {
        // const {changeCurrentElementPropValue} = this.props;
        //
        // // Dispatch action to set current item type property
        // changeCurrentElementPropValue("type", selected_item.value);

    }

    handleElementAttachedDocumentsChange = (element, documents) => {
        const { changeCurrentCourseElementPropValue } = this.props;

        // Dispatch action to change element documents
        changeCurrentCourseElementPropValue(element, "attached_documents", documents);
    }

    handleElementAttachedDocumentDelete = (element, document) => {
        const { deleteCurrentCourseDocumentsElementItem } = this.props;

        // Dispatch action to delete element document
        deleteCurrentCourseDocumentsElementItem(element, document);
    }

    handleSaveDocumentsElement = (element) => {
        const { addCourseDocumentsElement } = this.props;
        const { current_course } = this.props.moderator_configure_course;


        // Dispatch action to save documents element
        addCourseDocumentsElement(current_course.id, element, () => {
            this.setState({
                showButtons: false
            })
        });
    }

    closeCreateElementsModal = () => {
        this.setState({
            show_create_elements_modal: false
        })
    }

    saveCreateElementsModal = () => {
        this.setState({
            show_create_elements_modal: false
        })
    }

    handleCourseChange = (propName, propValue) => {
        const { changeCurrentCoursePropValue } = this.props

        // Dispatch action to change register prop value
        changeCurrentCoursePropValue(propName, propValue);
        this.validateCurrentCourseProp(propName, propValue);
    }

    validateCurrentCourseProp = (propName, propValue) => {

        // Validate and Set the field value in the local state
        const { changeCurrentCourseValidationPropValue } = this.props

        switch (propName) {

            case 'avatar_path':
                const image_error = propValue ? "" : "Avatar is required";
                const { error: image_size_error } = image_size_schema.validate((propValue && propValue.size) ?? 0);
                changeCurrentCourseValidationPropValue(propName, {
                    is_valid: !image_size_error && image_error === "",
                    errors: [
                        image_error,
                        image_size_error ? image_size_error.message : ""
                    ]
                });
                break;

            case 'title':
                const { error: title_error } = title_schema.validate(propValue);
                changeCurrentCourseValidationPropValue(propName, {
                    is_valid: !title_error,
                    error: title_error ? title_error.message : ""
                });
                break;

            case 'description':
                const { error: description_error } = description_schema.validate(propValue);
                changeCurrentCourseValidationPropValue(propName, {
                    is_valid: !description_error,
                    error: description_error ? description_error.message : ""
                });
                break;


            default:
                break
        }
    }

    render() {
        const {
            current_step,
            current_course,
            categories_list,
            current_element,
            alerts,
            is_loading,
            element,
            notion,
            document,
            module,
            element_order,
            quiz,
            current_course_validation
        } = this.props.moderator_configure_course;
        const { changeCurrentCoursePropValue } = this.props;

        // Prepare categories dropdown items
        const categoriesDropdownItems = categories_list.map(category => {
            return {
                id: category.id,
                name: category.title,
                disabled: false,
            };
        });

        // Prepare toggle bar button items
        const toggleBarButtonItems = [
            {
                icon: icon_module,
                name: "Module",
                value: "MODULE"
            },
            {
                icon: icon_document,
                name: "Documents",
                value: "DOCUMENTS"
            },
            {
                icon: icon_trophy,
                name: "serious Game",
                value: "BUSINESS_GAME"
            },
            {
                icon: icon_evaluation,
                name: "Evaluation",
                value: "EVALUATION"
            },
        ];

        // Prepare toggle bar button items for module type
        const toggleBarButtonModuleItems = [
            {
                icon: icon_module,
                name: "Notion",
                value: "NOTION"
            },
            {
                icon: icon_document,
                name: "Quiz",
                value: "QUIZ"
            },
        ];

        return (
            <Translation>
                {
                    t =>
                        <>
                            <Loader isOpen={this.state.isLoading} />

                            <div className="moderator_configure_course_page">
                                <div className="page_header">
                                    <Header alerts={alerts} isLoading={is_loading} />
                                </div>

                                <div className="page_body">
                                    {current_step !== "CREATE_COURSE" &&
                                        <div className="course_publish_overview_wrapper">
                                            <CoursePublishOverview courseData={current_course ? current_course : {}} />
                                        </div>
                                    }

                                    <div className="create_course_form_wrapper">
                                        {current_step === "CREATE_COURSE" &&
                                            <CreateCourseComponent currentCourseValidation={current_course_validation}
                                                onChange={(e) => {
                                                    changeCurrentCoursePropValue("title", e.target.value)
                                                }} currentCourse={current_course}
                                                categoriesDropdownItems={categoriesDropdownItems}
                                                onChange1={(selected_item) => {
                                                    changeCurrentCoursePropValue("category", selected_item)
                                                }} onChange2={(e) => {
                                                    changeCurrentCoursePropValue("description", e.target.value)
                                                }} onImageChange={(image) => {
                                                    changeCurrentCoursePropValue("image", image)
                                                }} onClick={() => {
                                                    history.push("/moderator/");

                                                    // history.goBack()
                                                }} onClick1={this.handleCreateCourseClick} />
                                        }
                                        {/*CREATE COURSE END*/}

                                        <CreateElementsModal
                                            isOpen={this.state.show_create_elements_modal}
                                            onCancel={this.closeCreateElementsModal}
                                            onSave={this.saveCreateElementsModal}
                                        />
                                        {current_step === "ADD_CLONE_ELEMENT" &&
                                            <div className="actions">
                                                <div className="action action_add_element" onClick={() => {
                                                    this.handleAddElementClick()
                                                }}>
                                                    <div>
                                                        <img src={addIcon} alt="Add Element icon" />
                                                        <p>{t("moderator.add_element")}</p>
                                                    </div>
                                                </div>

                                                <div className="action action_clone_element" onClick={() => {
                                                    this.showModalView()
                                                }}>
                                                    <div>
                                                        <img src={cloneIcon} alt="Clone Element icon" />
                                                        <p>{t("moderator.clone_element")} </p>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {/*Add element start*/}
                                        {current_step === "CREATE_ELEMENT" &&
                                            <Create_element />
                                        }
                                        {/*{current_step === "CREATE_ELEMENT"   &&*/}
                                        {/*    <Create_element*/}

                                        {/*        currentElement={current_element}*/}
                                        {/*        onChange={(event) => {*/}
                                        {/*            this.handleCurrentElementPropValueChange("title", event.target.value)*/}
                                        {/*        }}*/}
                                        {/*        onChange1={(event) => {*/}
                                        {/*            this.handleCurrentElementPropValueChange("description", event.target.value)*/}
                                        {/*        }}*/}
                                        {/*        onClick={() => {*/}
                                        {/*            this.handleCreateElementClick()*/}
                                        {/*        }}*/}

                                        {/*    />*/}
                                        {/*}*/}

                                        {/*Select Something*/}
                                        {current_step === "CONFIGURE_ELEMENT" &&
                                            <>
                                                {current_course?.elements.map((element, index) => {
                                                    switch (element.type) {
                                                        case "DOCUMENTS":
                                                            return (
                                                                <DocumentsCard
                                                                    key={index}
                                                                    element={element}
                                                                    onUpdateAttachedDocuments={(documents) => {
                                                                        this.handleElementAttachedDocumentsChange(element, documents);
                                                                    }}
                                                                    onDeleteAttachedDocument={(element, document) => {
                                                                        this.handleElementAttachedDocumentDelete(element, document);
                                                                    }}
                                                                    // onSave={(element) => {
                                                                    //     this.handleSaveDocumentsElement(element);
                                                                    // }}
                                                                    showButtons={this.state.showButtons}
                                                                />
                                                            );


                                                        case "EVALUATION":
                                                            return (
                                                                <EvaluationCard
                                                                    key={index}
                                                                    element={element}
                                                                />

                                                            );


                                                        case "MODULE":
                                                            return (
                                                                <ModuleCard
                                                                    key={element.id}
                                                                    element={element}
                                                                />

                                                            );
                                                            break;

                                                        case "BUSINESS_GAME":
                                                            return (<BusinessCard current_course={current_course}
                                                                element={element} key={element.id} />

                                                            );

                                                        default:
                                                            return (<CollapseCardContainer title={element.title}>
                                                                {!current_element.type &&
                                                                    <ToggleBarButton element={element}
                                                                        items={toggleBarButtonItems}
                                                                        onClick={this.handleElementTypeToggleBarItemClick} />}
                                                                {current_element.type && current_element.type === "MODULE" &&
                                                                    <ToggleBarButton items={toggleBarButtonModuleItems}
                                                                        onClick={this.handleModuleElementToggleBarItemClick} />
                                                                }
                                                            </CollapseCardContainer>);
                                                            break;
                                                    }

                                                })}
                                                {!current_course.publish &&
                                                    <div className="btn_add_element" onClick={() => {
                                                        this.setState({
                                                            show_create_elements_modal: true
                                                        })
                                                    }} />}
                                            </>
                                        }
                                        {/*adding Document*/}

                                        {current_step === "CREATE_DOCUMENT" &&
                                            <div className="create_course_form_wrapper">
                                                <div
                                                    className="NE_collapsable_card_with_icon NE_collapsable_card_with_icon_open document_creation_card">
                                                    <div className="header">
                                                        <div className="title">
                                                            <img src={icon} alt="Container icon" />
                                                            <h2>{element.title}</h2>
                                                        </div>
                                                        <span className="btn_collapse" />
                                                    </div>
                                                    <div className="body">
                                                        <div className="title"
                                                            style={{ 'display': 'flex', 'alignItem': 'center' }}>
                                                            <img src={icon} alt="Container icon"
                                                                style={{ 'marginRight': '8px' }} />
                                                            <h2>{t("moderator.edit_course.notion_title_text")}</h2>
                                                        </div>
                                                        <div className="form-from">
                                                            <div className="form-field">
                                                                <NormalInput value={document.title}
                                                                    onChange={(event) => {
                                                                        this.handleDocumentChangePropValue("title", event.target.value)
                                                                    }}
                                                                    label={t("moderator.edit_course.document_title_text")}
                                                                    placeholder={t("moderator.edit_course.document_title_placeholder")}
                                                                    id={"title"}
                                                                    message={"This is a message"}
                                                                    name='title' />
                                                            </div>
                                                            <div className="form-field">
                                                                <DocumentsUploader
                                                                    onDocumentChange={this.handleDocumentChange} />
                                                            </div>
                                                            <div className="_actionsDocument">
                                                                <div className="_actionsbutton">
                                                                    <div className="action">
                                                                        <LargeButton value={"Cancel"} />
                                                                    </div>
                                                                    <div className="action">
                                                                        <LargeButton button={"dark"} value={"Save"}
                                                                            onClick={this.handleDocumentSubmit} />
                                                                    </div>
                                                                </div>
                                                                <div className="toggle_button">
                                                                    <img src={add} alt="Toggle button" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="_wrapper">
                                                            <div className="add_enabled">
                                                                <img src={addEnabled} alt="add enabled" onClick={() => {
                                                                    console.log('ok')
                                                                }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {/*adding Notion*/}
                                        {current_step === "CREATE_NOTION" &&
                                            <div className="create_course_form_wrapper">
                                                <div
                                                    className="NE_collapsable_card_with_icon NE_collapsable_card_with_icon_open notion_creation_card">
                                                    <div className="header">
                                                        <div className="title">
                                                            <img src={icon} alt="Container icon" />
                                                            <h2>{element.title}</h2>
                                                        </div>
                                                        <span className="btn_collapse" />
                                                    </div>
                                                    <div className="body">
                                                        <div className="title"
                                                            style={{ 'display': 'flex', 'alignItem': 'center' }}>
                                                            <img src={icon} alt="Container icon"
                                                                style={{ 'marginRight': '8px' }} />
                                                            <h2>Notion Title</h2>
                                                        </div>
                                                        {/*<form>*/}
                                                        <div className="form-field">
                                                            <NormalInput value={notion.title} onChange={(event) => {
                                                                this.handleNotionChangePropValue("title", event.target.value)
                                                            }} label={t("moderator.edit_course.notion_title_text")}
                                                                placeholder={t("moderator.edit_course.notion_title_placeholder")}
                                                                id={"title"}
                                                                message={"This is a message"} name='title' />
                                                        </div>
                                                        <div className="form-field">
                                                            <NormalInput value={notion.media_path}
                                                                onChange={(event) => {
                                                                    this.handleNotionChangePropValue("media_path", event.target.value)
                                                                }} label={"Media"}
                                                                placeholder={t("moderator.edit_course.add_media_link")}
                                                                id={"media_path"}
                                                                message={"This is a message"} />
                                                        </div>
                                                        <div className="form-field">
                                                            <DocumentsUploader
                                                                onDocumentChange={this.handleDocumentNotionChange} />
                                                        </div>
                                                        {/* This textarea will be changed by the Wysiwyg editor plug-in */}
                                                        <div className="form-field">
                                                            <DraftEditor
                                                                getDataFromChild={this.getNotionDataFromChild} />
                                                            {/*<Textarea value={notion.content} onChange={(event) => {*/}
                                                            {/*    this.handleNotionChangePropValue("content", event.target.value)*/}
                                                            {/*}} placeholder={"Page content"} id={"content"} message={"This is a message"}/>*/}
                                                        </div>
                                                        <div className="actions">
                                                            <div className="action">
                                                                <LargeButton value={"Cancel"} />
                                                            </div>
                                                            <div className="action">
                                                                <LargeButton button={"dark"} value={"Save"}
                                                                    onClick={this.handleNotionSubmit} />
                                                            </div>
                                                        </div>
                                                        {/*</form>*/}
                                                        <div className="_wrapper">
                                                            <div className="form_wrapper">
                                                                <div
                                                                    className="NE_toggle_bar_button NE_toggle_bar_button_open">
                                                                    <div className="toggle_button">
                                                                        <img src={add} alt="Toggle button" />
                                                                    </div>
                                                                    <div className="actions_bar">
                                                                        <div className="action">
                                                                            <img src={icon_trophy} alt="Bar action" />
                                                                            <p>Notion</p>
                                                                        </div>
                                                                        <div className="action">
                                                                            <img src={icon_evaluation}
                                                                                alt="Bar action" />
                                                                            <p>Quiz</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="add_enabled" onClick={() => {
                                                                console.log('hi')
                                                            }}>
                                                                <img src={addEnabled} alt="add enabled" />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {/*Adding Quiz*/}
                                        {current_step === "CREATE_QUIZ" &&
                                            <div className="create_course_form_wrapper">
                                                <div
                                                    className="NE_collapsable_card_with_icon NE_collapsable_card_with_icon_open quiz_creation_card">
                                                    <div className="header">
                                                        <div className="title">
                                                            <img src={icon} alt="Container icon" />
                                                            <h2>{t("moderator.edit_course.element_title_text")}</h2>
                                                        </div>
                                                        <span className="btn_collapse" />
                                                    </div>
                                                    <div className="body">
                                                        <form action="#" method="post">
                                                            <div className="form-field">
                                                                <div className="question">
                                                                    <div className="NE_normal_input">
                                                                        <label htmlFor="question1">Question</label>
                                                                        <input type="text" id="question1" name="title"
                                                                            placeholder="Write your question"
                                                                            onChange={(event) => {
                                                                                this.handleQuizChangePropValue("title", event.target.value)
                                                                            }} />
                                                                        <p>This is a message</p>
                                                                    </div>
                                                                    <ul className="question_options">
                                                                        {/*<li class="question_option question_option_selected question_option_edit_mode">*/}

                                                                        {quiz?.questions?.map((item, key) => {
                                                                            let className = "question_option ";
                                                                            const { isOpen } = this.state;
                                                                            className += isOpen ? "question_option_edit_mode" : "";
                                                                            return (
                                                                                <li key={key} className={className}
                                                                                    onClick={this.handleQuestionEdit}>
                                                                                    <div className="NE_radio_button">
                                                                                        <input type="radio" name="r1"
                                                                                            id={key} />
                                                                                        <label htmlFor={key}>
                                                                                            {item.title}
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="NE_normal_input">
                                                                                        <input type="text"
                                                                                            placeholder="Write your option" />
                                                                                        <p>This is a message</p>
                                                                                    </div>
                                                                                    <span key={key}
                                                                                        className="question_option_action question_option_edit_mode"
                                                                                        onClick={this.handleRemove} />
                                                                                </li>
                                                                            )
                                                                        })}
                                                                        <li className="add_question_option"
                                                                            onClick={this.onAddChild}>
                                                                            {t("moderator.edit_course.add_option_text")}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="add_question" onClick={() => {
                                                                console.log('hi')
                                                            }} />

                                                        </form>

                                                    </div>

                                                </div>
                                                <div className="actions">
                                                    <div className="action">
                                                        <LargeButton value={"Cancel"} />
                                                    </div>
                                                    <div className="action">
                                                        <LargeButton button={"dark"} value={"Save"}
                                                            onClick={this.handleNotionSubmit} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <ChooseBusinessGameModal
                                        isOpen={this.state.is_games_modal_open}
                                    />
                                    <BusinessGameModal
                                        toggleBarButtonItem={toggleBarButtonItems[2]}
                                        current_element={this.state.current_element}
                                        isOpen={this.state.is_business_modal_open}
                                        editTypElement={(elm, client_affectation_id, game_id) => {
                                            console.log({ elm, client_affectation_id, game_id })
                                            const { current_course } = this.props.moderator_configure_course;
                                            this.props.addBusinessGame(current_course.id, current_course.elements.findIndex((e) => e._id === elm._id), game_id, client_affectation_id, elm._id, elm.stratedge_configuration_name)
                                            this.props.editTypElement(elm);
                                        }}
                                        onCancel={this.handleBusinessModalCancel}
                                    />
                                    <CloneElementModal courses_list={this.props.moderator_courses_list.courses_list}
                                        isOpen={this.state.is_create_user_modal_open}
                                        onCancel={this.handleAddClientModalCancel} />
                                </div>
                            </div>
                        </>
                }
            </Translation>
        );
    }
}

const mapStateToProps = state => {
    const { moderator_courses_list, moderator_configure_course } = state;
    return { moderator_courses_list, moderator_configure_course };
};

const mapActionsToProps = {
    loadCourseById,
    loadAllCourses,
    addElement,
    addDocuments,
    addNotion,
    addQuiz,
    changeElementPropValue,
    changeNotionPropValue,
    changeDocumentPropValue,
    changeModulePropValue,
    changeOrderPropValue,
    changeQuizPropValue,
    changeCoursePropValue,
    addElementToCourse,
    changeCoursePropValueById,
    changeCurrentElementPropValue,
    pushCurrentElement,
    setCurrentStep,
    loadAllCategories,
    changeCurrentCoursePropValue,
    changeCurrentCourseValidationPropValue,
    changeCurrentCourseElementPropValue,
    deleteCurrentCourseDocumentsElementItem,
    addCourseDocumentsElement,
    loadCourseContent,
    addCourse,
    loadAllGames,
    pushElement,
    editTypElement,
    addModule,
    addBusinessGame
};

export default connect(mapStateToProps, mapActionsToProps)(ConfigureCourse);

export {
    CreateCourseComponent
}
