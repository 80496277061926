import React, {Component, useEffect} from "react";
import Create_element from "../create_element";
import style from  "./scss.module.scss"
import history from "../../../../../../utils/history";
import {CreateCourseComponent} from "../../index";
import {useDispatch, useSelector} from "react-redux";
import {loadAllCategories} from "../../../../../redux/moderator/configure-course/actions-creators";

const EditCourseModal = ({
                                 isOpen,
                                 onCancel ,
                                 games_list = [],
                                 onSave=()=>null,
                                 course={}
                             })=>{

       const {categories_list} = useSelector((state)=>state.moderator_configure_course);

     const dispatch =   useDispatch()

    useEffect(() => {
        dispatch(loadAllCategories())

    }, []);


        let class_name = "configure_course_modal_wrapper ";
        class_name += isOpen ? "configure_course_modal_open" : "";

        return(
            <div className={`${class_name} ${style.configure_course_modal_wrapper}`} onClick={(e) => {
                if(e.target.className === class_name){
                    onCancel();
                }
            }}>

                <div className={`${style.NE_body_}`}>
                    <div className="NE_text">
                        <h3 className="NE_text_body">Créer un élément</h3>
                        <h3 className={`NE_text_body _close_button ${style._close_button}`} onClick={()=>{
                            onCancel();
                        }} >x</h3>
                    </div>
                    <div className="NE_body_center" />
                    <div className="NE_search_bar">
                        <div className="NE_icon_input NE_icon_input_success NE_icon_input_error">
                            <div className="input_wrapper">
                                <CreateCourseComponent
                                    className_actions={style.className_actions}
                                    categoriesDropdownItems={categories_list}
                                    onImageChange={()=>null}
                                    initial={course}
                                    requiredImg={false}
                                    onSave={onSave}
                                    editMode={true}
                                    onClick={onCancel}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );

}

export  default EditCourseModal;
