import {
  ADD_BUSINESS_GAME,
  ADD_BUSINESS_GAME_FAILED,
  ADD_BUSINESS_GAME_SUCCESS,
  ADD_COURSE,
  ADD_COURSE_DOCUMENTS_ELEMENT,
  ADD_COURSE_DOCUMENTS_ELEMENT_ERROR,
  ADD_COURSE_DOCUMENTS_ELEMENT_SUCCESS,
  ADD_COURSE_FAILED,
  ADD_COURSE_SUCCESS,
  ADD_DOCUMENT,
  ADD_DOCUMENT_FAILED,
  ADD_DOCUMENT_SUCCESS,
  ADD_ELEMENT,
  ADD_ELEMENT_FAILED,
  ADD_ELEMENT_SUCCESS,
  ADD_ELEMENT_TO_COURSE,
  ADD_EVALUATION,
  ADD_EVALUATION_FAILED,
  ADD_EVALUATION_SUCCESS,
  ADD_MODULE,
  ADD_MODULE_FAILED,
  ADD_MODULE_SUCCESS,
  ADD_NOTION,
  ADD_NOTION_FAILED,
  ADD_NOTION_SUCCESS,
  ADD_QUIZ,
  ADD_QUIZ_FAILED,
  ADD_QUIZ_SUCCESS,
  CHANGE_COURSE_PROP_VALUE,
  CHANGE_COURSE_PROP_VALUE_BY_ID,
  CHANGE_CURRENT_COURSE_ELEMENT_PROP_VALUE,
  CHANGE_CURRENT_COURSE_PROP_VALUE,
  CHANGE_CURRENT_COURSE_VALIDATION_PROP_VALUE,
  CHANGE_CURRENT_ELEMENT_PROPS,
  CHANGE_DOCUMENT_PROP_VALUE,
  CHANGE_ELEMENT_ORDER,
  CHANGE_ELEMENT_PROP_VALUE,
  CHANGE_MODULE_PROP_VALUE,
  CHANGE_NOTION_PROP_VALUE,
  CHANGE_QUIZ_PROP_VALUE,
  CLOSE_ALERT,
  DELETE_CURRENT_COURSE_DOCUMENTS_ELEMENT_ITEM,
  DELETE_GAME,
  DELETE_GAME_FAILED,
  DELETE_GAME_SUCCESS,
  DELETE_MODULE,
  DELETE_MODULE_FAILED,
  DELETE_MODULE_SUCCESS,
  DELETE_NOTION,
  DELETE_NOTION_FAILED,
  DELETE_NOTION_SUCCESS,
  DELETE_QUIZ,
  DELETE_QUIZ_SUCCESS,
  DELETE_QUIZ_FAILED,
  DELETE_EVALUATION,
  DELETE_EVALUATION_SUCCESS,
  DELETE_EVALUATION_FAILED,
  EDIT_TYPE_ELEMENT,
  ELEMENT_ADDED,
  LOAD_ALL_CATEGORIES,
  LOAD_ALL_CATEGORIES_ERROR,
  LOAD_ALL_CATEGORIES_SUCCESS,
  LOAD_ALL_GAMES,
  LOAD_ALL_GAMES_ERROR,
  LOAD_ALL_GAMES_SUCCESS,
  LOAD_COURSE_CONTENT,
  LOAD_COURSE_CONTENT_ERROR,
  LOAD_COURSE_CONTENT_SUCCESS,
  PUSH_ALERT,
  PUSH_CURRENT_ELEMENT,
  PUSH_ELEMENT,
  RESET_ELEMENT,
  SET_CURRENT_STEP,
  DELETE_DOCUMENTS,
  DELETE_DOCUMENTS_SUCCESS,
  DELETE_DOCUMENTS_FAILED,
  PUBLISH,
  CLEAR_ELEMENT_COURSE, EDIT_COURSE_SUCCESS, EDIT_COURSE
} from "./actions-definitions";
import { findIndex } from "lodash";

// Initial state
const INIT_STATE = {
  courses_list: [],
  element_order: 0,
  element_added: false,
  element: {
    title: "",
    description: ""
  },
  elements: [],
  module: {
    title: "",
    description: "",
    element_order: "",
    course_id: ""
  },
  modules: [],
  notion: {
    title: "",
    content: "",
    media_path: "",
    attached_documents: []
  },
  notions: [],
  document: {
    course_id: "",
    title: "",
    media_path: "",
    element_order: 0,
    attached_documents: []
  },
  documents: [],
  quiz: {
    module_id: "",
    element_order: "",
    title: "",
    questions: []
  },
  quizs: [],

  //Start Here//
  categories_list: [],
  current_course: {
    publish: false,
    title: "",
    category: null,
    description: "",
    image: null,
    elements: []
  },
  current_step: "CREATE_COURSE",
  alerts: [],
  is_loading: false,
  current_element: {
    id: null,
    title: "",
    description: ""
  },

  title: "Programming",
  category: "Categ1",
  description: "test test",
  image: "",
  _elements: [],

  current_module: {},
  current_document: {
    title: "",
    attach: []
  },
  games_list: [],

  //end Here
  //Validation Start //
  current_course_validation: {
    image: {
      is_valid: false,
      error: ""
    },
    title: {
      is_valid: false,
      error: ""
    },
    category: {
      is_valid: false,
      error: ""
    },
    description: {
      is_valid: false,
      error: ""
    }
  }

  //Validation end //
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_ELEMENT_COURSE:
      return {
        ...state,
        ...INIT_STATE,
        courses_list: state.courses_list
      };
    case ADD_ELEMENT:
      return { ...state, errors: [] };
    case ADD_ELEMENT_SUCCESS:
      return {
        ...state,
        elements: [...state.elements, { ...action.payload }],
        is_loading: false
      };
    case ADD_ELEMENT_FAILED:
      return { ...state, errors: action.payload };
    case ADD_MODULE:
      return { ...state, errors: [] };
    case ADD_MODULE_SUCCESS:
      let a = state.current_course.elements.filter(
        e => e._id !== action.payload._id
      );
      let b = state.current_course.elements.find(
        e => e._id === action.payload._id
      );
      b = {
        ...b,
        ...action.payload.Module
      };
      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: [...a, b]
        },
        is_loading: false
      };
    case ADD_MODULE_FAILED:
      return { ...state, errors: action.payload };
    case ADD_NOTION:
      return { ...state, errors: [] };
    case ADD_NOTION_SUCCESS:
      let Notiona = state.current_course.elements.filter(
        e => e._id !== action.payload._id
      );
      let Notionb = state.current_course.elements.find(
        e => e._id === action.payload._id
      );

      if (Notionb.notions !== null) {
        Notionb = {
          ...Notionb,
          notions: [...Notionb.notions, action.payload.Notion]
        };
      } else {
        Notionb = {
          ...Notionb,
          notions: [action.payload.Notion]
        };
      }
      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: [...Notiona, Notionb]
        },
        is_loading: false
      };
    case ADD_NOTION_FAILED:
      return { ...state, errors: action.payload };

    case DELETE_NOTION:
      return { ...state, errors: [] };
    case DELETE_NOTION_SUCCESS:
      let DNotiona = state.current_course.elements.filter(
        e => e.id !== action.payload.module_id
      );
      let DNotionb = state.current_course.elements.find(
        e => e.id === action.payload.module_id
      );
      DNotionb.notions = DNotionb.notions.filter(
        e => e.id !== action.payload.id
      );

      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: [...DNotiona, DNotionb]
        },
        is_loading: false
      };
    case DELETE_NOTION_FAILED:
      return { ...state, errors: action.payload };

    case DELETE_QUIZ:
      return { ...state, errors: [] };
    case DELETE_QUIZ_SUCCESS:
      let QNotiona = state.current_course.elements.filter(
        e => e.id !== action.payload.module_id
      );
      let QNotionb = state.current_course.elements.find(
        e => e.id === action.payload.module_id
      );
      QNotionb.quizzes = QNotionb.quizzes.filter(
        e => e.id !== action.payload.id
      );

      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: [...QNotiona, QNotionb]
        },
        is_loading: false
      };
    case DELETE_QUIZ_FAILED:
      return { ...state, errors: action.payload };

    case DELETE_EVALUATION:
      return { ...state, errors: [] };
    case DELETE_EVALUATION_SUCCESS:
      let ENotiona = state.current_course.elements.filter(
        e => e._id !== action.payload._id
      );

      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: [...ENotiona]
        },
        is_loading: false
      };
    case DELETE_EVALUATION_FAILED:
      return { ...state, errors: action.payload };

    case DELETE_MODULE:
      return { ...state, errors: [], is_loading: true };
    case DELETE_MODULE_SUCCESS:
      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: state.current_course.elements.filter(
            e => e.id !== action.payload.id
          )
        },
        is_loading: false
      };
    case DELETE_MODULE_FAILED:
      return { ...state, is_loading: false, errors: action.payload };

    case DELETE_GAME:
      return { ...state, errors: [] };
    case DELETE_GAME_SUCCESS:
      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: state.current_course.elements.filter(
            e => e._id !== action.payload._id
          )
        },
        is_loading: false
      };
    case DELETE_GAME_FAILED:
      return { ...state, errors: action.payload };

    case DELETE_DOCUMENTS:
      return { ...state, errors: [] };
    case DELETE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: state.current_course.elements.filter(
            e => e._id !== action.payload._id
          )
        },
        is_loading: false
      };
    case DELETE_DOCUMENTS_FAILED:
      return { ...state, errors: action.payload };
    case ADD_QUIZ:
      return { ...state, errors: [] };
    case ADD_QUIZ_SUCCESS:
      let Quiza = state.current_course.elements.filter(
        e => e._id !== action.payload._id
      );
      let Quizb = state.current_course.elements.find(
        e => e._id === action.payload._id
      );

      if (Quizb.quizzes !== null) {
        Quizb = {
          ...Quizb,
          quizzes: [...Quizb.quizzes, action.payload.Quiz]
        };
      } else {
        Quizb = {
          ...Quizb,
          quizzes: [action.payload.Quiz]
        };
      }

      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: [...Quiza, Quizb]
        },
        is_loading: false
      };
    case ADD_QUIZ_FAILED:
      return { ...state, errors: action.payload };
    case ADD_EVALUATION:
      return { ...state, errors: [] };
    case ADD_EVALUATION_SUCCESS:
      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: state.current_course.elements.map((elem, index) => {
            if (elem._id === action.payload.oldEvaluation._id)
              return {
                ...action.payload.oldEvaluation,
                id: action.payload.Evaluation.id
              };
            return elem;
          })
        },

        is_loading: false
      };
    case ADD_EVALUATION_FAILED:
      return { ...state, errors: action.payload };
    case ADD_BUSINESS_GAME:
      return { ...state, errors: [] };
    case ADD_BUSINESS_GAME_SUCCESS:
      let elem = state.current_course.elements.find(
        e => e._id === action.payload?._id
      );
      const elemAll = state.current_course.elements.filter(
        e => e._id !== action.payload?._id
      );
      elem = { ...elem, ...action.payload?.businessGame };

      return {
        ...state,
        is_loading: false,
        current_course: {
          ...state.current_course,
          elements: [...elemAll, elem]
        }
      };
    case ADD_BUSINESS_GAME_FAILED:
      return { ...state, errors: action.payload };
    case ADD_DOCUMENT:
      return { ...state, errors: [] };
    case ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: [...state.documents, { ...action.payload }],
        is_loading: false
      };
    case ADD_DOCUMENT_FAILED:
      return { ...state, errors: action.payload };
    case CHANGE_ELEMENT_PROP_VALUE:
      return {
        ...state,
        element: {
          ...state.element,
          [action.payload.propName]: action.payload.propValue
        }
      };
    case CHANGE_MODULE_PROP_VALUE:
      return {
        ...state,
        module: {
          ...state.module,
          [action.payload.propName]: action.payload.propValue
        }
      };
    case CHANGE_NOTION_PROP_VALUE:
      return {
        ...state,
        notion: {
          ...state.notion,
          [action.payload.propName]: action.payload.propValue
        }
      };
    case CHANGE_DOCUMENT_PROP_VALUE:
      return {
        ...state,
        document: {
          ...state.document,
          [action.payload.propName]: action.payload.propValue
        }
      };
    case CHANGE_QUIZ_PROP_VALUE:
      return {
        ...state,
        quiz: {
          ...state.quiz,
          [action.payload.propName]: action.payload.propValue
        }
      };
    case CHANGE_ELEMENT_ORDER:
      return { ...state, [action.payload.propName]: action.payload.propValue };

    case ADD_ELEMENT_TO_COURSE:
      return { ...state, [action.payload.propName]: action.payload.propValue };
    case CHANGE_COURSE_PROP_VALUE:
      return { ...state, [action.payload.propName]: action.payload.propValue };
    case CHANGE_COURSE_PROP_VALUE_BY_ID:
      // Find index of the client to be updated
      const element_index = findIndex(
        state._elements,
        element => element.id === action.payload.id
      );

      // Created an updated list of clients
      const updatedElementList = [...state._elements];
      updatedElementList.splice(element_index, 1, action.payload);

      return {
        ...state,
        _elements: [...updatedElementList],
        is_loading: false
      };
    case CHANGE_CURRENT_ELEMENT_PROPS:
      return {
        ...state,
        current_element: {
          ...state.current_element,
          [action.payload.propName]: action.payload.propValue
        }
      };

    case SET_CURRENT_STEP:
      return { ...state, current_step: action.payload };

    case PUSH_CURRENT_ELEMENT:
      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: [
            ...state.current_course.elements,
            { ...state.current_element }
          ]
        },
        current_element: { ...INIT_STATE.current_element }
      };

    case PUSH_ELEMENT:
      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: [...state.current_course.elements, action.payload]
        },
        current_step: "CONFIGURE_ELEMENT"
      };
    case EDIT_TYPE_ELEMENT:
      const listElements = state.current_course.elements.map(elem => {
        if (elem._id === action.payload._id)
          return { ...elem, ...action.payload };
        return elem;
      });

      return {
        ...state,
        current_course: { ...state.current_course, elements: listElements }
      };

    case LOAD_ALL_CATEGORIES:
      return { ...state, is_loading: true };

    case LOAD_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories_list: [...action.payload],
        is_loading: false
      };

    case LOAD_ALL_CATEGORIES_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };

    case ADD_COURSE:
      return { ...state, is_loading: true };

    case ADD_COURSE_SUCCESS:
      return {
        ...state,
        current_course: { ...state.current_course, ...action.payload },
        current_step: "ADD_CLONE_ELEMENT",
        is_loading: false,
        alerts: []
      };

    case ADD_COURSE_FAILED:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };

    case CHANGE_CURRENT_COURSE_PROP_VALUE:
      return {
        ...state,
        current_course: {
          ...state.current_course,
          [action.payload.propName]: action.payload.propValue
        }
      };

    case CHANGE_CURRENT_COURSE_ELEMENT_PROP_VALUE: {
      const targeted_element_index = findIndex(
        state.current_course.elements,
        element => element._id === action.payload.element._id
      );

      const updated_targeted_element = {
        ...state.current_course.elements[targeted_element_index],
        [action.payload.propName]: action.payload.propValue
      };

      const updated_course_elements = [...state.current_course.elements];
      updated_course_elements.splice(
        targeted_element_index,
        1,
        updated_targeted_element
      );
      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: [...updated_course_elements]
        }
      };
    }

    case DELETE_CURRENT_COURSE_DOCUMENTS_ELEMENT_ITEM: {
      const targeted_element_index = findIndex(
        state.current_course.elements,
        element => element.id === action.payload.element.id
      );
      const targeted_item_index = findIndex(
        state.current_course.elements[targeted_element_index]
          .attached_documents,
        item => item.name === action.payload.document.name
      );

      const updated_element_documents = [
        ...state.current_course.elements[targeted_element_index]
          .attached_documents
      ];
      updated_element_documents.splice(targeted_item_index, 1);

      const updated_targeted_element = {
        ...state.current_course.elements[targeted_element_index],
        attached_documents: [...updated_element_documents]
      };

      const updated_course_elements = [...state.current_course.elements];
      updated_course_elements.splice(
        targeted_element_index,
        1,
        updated_targeted_element
      );

      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: [...updated_course_elements]
        }
      };
    }

    case ADD_COURSE_DOCUMENTS_ELEMENT:
      return { ...state, is_loading: true };

    case ADD_COURSE_DOCUMENTS_ELEMENT_SUCCESS:
      // _id, documents_element

      let docElem = state.current_course.elements.find(
        e => e._id === action.payload?._id
      );
      const docElemAll = state.current_course.elements.filter(
        e => e._id !== action.payload?._id
      );
      docElem = { ...docElem, ...action.payload?.documents_element };


      return {
        ...state,
        is_loading: false,
        current_course: {
          ...state.current_course,
          elements: [...docElemAll, docElem]
        }
      };

    case ADD_COURSE_DOCUMENTS_ELEMENT_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };

    case LOAD_COURSE_CONTENT:
      return { ...state, is_loading: true };

    case LOAD_COURSE_CONTENT_SUCCESS:
      return {
        ...state,
        current_course: { ...action.payload },
        is_loading: false
      };

    case LOAD_COURSE_CONTENT_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };

    case PUSH_ALERT:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ]
      };

    case CLOSE_ALERT:
      return {
        ...state,
        alerts: [...state.alerts.filter(alert => alert.id !== action.payload)]
      };

    case LOAD_ALL_GAMES:
      return { ...state, is_loading: true };

    case LOAD_ALL_GAMES_SUCCESS:
      return { ...state, games_list: [...action.payload], is_loading: false };

    case LOAD_ALL_GAMES_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };
    case RESET_ELEMENT:
      return {
        ...state,
        current_course: {
          ...state.current_course,
          elements: state.current_course.elements.map(elem => {
            if (elem._id === action.payload._id)
              return { title: elem.title, description: elem.description };
            return elem;
          })
        }
      };

    case CHANGE_CURRENT_COURSE_VALIDATION_PROP_VALUE:
      return {
        ...state,
        current_course_validation: {
          ...state.current_course_validation,
          [action.payload.propName]: action.payload.propValue
        }
      };

    case EDIT_COURSE:
   return {...state,is_loading:true }
    case EDIT_COURSE_SUCCESS:

      return {
        ...state,
        is_loading: false,
        current_course: {
          ...state.current_course,
          title:action.payload.title,
          image_path:action.payload.image_path,
          description:action.payload.description,
          category:action.payload.category,
        }
      };

    case ELEMENT_ADDED:
      return {
        ...state,
        element_added: action.payload
      };
    case PUBLISH:
      return {
        ...state,
        current_course: { ...state.current_course, publish: true }
      };
    default:
      return { ...state };
  }
};

export default reducer;
