import { all, call, delay, fork, put, takeLatest } from "redux-saga/effects";
import jwtDecode from "jwt-decode";
import {
  LOGIN_USER,
  LOGIN_FACEBOOK,
  LOGOUT_USER,
  LOGIN_GOOGLE
} from "./action-definitions";
import {
  loginFacebookFailed,
  loginFacebookSuccess,
  loginGoogleFailed,
  loginGoogleSuccess,
  loginUserFailed,
  loginUserSuccess
} from "./action-creators";
import {
  loginSecureFacebook,
  loginSecureGoogle,
  SecureloginUser
} from "../../services/auth.service";
import history from "../../../utils/history";
import i18n from "../../../i18n";
import { uid } from "uid";
import { pushAlert } from "../admin/client-details/action-creators";
import {
  closeAlert,
  loadAllClientsError
} from "../admin/clients-list/action-creators";
import {clientExpired} from "../../services/moderator.service";

/* Define the actions watchers */
export function* watchUserLogin() {
  yield takeLatest(LOGIN_USER, userLoginWorker);
}

export function* watchFacebook() {
  yield takeLatest(LOGIN_FACEBOOK, facebookLoginWorker);
}

export function* watchGoogle() {
  yield takeLatest(LOGIN_GOOGLE, googleLoginWorker);
}

export function* watchLogout() {
  yield takeLatest(LOGOUT_USER, logoutWorker);
}

/* Define the actions workers */
function* userLoginWorker({ payload }) {
  // Get Userinfo infos and history from the payload
  const data = payload;

  // Attempt to authenticate
  try {
    const call_result = yield SecureloginUser(data);



    localStorage.setItem("NowEdge.current_user_token", call_result.data.token);

    // Decode the access token
    const { details: token_user } = jwtDecode(call_result.data.token);

    // Store the current Userinfo in the local storage
    localStorage.setItem("NowEdge.current_user", JSON.stringify(token_user));


    if(call_result.data.role.name === "Moderator"){
      const res_expired =   yield clientExpired({
          id:call_result.data.id
        })

      yield put(loginUserSuccess({...token_user,expired:res_expired.data}));


    }else {

      // Dispatch Signup Userinfo success
      yield put(loginUserSuccess(token_user));
    }



    yield call(forwardTo, "/");
  } catch (error) {
    // Dispatch alert
    const alert_id = uid();
    yield put(
      loginUserFailed(alert_id, "danger", i18n.t("login.invalidCredential"))
    );

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(alert_id));

    //invalidCredential
  }
}

//Facebook omniauth
function* facebookLoginWorker({ payload }) {
  try {
    const call_result = yield loginSecureFacebook({
      code: payload,
      redirect_uri: "http://localhost:3000"
    });

    localStorage.setItem("NowEdge.current_user_token", call_result.data.token);

    // Decode the access token
    const { details: token_user } = jwtDecode(call_result.data.token);

    // Store the current Userinfo in the local storage
    localStorage.setItem("NowEdge.current_user", JSON.stringify(token_user));

    // Change application language
    yield i18n.changeLanguage(token_user?.language);

    // Dispatch Signup Userinfo success
    yield put(loginFacebookSuccess(token_user));

    yield call(forwardTo, "/");
  } catch (error) {
    const alert_id = uid();
    yield put(
      loginFacebookFailed(
        alert_id,
        "danger",
        i18n.t("login.sign_in_with_facebook_error")
      )
    );

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(alert_id));
  }
}

//Google omniAuth
function* googleLoginWorker({ payload }) {


  try {
    const call_result = yield loginSecureGoogle({
      code: payload,
      redirect_uri: "http://localhost:3000/omniauth/google"
    });

    localStorage.setItem("NowEdge.current_user_token", call_result.data.token);

    // Decode the access token
    const { details: token_user } = jwtDecode(call_result.data.token);

    // Store the current Userinfo in the local storage
    localStorage.setItem("NowEdge.current_user", JSON.stringify(token_user));

    // Change application language
    yield i18n.changeLanguage(token_user?.language);

    // Dispatch Signup Userinfo success
    yield put(loginGoogleSuccess(token_user));

    yield call(forwardTo, "/");
  } catch (error) {
    const alert_id = uid();
    yield put(
      loginGoogleFailed(
        alert_id,
        "danger",
        i18n.t("login.sign_in_with_google_error")
      )
    );

    // Dispatch action to close alert after 3s delay
    yield delay(3000);
    yield put(closeAlert(alert_id));
  }
}

function forwardTo(location) {
  history.push(location);
}

function* logoutWorker({ payload }) {
  // Clear local storage
  localStorage.clear();

  // Redirect Userinfo to login page
  history.push("/");
}

// Export the combined sagas
export default function* allSagas() {
  yield all([
    fork(watchUserLogin),
    fork(watchGoogle),
    fork(watchFacebook),
    fork(watchLogout)
  ]);
}
