import {
  Redirect,
  Route,
  Switch,
  Router as BrowserRouter
} from "react-router-dom";
import history from "./utils/history";
import { isAuthenticated } from "./app/services/auth.service";
import Login from "./app/modules/Login";
import Signup from "./app/modules/Signup";
import Reset from "./app/modules/Reset";
import completeProfile from "./app/modules/Signup/complete-profil";
import detectBrowserLanguage from 'detect-browser-language';

import ClientsList from "./app/modules/admin/clients-list";
import ModeratorList from "./app/modules/moderator/courses-list";
import ParticipantList from "./app/modules/Participant/Participant-course-list";
import ClientDetails from "./app/modules/admin/client-details";
import ParticipantCourseItem from "./app/modules/Participant/Participant-course-item";
import TrainingSessions from "./app/modules/moderator/training-sessions";
// import addCloneElement from "./app/modules/moderator/Add_clone_Element";
// import addCloneElement from "./app/modules/moderator/Add_clone_Element";
// import moderatorAddElement from "./app/modules/moderator/moderator-add-element";
// import addCloneElement from "./app/modules/moderator/add_clone_Element";
// import AddCloneElement from "./app/modules/moderator/Add_clone_Element";
import ConfigureCourse from "./app/modules/moderator/configure-course";
import ModeratorAddElement from "./app/modules/moderator/moderator-add-element";
import CloneElementModal from "./app/modules/moderator/configure-course/components/clone-element-modal";
import ParticipantPoints from "./app/modules/Participant/Participant-points";
import ParticipantEditProfile from "./app/modules/Participant/Participant-edit-profile";
import ParticipantProfile from "./app/modules/Participant/participant-profile";

import FacebookLogin from "./app/modules/Login/Facebook";
import GoogleLogin from "./app/modules/Login/Google";
import EditCourse from "./app/modules/moderator/edit-course";
import ModeratorCourseItem from "./app/modules/moderator/Moderator-course-item";
import {useTranslation} from "react-i18next";
import Auth from "./app/redux/login/reducer";
import {useSelector} from "react-redux";
import {useEffect} from "react";
import "./app/assets/app.css"

// Define the protected route Facebook
function ProtectedRoute({
  component: Component,
  layout: Layout,
  permission,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={props => {
        // Check if the user is authenticated
        if (isAuthenticated()) {
          const currentUser = JSON.parse(
            localStorage.getItem("NowEdge.current_user")
          );
          if (currentUser.role.name.toUpperCase() === permission) {
            // Render the Facebook normally
            return Layout ? (
              <Layout {...props}>
                <Component {...props} />
              </Layout>
            ) : (
              <Component {...props} />
            );
          } else {
            return <Redirect to="/" />;
          }
        } else {
          // Redirect to login page
          return <Redirect to="/login" />;
        }
      }}
    />
  );
}

function App() {
  const {i18n} = useTranslation();

  const { current_user } = useSelector((state) => state.login);



  useEffect(() => {
    if (current_user){
        i18n.changeLanguage(current_user.language).then(r => r);

    }else {
        const userLanguage = detectBrowserLanguage().split('-')[0];

        if (['fr', 'en'].includes(userLanguage)) {
            i18n.changeLanguage(userLanguage).then(r => r);
        } else {
            i18n.changeLanguage('fr').then(r => r);
        }

    }

  }, [current_user, i18n]);



  return (
    <BrowserRouter history={history}>
      <Switch>
        {/*  Routes definition ... */}
        <Route
          path="/"
          exact
          render={() => {
            const currentUser = JSON.parse(
              localStorage.getItem("NowEdge.current_user")
            );

            if (
              currentUser &&
              currentUser.role.name.toUpperCase() === "ADMIN"
            ) {
              return <Redirect to="/admin" />;
            } else if (
              currentUser &&
              currentUser.role.name.toUpperCase() === "MODERATOR"
            ) {
              return <Redirect to="/moderator" />;
            } else if (
              currentUser &&
              currentUser.role.name.toUpperCase() === "PARTICIPANT"
            ) {
              return <Redirect to="/participant" />;
            } else {
              return <Redirect to="/login" />;
            }
          }}
        />

        <Route path="/login" exact component={Login} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/reset" component={Reset} />
        <Route exact path="/omniauth/facebook" component={FacebookLogin} />
        <Route exact path="/omniauth/google" component={GoogleLogin} />
        <ProtectedRoute
          permission="PARTICIPANT"
          component={completeProfile}
          exact
          path="/complete"
        />

        {/*Moderator Route*/}
        <ProtectedRoute
          permission="MODERATOR"
          component={ModeratorList}
          exact
          path="/moderator"
        />
        <ProtectedRoute
          permission="MODERATOR"
          component={ConfigureCourse}
          exact
          path="/moderator/create_course"
        />
        <ProtectedRoute
          permission="MODERATOR"
          component={EditCourse}
          exact
          path="/moderator/edit_course/:courseId"
        />
        <ProtectedRoute
          permission="MODERATOR"
          exact
          path="/moderator/:id/addCloneElement"
          component={ConfigureCourse}
        />
        <ProtectedRoute
          permission="MODERATOR"
          exact
          path="/moderator/:id/addelement"
          component={ModeratorAddElement}
        />
        <ProtectedRoute
          permission="MODERATOR"
          exact
          path="/moderator/:id/clonelement"
          component={CloneElementModal}
        />
        <ProtectedRoute
          permission="MODERATOR"
          component={ModeratorCourseItem}
          exact
          path="/moderator/course/:trainingSessionID"
        />
        <ProtectedRoute
          permission="MODERATOR"
          component={TrainingSessions}
          exact
          path="/moderator/training-sessions/:trainingSessionID"
        />
        {/*Moderator Route*/}

        <ProtectedRoute
          permission="ADMIN"
          component={ClientsList}
          exact
          path="/admin"
        />
        <ProtectedRoute
          permission="ADMIN"
          component={ClientDetails}
          exact
          path="/admin/client_details"
        />
        <ProtectedRoute
          permission="PARTICIPANT"
          component={ParticipantList}
          exact
          path="/participant"
        />
        <ProtectedRoute
          permission="PARTICIPANT"
          component={ParticipantCourseItem}
          exact
          path="/participant/course/:trainingSessionID"
        />
        <ProtectedRoute
          permission="PARTICIPANT"
          component={ParticipantPoints}
          exact
          path="/participant/profile"
        />
        <ProtectedRoute
          permission="PARTICIPANT"
          component={ParticipantEditProfile}
          exact
          path="/participant/edit-profile"
        />
        <ProtectedRoute
          permission="PARTICIPANT"
          component={ParticipantProfile}
          exact
          path="/participant/profile1"
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
