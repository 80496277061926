import { all } from "redux-saga/effects";
import loginSagas from "./login/sagas";
import registerSagas from "./register/sagas";
import resetSagas from "./reset/sagas";
import adminClientsListSagas from "./admin/clients-list/sagas";
import adminClientDetailsSagas from "./admin/client-details/sagas";
import moderatorCoursesListSagas from "./moderator/courses-list/sagas";
import participantCoursesListSagas from "./participant/participant-course-list/sagas";
import participantCoursesItemSagas from "./participant/participant-course-item/sagas";
import participantPointsSagas from "./participant/participant-points/sagas";
import moderatorTrainingSessions from "./moderator/training-sessions/sagas";

import moderatorConfigureCourseSagas from "./moderator/configure-course/sagas";
import userUpdateInformation from "./updateuser/sagas";
import editCourseSaga from "./moderator/edit-course/sagas";
import StudentManagementSaga from "./studentManagement/saga";

// Export the root saga
export default function* rootSaga() {
  yield all([
    loginSagas(),
    registerSagas(),
    resetSagas(),
    adminClientsListSagas(),
    adminClientDetailsSagas(),
    moderatorCoursesListSagas(),
    moderatorConfigureCourseSagas(),
    participantCoursesListSagas(),
    participantCoursesItemSagas(),
    participantPointsSagas(),
    moderatorTrainingSessions(),
    userUpdateInformation(),
    editCourseSaga(),
    StudentManagementSaga()
  ]);
}
