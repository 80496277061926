import React,{Component} from "react";
import {Translation} from "react-i18next";

import './index.css'
import LargeButton from "../LargeButton";

class ClientInfoCardWithActions extends Component{
    render() {
        // Get Facebook props
        const {client,onAffectGames,onAffectModerator} = this.props;

        return <Translation>
            {t => {
                return (
                    <div className="NE_client_info_card_with_actions">
                        <div className="header">
                            {/*<span className="actions_btn" />*/}
                            <img src={client.image} alt="Client profile" />
                            <h1>{client.full_name}</h1>
                            <p>{client.type}</p>
                            <p>{client.email}</p>
                            <p>{new Date(client.expiry_date).toLocaleDateString("sv-SE")}</p>
                        </div>

                        <div className="body">
                            <div className="phone">
                                <p>{client.phone}</p>
                            </div>

                            <div className="address">
                                <p>{client.address}</p>
                            </div>


                        </div>

                        <div className="footer">
                            <LargeButton button="dark" value={t("admin.client-details.statics.client-card.affect_business_game_button")} onClick={onAffectGames} />
                            <LargeButton value={t("admin.client-details.statics.client-card.affect_moderator_button")} onClick={onAffectModerator} />
                        </div>
                    </div>
                    
                );
            }}
        </Translation>
    }
}
export default ClientInfoCardWithActions;
