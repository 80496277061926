import React, {createContext, useContext, useState} from "react";
import './index.css'
import icon from './assets/icon.svg';
import iconNotion from './assets/file-remove.svg';
import iconAddFile from './assets/add-file.svg';
import add from "../../../../../components/ToggleBarButton/assets/add.svg";
import RichEditor from "../editor";
import * as PropTypes from "prop-types";
import {uid} from "uid";
import {Controller, useForm} from "react-hook-form";
import icon_bus from "./assets/icon_bus.svg"
import {deleteGame} from "../../../../../redux/moderator/configure-course/actions-creators";
import {useDispatch} from "react-redux";
import DeleteElementsModal from "../delete-elements-modal";
import Switch from "../../../../../components/Switch";

export const ModuleItemContext = createContext({
    items: [],
    addItem: () => {
    },
    deleteItem: () => {
    },
    editItem: () => {
    },
    changeQuestionOption: () => {
    }
});

const initialState = [
    // {
    //     text: "option",
    //     question_option:true,
    //     type:"quiz",
    //     _id: uid()
    // }
];

const ModuleItemsProvider = (props) => {

    const [items, setItems] = useState(initialState);

    const addItem = (item) => setItems((items) => [...items, item]);
    const deleteItem = (id) =>
        setItems((items) => items.filter((item) => item._id !== id));

    const editItem = (elem) => {

        const list = items.map((item) => {
            if (item._id === elem._id) return {...item, ...elem};
            return item;
        });
        setItems(list);
    };


    const value = {
        items,
        addItem,
        deleteItem,
        editItem
    };

    return (
        <ModuleItemContext.Provider value={value}>{props.children}</ModuleItemContext.Provider>
    );
};


function NotionFormComponent({elem}) {
    const {register, control, watch, formState: {errors}} = useForm();

    const [isCollapse, setIsCollapse] = useState(false);
    const {items, addItem, editItem} = useContext(ModuleItemContext);

    React.useEffect(() => {
        const subscription = watch((value, {name, type}) => {

            editItem({_id: elem._id, ...value});
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const file_name = ""; //elem.doc !== undefined ? elem.doc[0].name :  null;

    return <div className={`body_element ${isCollapse ? "isCollapse" : ""}`}>
        <div
            className="NE_collapsable_card_with_icon NE_collapsable_card_with_icon_open notion_creation_card">
            <div className="header">
                <div className="title">
                    <img src={iconNotion}
                         alt="Container icon"/>
                    <h2>Element Title</h2>
                </div>

                <span className="btn_collapse" onClick={() => setIsCollapse(!isCollapse)}/>
            </div>

            <div className="body">
                <form action="#" method="post" encType="multipart/form-data">
                    <div className="form-field">
                        <div className="NE_normal_input">
                            <label htmlFor="title">Title</label>
                            <input type="text" {...register("title")} placeholder="Notion title"/>
                            <p>This is a message</p>
                        </div>
                    </div>

                    <div className="form-field">
                        <div className="NE_normal_input">
                            <label htmlFor="media">Media</label>
                            <input type="text" id="media" {...register("media")} placeholder="Add media link"/>
                            <p>This is a message</p>
                        </div>
                    </div>

                    <div className="form-field">
                        <div className="NE_documents_uploader">
                            <input type="file"  {...register("doc")} accept=".pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx,.pptm,.xlsm" />
                            <div className="drop_zone">
                                <img
                                    src={iconAddFile}
                                    alt="Add document icon"/>
                                <h1>
                                    {file_name ?
                                        file_name
                                        :
                                        'Attached Documents'
                                    }


                                </h1>
                                <p>Drag & drop or <span>browse</span> your file here</p>
                            </div>
                        </div>
                    </div>

                    <div className="form-field">
                        <Controller
                            control={control}
                            name="description"
                            render={({field: {onChange, onBlur, value, ref}}) => (
                                <RichEditor onChange={onChange}/>
                            )}
                        />

                    </div>

                    <div className="actions">
                        <div className="action">
                            <button className="NE_button_large NE_button_outline"
                                    type="reset">Cancel
                            </button>
                        </div>

                        <div className="action">
                            <button className="NE_button_large NE_button_dark" type="submit">Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>;
}

function ControlBtnComponent({onAddElement = () => null}) {
    const [isOpen, setIsOpen] = useState(false);
    return <div className="NE_toggle_bar_button NE_toggle_bar_button_open">
        <div className="toggle_button" onClick={() => setIsOpen(!isOpen)}>
            <img src={add} alt="Toggle button"/>
        </div>

        {isOpen && <div className="actions_bar actions_bar_st">
            <div className="action" onClick={onAddElement("notion")}>
                <div className="notion_icon"/>
                <p>Notion</p>
            </div>

            <div className="action" onClick={onAddElement("quiz")}>
                <div className="quiz_icon"/>
                <p>Quiz</p>
            </div>
        </div>}
    </div>;
}

function ListComponent(props) {
    const {items, addItem} = useContext(ModuleItemContext);

    const addItem_ = (type) => (e) => {
        addItem({
            type,
            _id: uid(),
        });
    }
    return <div className="body">
        {/*<pre>*/}
        {/*     {JSON.stringify(items,undefined,2)}*/}
        {/*</pre>*/}
        {items.map(props.callbackfn)}
        <ControlBtnComponent onAddElement={addItem_}/>
    </div>;
}

ListComponent.propTypes = {
    listElements: PropTypes.arrayOf(PropTypes.any),
    callbackfn: PropTypes.func,
    onAddElement: PropTypes.func
};
const BusinessCard = (props) => {

    const [listElements, setListElements] = useState([]);
    const [is_open_delete, setIsOpenDelete] = useState(false);

    const [is_open, setIsOpen] = useState(true);

    const onAddElement = (type) => (e) => {

        setListElements([...listElements, {
            type
        }])
    }
    const toggleCollapse = () => {
        setIsOpen(!is_open);
    }

    const deleteEvaluationButton =()=>{
        const {element, onSave} = props;

        dispatch(deleteGame(element.id, element._id))
    }

    const {element, onSave} = props;


    let class_name = "NE_collapsable_card_with_icon quiz_creation_card ";
    class_name += is_open ? "NE_collapsable_card_with_icon_open" : "";

    const dispatch = useDispatch()

    const setPublished = (data)=>{
        console.log("----data---->>",data)
    }

    return<>
    <DeleteElementsModal  isOpen={is_open_delete} onCancel={()=> setIsOpenDelete(false)} onDelete={deleteEvaluationButton} />

    <ModuleItemsProvider>

        <div className="NE_collapsable_cards_container NE_collapsable_cards_container_open">
            <div className="header">
                <div className="title">
                    <img src={icon} alt="Container icon"/>
                    <h2>{element?.title}</h2>
                </div>

                {!props.current_course.publish && <div className="delete_element_content">

                    <div className={"switch-btn"}>
                        <Switch init={true} onChange={setPublished} />
                    </div>

                   <span className="btn_delete_element" onClick={() => {
                       setIsOpenDelete(true)
                   }}>
                   </span>
                    <span className="btn_collapse" onClick={() => {
                        toggleCollapse()
                    }}/>
                </div>}

                {/*<button onClick={() => {*/}
                {/*    console.log("________", element)*/}
                {/*    dispatch(deleteGame(element.id, element._id))*/}
                {/*}*/}
                {/*}>Delete*/}
                {/*</button>*/}
                {/*<span className="btn_collapse"/>*/}
            </div>
            <div className="collapse_bus_game">
                <img src={icon_bus}/>
                <p>{element?.business_game?.name_game}</p>
            </div>


        </div>
    </ModuleItemsProvider>
        </>

}
export default BusinessCard;
