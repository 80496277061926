import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/Header";
import CoursePublishOverview from "../../../components/CoursePublishOverview";
import DocumentsCard from "../edit-course/components/DocumentsCard/index";
import BusinessCard from "../edit-course/components/BusinessCard";
import EvaluationCard from "./components/EvaluationCard";
import ModuleCard from "./components/ModuleCard";
import Loader from "../../../components/Loader";
import CreateElementsModal from "../configure-course/components/create-elements-modal";
import icon_module from "../configure-course/assets/module.svg";
import icon_document from "../configure-course/assets/documents.svg";
import icon_trophy from "../configure-course/assets/trophy.svg";
import icon_evaluation from "../configure-course/assets/evaluation.svg";
import ToggleBarButton from "../../../components/ToggleBarButton";
import CollapseCardContainer from "../../../components/CollapseCardContainer";
import { addElement, addElementCourse } from "../../../redux/moderator/edit-course/actions";
import BusinessGameModal from "../configure-course/components/business-game-modal";
import {
    loadAllCategories,
    loadAllGames,
    loadCourseContent
} from "../../../redux/moderator/configure-course/actions-creators";
import { useParams } from "react-router-dom";

const toggleBarButtonItems = [
    {
        icon: icon_module,
        name: "Module",
        value: "MODULE"
    },
    {
        icon: icon_document,
        name: "Documents",
        value: "DOCUMENTS"
    },
    {
        icon: icon_trophy,
        name: "serious Game",
        value: "BUSINESS_GAME"
    },
    {
        icon: icon_evaluation,
        name: "Evaluation",
        value: "EVALUATION"
    },
];


const EditCourse = ({ }) => {
    const { current_course, isLoading, last_update } = useSelector((state) => state.editCourse);

    const [show_create_elements_modal, setShow_create_elements_modal] = useState(false);

    const [current_element, setCurrentElement] = useState();

    const [is_business_modal_open, setIsBusinessModalOpen] = useState(false);

    const dispatch = useDispatch();

    let { courseId } = useParams();


    useEffect(() => {
        dispatch(loadAllGames())
        dispatch(loadCourseContent(courseId))

    }, []);


    const closeCreateElementsModal = (data) => {
        setShow_create_elements_modal(false)
    }

    const saveCreateElementsModal = (data) => {
        dispatch(addElement({ ...data, type: null }))
        setShow_create_elements_modal(false)
    }

    const handleElementTypeToggleBarItemClick = (selected_item, element) => {

        if (selected_item.value === "BUSINESS_GAME") {
            setIsBusinessModalOpen(true);
            setCurrentElement(element);
        }
        else {
            dispatch(addElementCourse({
                ...element,
                type: selected_item.value,
                element_order: current_course.elements.length,
                course_id: current_course.id
            }))
        }

    }

    const handleBusinessModalCancel = () => {
        setIsBusinessModalOpen(false);
    }

    return (

        <div className="moderator_configure_course_page">

            <Loader isOpen={isLoading} />

            <div className="page_header">
                <Header alerts={[]} isLoading={false} />
            </div>



            <div className="page_body">
                <div className="page_body">
                    <div className="create_course_form_wrapper">
                        <CreateElementsModal isOpen={show_create_elements_modal}
                            onSave={saveCreateElementsModal}
                            onCancel={closeCreateElementsModal}
                        />

                        <div className="page_body">
                            <BusinessGameModal
                                toggleBarButtonItem={toggleBarButtonItems[2]}
                                current_element={current_element}
                                isOpen={is_business_modal_open}
                                editTypElement={(elm, client_affectation_id, game_id) => {

                                    dispatch(addElementCourse({
                                        stratedge_configuration_name: elm.stratedge_configuration_name,
                                        client_affectation_id: client_affectation_id,
                                        course_id: current_course.id,
                                        element_order: current_course.elements.length,
                                        game_id: game_id,
                                        type: "BUSINESS_GAME"

                                    }))

                                }}
                                onCancel={handleBusinessModalCancel} />
                        </div>

                    </div>
                </div>
                <div className="course_publish_overview_wrapper">
                    <CoursePublishOverview courseData={current_course ? current_course : {}} />
                </div>
                <div className="create_course_form_wrapper" >
                    {current_course?.elements.sort((a, b) => a.element_order - b.element_order).map((element, index) => {

                        return {
                            'DOCUMENTS': <DocumentsCard editMode={current_course.number_of_training_sessions <= 0} isOpen={false} key={`${element.id}${index}`} current_course={current_course} element={element} showButtons={false} />,

                            'BUSINESS_GAME': <BusinessCard editMode={current_course.number_of_training_sessions <= 0} isOpen={false} current_course={current_course} element={element} key={`${element.id}${index}`} />,

                            'EVALUATION': <EvaluationCard editMode={current_course.number_of_training_sessions <= 0} isOpen={false} current_course={current_course} element={element} key={`${element.id}${index}`} />,

                            'MODULE': <ModuleCard editMode={current_course.number_of_training_sessions <= 0} isOpen={false} current_course={current_course} element={element} key={`${element.id}${index}`} />,

                            null: <CollapseCardContainer key={index} isOpen={false} title={element.title}>
                                <ToggleBarButton element={element} items={toggleBarButtonItems} onClick={handleElementTypeToggleBarItemClick} />
                            </CollapseCardContainer>
                        }[element.type]

                    })}
                    <div className="btn_add_element" onClick={() => {

                        setShow_create_elements_modal(true)

                    }} />


                </div>
            </div>
        </div>

    );
};

EditCourse.propTypes = {};
EditCourse.defaultProps = {};

export default EditCourse;
