import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import './index.css'
import UserAffectation from "../../modules/UserAffectation";
import { useDispatch, useSelector } from "react-redux";
import { getParticipants, getTeams } from "../../redux/studentManagement/actions";

const StudentsList = ({ elements, onActiveElementChange }) => {

    const dispatch = useDispatch();
    const [active_element, setActiveElement] = useState(null);
    const [showAffectation, setShowAffectation] = useState(false);
    const trainingSessionId = useSelector((state) => state.moderator_training_sessions.selected_session.id)

    useEffect(() => {
        dispatch(getParticipants(trainingSessionId));
        // dispatch(getQuizStatistics(trainingSessionId));
        dispatch(getTeams(trainingSessionId));
    }, [])

    const handleActiveElementChange = (element) => {

        // this.setState({
        //     ...this.state,
        //     active_element: element
        // });

        setActiveElement(element)
        onActiveElementChange(element);
    }

    const onCancel = () => {
        setShowAffectation(!showAffectation)
    }

    // const { elements } = this.props;
    // console.log('elements', elements);

    return <>
        <UserAffectation isOpen={showAffectation} onCancel={onCancel} />
        <Translation>
            {t => {
                return (
                    <div className="NE_students_list">
                        <div className="list_header">
                            <p>{elements?.length} {t("moderator.training-sessions.statics.session-dashboards.dashboard.elements_list_title")}</p>
                        </div>
                        <div className="list_items">
                            {elements?.map(element => {
                                const is_active = active_element?.id === element.id;
                                let class_name = "list_item ";
                                class_name += is_active ? "list_item_active" : "";
                                return (
                                    <div key={element.id} className={class_name} onClick={() => { handleActiveElementChange(element) }}>
                                        <img src={element?.image_path ? element.image_path : `https://avatars.dicebear.com/api/initials/${element?.full_name}.svg?radius=50&size=42`} alt="Student" />
                                        <p>{element?.full_name}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <button className="btn_affectation" onClick={() => setShowAffectation(true)} >
                            Affectation
                        </button>
                    </div>
                );
            }}
        </Translation>
    </>
}
export default StudentsList;