import React, { useEffect, useRef, useState} from "react";
import {Translation} from "react-i18next";
import './index.css'
import NormalInputWithActionButton from "../NormalInputWithActionButton";
import { useSelector} from "react-redux";



const initialState = {
    current_comment: {
        parent_id: null,
        content: ""
    },
    current_replay_comment: {
        content: "",
        parent_id: null
    },
    replay_id_comment: null,
    replay_id_comment_2: {
        id_parent:null,
        id:null
    }
};

const  CommentsTree = ({onAddComment,discussionId})=>{

    const [current_comment, setCurrent_comment] = useState(initialState.current_comment);
    const [current_replay_comment, setCurrent_replay_comment] = useState(initialState.current_replay_comment);

    const [replay_id_comment, setReplay_id_comment] = useState(initialState.replay_id_comment);

    const [replay_id_comment_2, setReplay_id_comment_2] = useState(initialState.replay_id_comment_2);

    const {messages} = useSelector((state)=>state.moderator_training_sessions.current_dashboard_selected_element);

    const commentsList = useRef();

    const clearState = () => {
        setCurrent_comment(initialState.current_comment);
        setCurrent_replay_comment(initialState.current_replay_comment);
        setReplay_id_comment(initialState.replay_id_comment);
        setReplay_id_comment_2(initialState.replay_id_comment_2)
    };

   const changeCurrentCommentContent = (content , parent_id ) => {
       setCurrent_comment({
           content:content ,
           parent_id:parent_id
       })

    };
    const changeCurrentReplayCommentContent = (content , parent_id ) => {
        setCurrent_replay_comment({content , parent_id})
    };

    const handleSendCommentClick = () => {

        onAddComment(discussionId,current_comment.parent_id,current_comment.content);
        clearState();
    };

    const handleSendReplayCommentClick = () => {
        const {parent_id,content} = current_replay_comment;

        onAddComment(discussionId,parent_id,content);
        clearState();
    };

    useEffect(() => {
        commentsList?.current?.scrollIntoView({ behavior: "smooth" });
    }, );


    const replayComment = (id,reply_id)=>{
        if(reply_id){
            setReplay_id_comment_2({
                id_parent:id,
                id:reply_id
            })
        }
        else
            setReplay_id_comment(id)
    };

        return <Translation>
            {t => {
                return (
                        <div className="NE_comments_tree">
                            <div className="comments_list">
                                {messages?.map(comment => {
                                    return (
                                        <div key={comment.id} className="comment">
                                            <div className="comment_header">
                                                <div className="comment_user">
                                                    <img src={comment.user.avatar_path ? comment.user.avatar_path : `https://avatars.dicebear.com/api/initials/${comment.user.full_name}.svg?radius=50&size=42`} alt="commenting user"/>
                                                    <h1>{comment.user.full_name}</h1>
                                                </div>
                                                <p className="comment_date">{comment.time}</p>
                                            </div>

                                            <div className="comment_body">
                                                <p>{comment.content}</p>
                                               {replay_id_comment === comment.id ? <NormalInputWithActionButton
                                                    id="response"
                                                    // label={"."}
                                                    actionText={t("moderator.training-sessions.statics.session-dashboards.dashboard-discussion.fields.reply_button")}
                                                    placeholder={t("moderator.training-sessions.statics.session-dashboards.dashboard-discussion.fields.reply_placeholder")}
                                                    value={current_replay_comment.content}
                                                    onChange={(e) => {changeCurrentReplayCommentContent(e.target.value ,comment.id )}}
                                                    onActionClick={handleSendReplayCommentClick}
                                                />:
                                                   <span onClick={()=>replayComment(comment.id)} >{t("moderator.training-sessions.statics.session-dashboards.dashboard-discussion.reply_link_title")}</span>
                                               }
                                                <div className="sub_comments">
                                                    {comment.replies?.map(reply => {
                                                        return (
                                                            <div key={reply.id} className="comment">
                                                                <div className="comment_header">
                                                                    <div className="comment_user">
                                                                        <img src={reply.user.avatar_path ? reply.user.avatar_path : `https://avatars.dicebear.com/api/initials/${reply.user.full_name}.svg?radius=50&size=42`} alt="commenting user"/>
                                                                        <h1>{reply.user.full_name}</h1>
                                                                    </div>
                                                                    <p className="comment_date">{reply.time}</p>
                                                                </div>

                                                                <div className="comment_body">
                                                                    <p>{reply.content}</p>
                                                                    {replay_id_comment_2.id === reply.id && replay_id_comment_2.id_parent === comment.id ?
                                                                        <NormalInputWithActionButton
                                                                            id="response"
                                                                            // label={"."}
                                                                            actionText={t("moderator.training-sessions.statics.session-dashboards.dashboard-discussion.fields.reply_button")}
                                                                            placeholder={t("moderator.training-sessions.statics.session-dashboards.dashboard-discussion.fields.reply_placeholder")}
                                                                            value={current_replay_comment.content}
                                                                            onChange={(e) => {changeCurrentReplayCommentContent(e.target.value ,comment.id )}}
                                                                            onActionClick={handleSendReplayCommentClick}
                                                                        />
                                                                        :
                                                                        <span onClick={()=>replayComment(comment.id,reply.id)}>{t("moderator.training-sessions.statics.session-dashboards.dashboard-discussion.reply_link_title")}</span>

                                                                    }
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                <sapn ref={commentsList} />
                            </div>

                            <div className="reply_box">
                                <NormalInputWithActionButton
                                    id="response"
                                    label={t("moderator.training-sessions.statics.session-dashboards.dashboard-discussion.fields.reply_label")}
                                    actionText={t("moderator.training-sessions.statics.session-dashboards.dashboard-discussion.fields.reply_button")}
                                    placeholder={t("moderator.training-sessions.statics.session-dashboards.dashboard-discussion.fields.reply_placeholder")}
                                    value={current_comment.content}
                                    onChange={(e) => changeCurrentCommentContent(e.target.value) }
                                    onActionClick={handleSendCommentClick}
                                />
                            </div>
                        </div>
                );
            }}
        </Translation>
    // }
}



export default CommentsTree;
