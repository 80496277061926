import {
  LOAD_ALL_SESSIONS,
  LOAD_ALL_SESSIONS_SUCCESS,
  LOAD_ALL_SESSIONS_ERROR,
  CLOSE_ALERT,
  PUSH_ALERT,
  CHANGE_CURRENT_SESSION_PROP_VALUE,
  CHANGE_CURRENT_SESSION_VALIDATION_PROP_VALUE,
  CREATE_SESSION,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_ERROR,
  SET_CURRENT_SESSION,
  UPDATE_SESSION,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_ERROR,
  SET_SELECTED_SESSION,
  DELETE_SESSION,
  DELETE_SESSION_SUCCESS,
  DELETE_SESSION_ERROR,
  CLOSE_SESSION_CODE_MODAL,
  SET_SELECTED_DASHBOARD,
  LOAD_SELECTED_SESSION_KPIS,
  LOAD_SELECTED_SESSION_KPIS_ERROR,
  LOAD_SELECTED_SESSION_KPIS_SUCCESS,
  SET_CURRENT_DASHBOARD_SELECTED_ELEMENT,
  CHANGE_STUDENT_ACTIVATION,
  CHANGE_STUDENT_ACTIVATION_ERROR,
  CHANGE_STUDENT_ACTIVATION_SUCCESS,
  CREATE_COMMENT,
  CREATE_COMMENT_ERROR,
  CREATE_COMMENT_SUCCESS, RESET_SELECTED_SESSION_KPIS
} from "./action-definitions";

import { findIndex } from "lodash";

// Initial state
const INIT_STATE = {
  sessions_list: [],
  current_session: {
    title: "",
    start_date: "",
    end_date: "",
    has_satisfaction_study: false
  },
  current_session_validation: {
    title: {
      is_valid: false,
      error: ""
    },
    start_date: {
      is_valid: false,
      error: ""
    },
    end_date: {
      is_valid: false,
      error: ""
    }
  },
  session_code_modal: {
    is_open: false,
    code: ""
  },
  selected_session: null,
  selected_session_kpis: null,
  selected_dashboard: null,
  current_dashboard_selected_element: null,
  is_loading: false,
  alerts: []
};

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_ALL_SESSIONS:
      return { ...state, is_loading: true };

    case LOAD_ALL_SESSIONS_SUCCESS:
      return {
        ...state,
        sessions_list: [...action.payload],
        is_loading: false
      };

    case LOAD_ALL_SESSIONS_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };

    case CHANGE_CURRENT_SESSION_PROP_VALUE:
      return {
        ...state,
        current_session: {
          ...state.current_session,
          [action.payload.propName]: action.payload.propValue
        }
      };

    case CHANGE_CURRENT_SESSION_VALIDATION_PROP_VALUE:
      return {
        ...state,
        current_session_validation: {
          ...state.current_session_validation,
          [action.payload.propName]: action.payload.propValue
        }
      };

    case CREATE_SESSION:
      return { ...state, is_loading: true };

    case CREATE_SESSION_SUCCESS:
      return {
        ...state,
        sessions_list: [...state.sessions_list, { ...action.payload }],
        session_code_modal: {
          ...state.session_code_modal,
          is_open: true,
          code: action.payload.training_session_code
        },
        is_loading: false,
        current_session: INIT_STATE.current_session,
        current_session_validation: INIT_STATE.current_session_validation
      };

    case CREATE_SESSION_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false,
        current_session: INIT_STATE.current_session,
        current_session_validation: INIT_STATE.current_session_validation
      };

    case UPDATE_SESSION:
      return { ...state, is_loading: true };

    case UPDATE_SESSION_SUCCESS:
      // Find index of session to be updated
      const session_index = findIndex(
        state.sessions_list,
        session => session.id === action.payload.id
      );

      // Create an updated list of sessions
      const updated_sessions_list = [...state.sessions_list];
      updated_sessions_list.splice(session_index, 1, action.payload);

      return {
        ...state,
        sessions_list: [...updated_sessions_list],
        is_loading: false,
        current_session: INIT_STATE.current_session,
        current_session_validation: INIT_STATE.current_session_validation
      };

    case UPDATE_SESSION_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false,
        current_session: INIT_STATE.current_session,
        current_session_validation: INIT_STATE.current_session_validation
      };

    case DELETE_SESSION:
      return { ...state, is_loading: true };


    case RESET_SELECTED_SESSION_KPIS:
      return {
        ...state, selected_session: null,
        selected_session_kpis: null
      };

    case DELETE_SESSION_SUCCESS:
      return {
        ...state,
        sessions_list: [
          ...state.sessions_list.filter(
            session => session.id !== action.payload
          )
        ],
        is_loading: false,
        selected_session: null,
        selected_session_kpis: null
      };



    case DELETE_SESSION_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };

    case LOAD_SELECTED_SESSION_KPIS:
      return { ...state, is_loading: true };

    case LOAD_SELECTED_SESSION_KPIS_SUCCESS:
      return {
        ...state,
        selected_session_kpis: { ...action.payload },
        is_loading: false
      };

    case LOAD_SELECTED_SESSION_KPIS_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };

    case CHANGE_STUDENT_ACTIVATION:
      return { ...state, is_loading: true };

    case CHANGE_STUDENT_ACTIVATION_SUCCESS:
      return {
        ...state,
        current_dashboard_selected_element: {
          ...state.current_dashboard_selected_element,
          is_active: action.payload
        },
        is_loading: false
      };

    case CHANGE_STUDENT_ACTIVATION_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };

    case CREATE_COMMENT:
      return { ...state, is_loading: true };

    case CREATE_COMMENT_SUCCESS:
      if (action.payload.parent_id !== null) {
        let listMessages = {};
        const dashboard_discussion = state.selected_session_kpis.dashboard_discussion.map(
          discussion => {
            if (discussion.id === action.payload.discussion_id) {
              listMessages = {
                ...discussion,
                messages: discussion.messages.map(message => {
                  if (message.id === action.payload.parent_id)
                    return {
                      ...message,
                      replies: [...(message.replies || []), action.payload]
                    };

                  return message;
                })
              };
              return listMessages;
            }
            return discussion;
          }
        );

        return {
          ...state,
          is_loading: false,
          selected_session_kpis: {
            ...state.selected_session_kpis,
            dashboard_discussion: dashboard_discussion
          },
          current_dashboard_selected_element: {
            ...state.current_dashboard_selected_element,
            messages: listMessages.messages
          }
        };
      } else {
        // Get targeted discussion index
        const targetedDiscussionIndex = findIndex(
          state.selected_session_kpis.dashboard_discussion,
          discussion => discussion.id === action.payload.discussion_id
        );

        // Get the targeted discussion
        let targetedDiscussion = {
          ...state.selected_session_kpis.dashboard_discussion[
          targetedDiscussionIndex
          ]
        };

        // Update the targeted discussion
        targetedDiscussion = {
          ...targetedDiscussion,
          comments_count: targetedDiscussion.comments_count + 1,
          messages: [...targetedDiscussion.messages, { ...action.payload }]
        };

        // Create an updated list of discussions
        const updatedDiscussions = [
          ...state.selected_session_kpis.dashboard_discussion
        ];
        updatedDiscussions.splice(
          targetedDiscussionIndex,
          1,
          targetedDiscussion
        );

        return {
          ...state,
          selected_session_kpis: {
            ...state.selected_session_kpis,
            dashboard_discussion: [...updatedDiscussions]
          },
          current_dashboard_selected_element: {
            ...state.current_dashboard_selected_element,
            messages: [
              ...state.current_dashboard_selected_element.messages,
              { ...action.payload }
            ],
            comments_count:
              state.current_dashboard_selected_element.comments_count + 1
          },
          is_loading: false
        };
      }

    case CREATE_COMMENT_ERROR:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ],
        is_loading: false
      };

    case SET_CURRENT_SESSION:
      return {
        ...state,
        current_session: { ...action.payload },
        current_session_validation: { ...INIT_STATE.current_session_validation }
      };

    case SET_SELECTED_SESSION:
      return { ...state, selected_session: { ...action.payload }, selected_dashboard: null };

    case SET_SELECTED_DASHBOARD:
      return {
        ...state,
        selected_dashboard: action.payload,
        current_dashboard_selected_element:
          INIT_STATE.current_dashboard_selected_element,
        // 
      };

    case SET_CURRENT_DASHBOARD_SELECTED_ELEMENT:
      return {
        ...state,
        current_dashboard_selected_element: { ...action.payload }
      };

    case CLOSE_SESSION_CODE_MODAL:
      return {
        ...state,
        session_code_modal: { ...INIT_STATE.session_code_modal }
      };

    case PUSH_ALERT:
      return {
        ...state,
        alerts: [
          ...state.alerts,
          {
            id: action.payload.id,
            type: action.payload.type,
            message: action.payload.message
          }
        ]
      };

    case CLOSE_ALERT:
      return {
        ...state,
        alerts: [...state.alerts.filter(alert => alert.id !== action.payload)]
      };

    default:
      return { ...state };
  }
};

export default reducer;
