import React, {createContext, useContext, useEffect, useState} from "react";
import './index.css'
import icon from './assets/icon-question.svg';
import style from "../quiz-component/style.module.scss";
import {uid} from "uid";
import {
    addEvaluation,
    deleteEvaluation,
    restElement
} from "../../../../../redux/moderator/configure-course/actions-creators";
import {useDispatch, useSelector} from "react-redux";
import DeleteElementsModal from "../delete-elements-modal";
import Textarea from "../../../../../components/Textarea";
import Switch from "../../../../../components/Switch";
import PublishedElement from "../../../edit-course/components/PublishedElement/PublishedElement";
import Loader from "../../../../../components/Loader";


const ItemContext = createContext({
    items: [],
    _id: null,
    question: "",
    showButtons:true,
    justification:"",
    addItem: () => {
    },
    deleteItem: () => {
    },
    editItem: () => {
    },
    editQuestion: () => {
    },
    changeQuestionOption: () => {
    }
});

const initialState = [
    // {
    //     text: "option",
    //     question_option:true,
    //     name_group:1,
    //     _id: uid()
    // }
];


const ItemProvider = (props) => {

    const [items, setItems] = useState(initialState);
    const [question, setQuestion] = useState("");
    const [showButtons, setShowButtons] = useState(true);
    const [justification, setJustification] = useState("");
    // const {items:items_, editItem:editItem_ } = useContext(ModuleItemContext);


    const addItem = (item) => setItems((items) => [...items, item]);
    const deleteItem = (id) =>
        setItems((items) => items.filter((item) => item._id !== id));

    const editItem = (id, text) => {
        const list = items.map((elem) => {
            if (elem._id === id) return {...elem, text: text};
            return elem;
        });
        setItems(list);
    };

    const editQuestion = (text) => {
        setQuestion(text);
    };

    const editJustification = (text) => {
        setJustification(text);
    };

    const changeQuestionOption = (id) => {
        const list = items.map((elem) => {
            if (elem._id === id) return {...elem, question_option: true};
            return {...elem, question_option: false};
        });

        setItems(list);
    };

    useEffect(() => {

        const list = props.ListQuiz.map((elem, index) => {
            if (elem._id === props.quiz._id) return {...elem, items, question , justification}
            return elem;
        });

        props.setList(list);

    }, [items, question , justification]);

    useEffect(() => {
        setShowButtons(props.showButtons)
    }, [props.showButtons]);



    const value = {
        items,
        question,
        showButtons,
        addItem,
        deleteItem,
        editItem,
        editQuestion,
        changeQuestionOption,
        justification ,
        editJustification
    };

    return (
        <ItemContext.Provider value={value}>{props.children}</ItemContext.Provider>
    );
};

function ItemQuestionComponent({elem}) {

    const [isEdit, setIsEdit] = useState(false);
    const {deleteItem, editItem, changeQuestionOption ,showButtons} = useContext(ItemContext);

    return <li className={`question_option  ${elem.question_option ? 'question_option_selected' : ''} `}>
        <div className={`NE_radio_button ${style.NE_radio_button}`}>
            <input type="radio" name={elem.name_group} defaultChecked={elem.question_option} id={elem._id}
                   onClick={() => showButtons ?  changeQuestionOption(elem._id) : null}/>
            {isEdit && showButtons ?
                <label htmlFor={elem._id}>
                    <div className={style.block_}>
                        <input className={style.input} onChange={(e) => editItem(elem._id, e.target.value)}
                               value={elem.text}/>
                    </div>
                </label>
                :
                <label htmlFor={showButtons ? elem._id :"-1"}>
                    {elem.text}
                </label>
            }

        </div>
       {showButtons && <>
       <span className={`question_option_action ${isEdit ? style.btn_save : style.btn_edit}`}
             onClick={() => setIsEdit(!isEdit)}/>
           <span className={`${style.question_option_action_trash}`} onClick={() => deleteItem(elem._id)}/>
           </>}
        { elem.question_option &&
        <span className={`${style.question_option_action_valid}`}/>
        }

        {/*<span className="question_option_action" onClick={onDelete(elem._id)} />*/}
    </li>;
}

function ListQuestionComponent({name_group}) {
    const [list, setList] = useState([]);
    const {items, addItem , showButtons} = useContext(ItemContext);

    useEffect(() => {
        if (items.length === 0){
            addElement();
            addElement();
            addElement();
            addElement();
        }
    }, []);

    const addElement = () => {
        addItem({
            text: "option",
            question_option: false,
            name_group: name_group,
            _id: uid()
        });
    }
    const onDelete = (id) => (e) => {
        setList(list.filter(elm => elm._id !== id))
    }
    return <ul className="question_options">
        {items.map((elem, index) => <ItemQuestionComponent elem={elem} key={elem.id} onDelete={onDelete}/>)}

        {showButtons && <li className="add_question_option" onClick={addElement}>
            Add Option
        </li>}
    </ul>;
}


function QuizFormComponent({element,deleteElement , current_course}) {
    const {editQuestion, question,showButtons , justification , editJustification} = useContext(ItemContext);

    return <div className={`form-field ${style.form_field}`}>
        <div className="question">
            <div className="NE_normal_input">
                <div  className={`${style.block_elemnt}`}>
                    <label htmlFor="question1">Question</label>
                    {!current_course.publish && <span className={`${style.question_option_action_trash}`}
                           onClick={() => deleteElement(element._id)}/>}
                </div>

                <input type="text" id="question1"
                       value={question}
                       onChange={(e) => editQuestion(e.target.value)}
                       placeholder="Write your question"
                      disabled={!showButtons}
                />
            </div>
            <ListQuestionComponent name_group={element._id}/>
            <div className="NE_normal_input">
                <Textarea
                    value={justification}
                    onChange={(e) => editJustification(e.target.value)}
                    placeholder="Justificatif de la réponse"
                />
                <p>This is a message</p>
            </div>
        </div>
    </div>
}

function QuizFormProvider({element, setList, ListQuiz,showButtons , deleteElement , current_course}) {

    return <ItemProvider showButtons={showButtons} quiz={element} ListQuiz={ListQuiz} setList={setList}>
        <QuizFormComponent current_course={current_course} deleteElement={deleteElement} element={element}/>
    </ItemProvider>
        ;
}

const EvaluationCard = ({element}) => {

    const [is_open, setIsOpen] = useState(true);
    const [is_open_delete, setIsOpenDelete] = useState(false);
    const [showButtons, setShowButtons] = useState(true);
    const [List, setList] = useState([]);
    const [loading, setLoading] = useState(false);


    const dispatch = useDispatch();
    const {current_course} = useSelector(state => state.moderator_configure_course)
    const addElement = () => {
        setList([...List, {_id: uid()}])
    };


    const toggleCollapse = () => {
        setIsOpen(!is_open);
    }


    let class_name = "NE_collapsable_card_with_icon quiz_creation_card ";
    class_name += is_open ? "NE_collapsable_card_with_icon_open" : "";

    const deleteEvaluationButton = () => {
        dispatch(
            deleteEvaluation(element._id, element.id)
        );
        setIsOpenDelete(false);
    }

    const isValid = ()=>{

        if(List.length<=0) return false;

        return List.reduce((previousValue, currentValue) =>
        {
            let found = false;
            if (currentValue?.items?.length > 0){
                found  = !!currentValue.items.find(element => element.question_option === true);
            }


            return  currentValue.question !== "" && currentValue.items?.length > 0 && found && previousValue;
        }, true);

    }

    const deleteElement = (id) => {
        setList((prev)=> prev.filter((elm)=>elm._id!== id))
    };

    return <>
        <DeleteElementsModal  isOpen={is_open_delete} onCancel={()=> setIsOpenDelete(false)} onDelete={deleteEvaluationButton} />
        <Loader isOpen={loading} />
        <div className={class_name}>
            <div className="header">
                <div className="title">
                    <img src={icon} alt="Container icon"/>
                    <h2>{element?.title}</h2>
                </div>


                {!current_course.publish &&<div className="delete_element_content">
                    {element.id && <PublishedElement element={element} type={"evaluation"}/>}

                    <span className="btn_delete_element" onClick={() => {
                       setIsOpenDelete(true)
                   }}>

                   </span>
                    <span className="btn_collapse" onClick={() => {
                        toggleCollapse()
                    }}/>
                </div>}
            </div>
            <div className="body">
                <form>
                    {
                        List.map((elem, index) => <QuizFormProvider current_course={current_course} deleteElement={deleteElement} showButtons={showButtons} ListQuiz={List} setList={setList}
                                                                       element={elem} key={elem._id}/>)
                    }
                    {showButtons && <div className="add_question" onClick={addElement}/>}


            {showButtons && List.length > 0 &&

                <div className="actions mt-2">
                    <div className="action">
                        <button className="NE_button_large NE_button_outline"
                                type="reset"
                                onClick={()=>{
                                    dispatch(restElement(element))
                                }}
                        >Cancel
                        </button>
                    </div>

                    <div className="action">
                        <button disabled={!isValid()} className="NE_button_large NE_button_dark" type="button" onClick={() => {
                            setLoading(()=>true)
                            dispatch(addEvaluation(
                                element,current_course.id, List, current_course.elements.findIndex((e) => e._id === element._id)
                                , () => {
                                    setShowButtons(false);
                                    setLoading(()=>false);
                                }))
                        }
                        }>Save
                        </button>
                    </div>
                </div>

            }
                </form>
            </div>
        </div>
    </>

}
export default EvaluationCard;
