import React, {Component} from "react";
import './index.css'

class ModeratorCard extends Component{


    render() {
        // Get props
        const {moderator,isSelected, onClick} = this.props;

        let class_name = "NE_moderator_card ";
        class_name += isSelected ? "NE_moderator_card_selected" : "";

        return <>
            <div className={class_name} onClick={() => {onClick(moderator);}}>
                <div className="card_header">
                    <div className="card_image">
                        <img src={moderator && (moderator.image !== null ? moderator.image : `https://avatars.dicebear.com/api/initials/${moderator?.full_name}.svg?radius=50&size=200`)} alt="Moderator" />
                    </div>
                </div>
                <div className="card_body">
                    <h1>{moderator && moderator.full_name}</h1>
                    <p>{moderator && moderator.email}</p>
                </div>
            </div>
        </>
    }
}
export default ModeratorCard;