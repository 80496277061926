import React, { Component } from "react";
import './index.css'
import history from "../../../utils/history";
import { Translation } from "react-i18next";

class ModeratorCardLatestComments extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { comments_list } = this.props
    console.log('comments_list', comments_list)
    return (
      <Translation>
        { t =>
          <>
            <div className="Moderator_NE_latest_comments_card">
              <div className="card_body">
                <h1>{t("comments.latest_comments")}</h1>
                <div className="comments_wrapper">
                  {comments_list?.map((obj, key) => {
                    return (

                      <div className="comment" key={key}>
                        <div className="comment_body">
                          <img src={obj?.user?.avatar_path || "https://i.pravatar.cc/34"}
                            alt="Profile Avatar" width="34" height="34" />
                          <div className="comment_infos">
                            <div className="comment_user">
                              <h2>{obj.user.full_name}</h2>
                              <p>{t("comments.add_comment")} <span className="course_name"
                                onClick={() => {
                                  history.push(
                                    "/moderator/training-sessions/" + obj.course_id
                                    + "?disccussion=" + obj.discussion_id + "&session=" + obj.training_session_id
                                  )
                                }}

                              >{obj.course_title}</span></p>
                            </div>

                            <div className="comment_text">
                              <p className="comment_text_paragraph">
                                {obj.content}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}

                </div>
              </div>
            </div>
          </>
        }
      </Translation>
    )
  }
}

export default ModeratorCardLatestComments;
