import Joi from "@hapi/joi";

// export default Joi.object({
//     full_name: Joi.string().alphanum().min(3).required(),
//     type: Joi.string().min(3).required(),
//     phone: Joi.string().pattern(new RegExp("^\+[0-9]{1,3} [0-9]{4,}$")).required(),
//     email: Joi.string().email().required(),
//     address: Joi.string().min(3).required(),
// });

export const image_size_schema = Joi.number()
  .integer()
  .max(5000000);
export const full_name_schema = Joi.string()
  .min(3)
  .required();
export const title_schema = Joi.string()
  .min(5)
  .required();
export const description_schema = Joi.string()
  .min(5)
  .required()
  .messages({
    'string.min': 'La description doit comporter au moins 5 caractères.',
    'any.required': 'La description est obligatoire.',
  })
   .empty('', { 'string.empty': 'La description ne peut pas être vide.' });
export const language_schema = Joi.string()
  .min(1)
  .required();
export const type_schema = Joi.string()
  .min(3)
  .required();
export const phone_schema = Joi.string()
  // .pattern(new RegExp("^\\+[0-9]{1,3} [0-9]{4,}$"))
  .required();
export const email_schema = Joi.string()
  .email({ tlds: { allow: false } })
  .required()
    .messages({
        'string.email': 'Veuillez entrez une adresse e-mail valide',
        'string.empty': `l'email ne peut pas être un champ vide.`,
        'any.required': `l'email est un champ obligatoire`
    })

export const address_schema = Joi.string()
  .min(3)
  .required();
export const date_schema = Joi.date().required();
export const password_schema = Joi.string()
  .alphanum()
  .min(5)
  .required()
  .messages({
        'string.empty': `Veuillez remplir le champ du mot de passe avant de continuer!`,
        'string.min': `Le mot de passe doit avoir une longueur minimale de {#limit}`,
        'any.required': `Le mot de passe est un champ obligatoire`
  })
export const session_title_schema = Joi.string()
  .min(3)
  .required()
    .messages({
        'string.empty': `Veuillez remplir le champ du nom de la session avant de continuer!`,
        'string.min': `Le nom de la session doit avoir une longueur minimale de {#limit}`,
        'any.required': `Le nom de la session est un champ obligatoire.`
    })
