import React, {Component} from "react";
import './index.css'
import {connect} from "react-redux";
import history from "../../../utils/history";
import {editCourse, publishCourse} from "../../redux/moderator/configure-course/actions-creators"
import EditCourseModal from "../../modules/moderator/configure-course/components/edit-course-modal";
class CoursePublishOverview extends Component{

    state = {
        step:"publish",
        openEditCourse:false
    }
    isValid = ()=>{

        if(this.props.moderator_configure_course.current_course.elements.length<=0) return false;

        return this.props.moderator_configure_course.current_course.elements.reduce((previousValue, currentValue) =>
        {
            return  currentValue?.id !== undefined && previousValue
        }, true);

    }
    onClick=()=>{
        // history.push("/moderator");
        if(this.state.step === "publish"){
            this.setState({
                step:"View"
            })
            this.props.publishCourse();
        }
        else {
            history.push("/moderator/training-sessions/"+this.props.moderator_configure_course.current_course.id)
        }

    }

    onSaveEditCourse = (data) => {


        const {id,image} = this.props.courseData;

        const course = {...data,id,img:data.img ? data.img :image}


        this.props.editCourse(course,()=>{
        });
        this.setState({
            openEditCourse:false
        });

    }

    onCloseEditCourse = () => {
        this.setState({
            openEditCourse:false
        });
    }




    render() {
        const {title, image_path,image, description,category} = this.props.courseData;

        // courseData={current_course}

        return <>
            <EditCourseModal
                isOpen={this.state.openEditCourse}
                onSave={this.onSaveEditCourse}
                onCancel={this.onCloseEditCourse}
                course={this.props.courseData}
            />
            <div className="NE_course_publish_overview">
                <div className="course_overview">
                    <img src={image_path ? image_path :(image ? image : "")} alt="Course Image"/>

                    <div className="course_infos">
                        <div className="category" style={{'backgroundColor': category && category.color}}>{category && category.title}</div>
                        <h1>{title && title}</h1>
                        <p>{description && description}</p>
                    </div>
                </div>

                <div className="actions">
                    <div className="action">
                        <button onClick={this.onClick} className="NE_button_large NE_button_dark" disabled={!this.isValid()}>{this.state.step === "publish" ?"Publier":"Voir cours"}</button>

                        <button
                            className="NE_button_large NE_button_dark mt-1"
                            onClick={()=>{
                                this.setState({
                                    openEditCourse:!this.state.openEditCourse
                                })
                            }}
                        >Modifier</button>

                    </div>
                </div>

            </div>
        </>
    }
}

const mapStateToProps = state => {
    const { moderator_configure_course } = state;
    return {   moderator_configure_course};
};

const mapActionsToProps = {
    publishCourse,
    editCourse
}
export default connect(mapStateToProps, mapActionsToProps)(CoursePublishOverview);
