import React, {createContext, useContext, useState} from "react";
import './index.css'
import icon from './assets/icon.svg';
import iconNotion from './assets/file-remove.svg';
import add from "../../../../../components/ToggleBarButton/assets/add.svg";
import QuizComponent from "../quiz-component";
import RichEditor from "../editor";
import * as PropTypes from "prop-types";
import {uid} from "uid";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {addNotion, deleteModule, deleteNotion} from "../../../../../redux/moderator/configure-course/actions-creators";
import DeleteElementsModal from "../delete-elements-modal";
import DocumentsUploader2 from "../../../../../components/DocumentsUploader2";
import Switch from "../../../../../components/Switch";
import PublishedElement from "../../../edit-course/components/PublishedElement/PublishedElement";
import Loader from "../../../../../components/Loader";

export const ModuleItemContext = createContext({
    items: [],
    addItem: () => {
    },
    deleteItem: () => {
    },
    editItem: () => {
    },
    changeQuestionOption: () => {
    }
});

const initialState = [
    // {
    //     text: "option",
    //     question_option:true,
    //     type:"quiz",
    //     _id: uid()
    // }
];

const ModuleItemsProvider = (props) => {

    const [items, setItems] = useState(initialState);

    const addItem = (item) => setItems((items) => [...items, item]);
    const deleteItem = (id) =>
        setItems((items) => items.filter((item) => item._id !== id));

    const editItem = (elem) => {
        setItems((items) => items.map((item) => {
            if (item._id === elem._id) return {...item, ...elem};
            return item;
        }));
    };


    const value = {
        items,
        addItem,
        deleteItem,
        editItem
    };

    return (
        <ModuleItemContext.Provider value={value}>{props.children}</ModuleItemContext.Provider>
    );
};


function NotionFormComponent({elem, module, index, current_course}) {
    const {register, control, watch, formState: {errors}, handleSubmit} = useForm();

    const [isCollapse, setIsCollapse] = useState(false);
    const [showButtons, setShowButtons] = useState(true);
    const {items, addItem, editItem, deleteItem} = useContext(ModuleItemContext);
    const [is_open_delete, setIsOpenDelete] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    React.useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            editItem({_id: elem._id, ...value});
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const file_name = ""; //elem.doc !== undefined ? elem.doc[0].name :  null;

    const onSubmit = data => {
        setLoading(()=>true)
        const docs = data.doc.length > 0 ? [...data.doc] : [];
        dispatch(addNotion({
            ...elem,
            module_id: module.id,
            _id: module._id,
            element_order: index,
            doc: docs
        }, () => {
            setShowButtons(()=>false)
            setLoading(()=>false)
        }))
    };

    const deleteNotionButton = () => {

        const notion = module?.notions?.find((n) => n.element_order === index);
        if (notion?.id) {
            dispatch(deleteNotion(module.id, notion.id, () => {
                deleteItem(elem._id)
            }))

        } else {
            deleteItem(elem._id)
        }

        setIsOpenDelete(false)

    }

    return <div className={`body_element_notion ${isCollapse ? "isCollapse" : ""}`}>
        <Loader  isOpen={loading} />
        <DeleteElementsModal isOpen={is_open_delete} onCancel={() => setIsOpenDelete(false)}
                             onDelete={deleteNotionButton}/>

        <div
            className="NE_collapsable_card_with_icon NE_collapsable_card_with_icon_open notion_creation_card">
            <div className="header">
                <div className="title">
                    <img src={iconNotion}
                         alt="Container icon"/>
                    <h2>Notion</h2>
                </div>
                {!current_course.publish && <div className="delete_element_content">
                   <span className="btn_delete_element" onClick={() => {
                       setIsOpenDelete(true)
                       //
                   }}>

                   </span>
                    <span className="btn_collapse" onClick={() => {
                        setIsCollapse(!isCollapse)
                    }}/>
                </div>}

            </div>

            <div className="body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-field">
                        <div className="NE_normal_input">
                            <label htmlFor="title">Title</label>
                            <input type="text" {...register("title", {required: true})} placeholder="Notion title"/>
                            {errors.title?.type === 'required' && (
                                <span className="error_msg">Title is required</span>)
                            }
                        </div>
                    </div>

                    <div className="form-field">
                        <div className="NE_normal_input">
                            <label htmlFor="media">Media</label>
                            <input type="text" id="media" {...register("media")} placeholder="Add media link"/>
                        </div>
                    </div>

                    <div className="form-field">
                        <Controller
                            control={control}
                            name="doc"
                            // rules={{ required: true }}
                            render={({field: {onChange, onBlur, value, ref}}) => (
                                <DocumentsUploader2 activeDelete={showButtons} onChange={onChange}/>
                            )}
                        />
                    </div>

                    <div className="form-field mb-1">
                        <Controller
                            control={control}
                            name="description"
                            rules={{required: true}}
                            render={({field: {onChange, onBlur, value, ref}}) => (
                                <RichEditor value={""} onChange={onChange}/>
                            )}
                        />
                    </div>
                    {errors.description?.type === 'required' && (
                        <span className="error_msg">Description is required</span>)}

                    {showButtons &&
                        <div className="actions">
                            <div className="action">
                                <button className="NE_button_large NE_button_outline"
                                        type="reset">Cancel
                                </button>
                            </div>

                            <div className="action">
                                <button className="NE_button_large NE_button_dark"
                                        type="submit"
                                >
                                    Save
                                </button>
                            </div>
                        </div>}
                </form>
            </div>
        </div>
    </div>;
}

function ControlBtnComponent({onAddElement = () => null}) {
    const [isOpen, setIsOpen] = useState(false);
    return <div className="NE_toggle_bar_button NE_toggle_bar_button_open">
        <div className="toggle_button" onClick={() => setIsOpen(!isOpen)}>
            <img src={add} alt="Toggle button"/>
        </div>

        {isOpen && <div className="actions_bar actions_bar_st">
            <div className="action" onClick={onAddElement("notion")}>
                <div className="notion_icon"/>
                <p>Notion</p>
            </div>

            <div className="action" onClick={onAddElement("quiz")}>
                <div className="quiz_icon"/>
                <p>Quiz</p>
            </div>
        </div>}
    </div>;
}

function ListComponent(props) {
    const {items, addItem} = useContext(ModuleItemContext);

    const addItem_ = (type) => (e) => {
        addItem({
            type,
            _id: uid(),
        });
    }
    return <div className="body">
        {/*<pre>*/}
        {/*     {JSON.stringify(items, undefined, 2)}*/}
        {/*</pre>*/}
        {items.map(props.callbackfn)}
        {!props.current_course.publish && <ControlBtnComponent onAddElement={addItem_}/>}
    </div>;
}

ListComponent.propTypes = {
    listElements: PropTypes.arrayOf(PropTypes.any),
    callbackfn: PropTypes.func,
    onAddElement: PropTypes.func
};
const ModuleCard = (props) => {
    const {current_course} = useSelector(state => state.moderator_configure_course)

    const [listElements, setListElements] = useState([]);
    const [is_open, setIs_open] = useState(true);
    const [is_open_delete, setIsOpenDelete] = useState(false);


    const onAddElement = (type) => (e) => {

        setListElements([...listElements, {
            type
        }])
    }

    const {element, onSave} = props;


    let class_name = "NE_collapsable_card_with_icon quiz_creation_card ";
    class_name += is_open ? "NE_collapsable_card_with_icon_open" : "";


    const dispatch = useDispatch()

    const deleteEvaluationButton = () => {
        dispatch(
            deleteModule(element.id)
        )
    }

    return <ModuleItemsProvider>
        <DeleteElementsModal isOpen={is_open_delete} onCancel={() => setIsOpenDelete(false)}
                             onDelete={deleteEvaluationButton}/>

        <div className={class_name}>
            <div className="header">
                <div className="title">
                    <img src={icon} alt="Container icon"/>
                    <h2>{element?.title}</h2>
                </div>

                {!current_course.publish && <div className="delete_element_content">
                    <PublishedElement element={element} type={"module"} />

                    <span className="btn_delete_element" onClick={() => {
                       setIsOpenDelete(true)
                   }}>

                   </span>
                    <span className="btn_collapse" onClick={() => setIs_open(!is_open)}/>
                </div>}

            </div>

            <ListComponent current_course={current_course} listElements={listElements} callbackfn={(elem, index) => {
                if (elem.type === "notion") return <NotionFormComponent current_course={current_course} elem={elem}
                                                                        key={index} index={index}
                                                                        module={element}/>;
                return <QuizComponent current_course={current_course} quiz_id={elem._id} key={index} elem={elem}
                                      module={element} index={index}/>;

            }}/>
        </div>
    </ModuleItemsProvider>

}
export default ModuleCard;
