import React, {useState, useEffect} from "react";
import { Translation , useTranslation} from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addMessage,
  getMessages,
} from "../../redux/participant/participant-course-item/action-creators";
import "./index.css";


const EtudiantDiscussion = ({ clickedItem, discussions, onClickItem, callbackfn }) => {
  const {t} =  useTranslation();
  const {
    messages: { messages, originMessages },
  } = useSelector((state) => state.participant_course_item);
  const dispatch = useDispatch();

  useEffect(() => {
    const discussion_id = discussions?.find(d => d.element_id === clickedItem?.course?.id)?.id;
    dispatch(getMessages(discussion_id));
  }, [clickedItem]);

  const [active, setActive] = useState('active_button');
  const [disable, setDisable] = useState('disabled_button');

  return (
    <div
      style={{
        flex: 3,
        display: "flex",
        paddingBottom: 100,
        marginLeft: 19,
      }}
      className="NE_notionItem_container"
    >
      <div className="NE_notionItem_header">
        <h2 className="NE_notionItem_header_h2">Discussion</h2>
        {callbackfn()}
      </div>


      <div className="NE_comments_tree">
        <div className="comments_list">
          {originMessages.map((m, index) => (
            <CommentLine message={m} key={index} />
          ))}
        </div>

        <SendComment label={t("participant.discussion.reply_label_2")}  placeholder={t('participant.discussion.reply_placeholder_2')} />
      </div>


    </div>
  );
};

export default EtudiantDiscussion;

// import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   addMessage,
//   getMessages,
// } from "../../redux/participant/participant-course-item/action-creators";
// import "./index.css";

// const EtudiantDiscussion = ({ clickedItem, onClickItem }) => {
//   const {
//     messages: { messages, originMessages },
//   } = useSelector((state) => state.participant_course_item);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(getMessages(clickedItem?.id));
//   }, [clickedItem]);
//   return (
// <div className="NE_etudiant_discussion">
//   <div className="NE_discussion">
//     <div className="NE_notionItem_header">
//       <h2 className="NE_notionItem_header_h2" style={{marginLeft: 22}}>Discussion</h2>
//       <div className="NE_notionItem_header_button">
//         <div
//           className="disabled_button"
//           onClick={() => {
//             onClickItem(clickedItem?.course);
//           }}
//         >
//           <span>Course</span>
//         </div>

//         <div className="active_button">
//           <span>Discussion</span>
//         </div>
//       </div>
//     </div>

//     <div className="NE_comments_tree">
//       <div className="comments_list">
//         {originMessages.map((m, index) => (
//           <CommentLine message={m} key={index} />
//         ))}
//       </div>

//       <SendComment />
//     </div>
//   </div>
// </div>
//   );
// };
//
const CommentLine = ({ message, replay = false }) => {
  const {
    messages: { messages, originMessages },
  } = useSelector((state) => state.participant_course_item);

  const [replies, setReplies] = useState([]);
  const [isReplay, setIsReplay] = useState(false);

  useEffect(() => {
    setReplies(messages.filter((m) => m.parent_message_id === message.id));
  }, [messages]);
  return (

    <div className="comment">
      <div className="comment_header">
        <div className="comment_user">
          <img
            src={message?.user?.avatar_path || "https://i.pravatar.cc/42"}
            alt="commenting user"
          />
          <h1>{message?.user?.full_name}</h1>
        </div>
        <p className="comment_date">{message.posting_time}</p>
      </div>

      <div className={`comment_body ${replay ? 'pl-0' : ''}`}>
        <p>{message.content}</p>
        <span onClick={() => setIsReplay(true)}>Reply</span>

        {isReplay && (
          <SendComment
            onFinish={() => {
              setIsReplay(false);
            }}
            parent_message_id={message.id}
          />
        )}

        <div className="sub_comments">
          {replies.map((r, index) => (
            <CommentLine message={r} key={index} replay={true} />
          ))}
        </div>
      </div>
    </div>
  );
};

const SendComment = ({ parent_message_id = null, onFinish = () => { } , label , placeholder}) => {
  const { clickedItem, discussions } = useSelector((state) => state.participant_course_item);

  const dispatch = useDispatch();
  const [content, setContent] = useState("");
  const submit = () => {

    const discussion_id = discussions?.find(d => d.element_id === clickedItem?.course?.id)?.id;
    dispatch(addMessage(discussion_id, parent_message_id, content, onFinish));
    setContent("");
  };
  return (
    <Translation>
      { t =>
        <>

          <div className="reply_box">
            <div className="NE_normal_input_with_action_button ">
              <label htmlFor="test">{label ?? t("participant.discussion.reply_label")}</label>
              <input
                type="text"
                id="test"
                placeholder={placeholder ?? t("participant.discussion.reply_placeholder")}
                value={content}
                onChange={(e) => setContent(e.target.value)}
              />
              <p>This is a message</p>
              <div className="send_button" onClick={submit}>
                <h3>{t("participant.discussion.reply_button")}</h3>
              </div>
            </div>
          </div>
        </>
      }
    </Translation>
  );
};
//
// export default EtudiantDiscussion;
