import { axiosInstance, getSecureAxiosInstance } from "./axios-instance";

export const loadAllCourses = () => {
  return getSecureAxiosInstance().get("participant/training-sessions");
};
export const loadLastComments = () => {
  return getSecureAxiosInstance().get("participant/last-comments");
};
export const loadTrainingSession = training_session_id => {
  return getSecureAxiosInstance().get(
    "participant/training-session?training_session_id=" + training_session_id
  );
};
export const getDiscussionTrainingSession = training_session_id => {
  return getSecureAxiosInstance().get(
    "participant/discussions?training_session_id=" + training_session_id
  );
};

export const getMessages = discussion_id => {
  return getSecureAxiosInstance().get(
    "participant/messages?discussion_id=" + discussion_id
  );
};

export const getProgress = training_session_id => {
  return getSecureAxiosInstance().get(
    "participant/progress?training_session_id=" + training_session_id
  );
};

export const addMessage = comment => {
  const cleanEmpty = obj =>
    Object.entries(obj)
      .map(([k, v]) => [k, v && typeof v === "object" ? cleanEmpty(v) : v])
      .reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {});

  return getSecureAxiosInstance().post(
    "participant/save-comment",
    cleanEmpty(comment)
  );
};

export const subscribeTrainingSession = code => {
  return getSecureAxiosInstance().post("participant/subscribe?code=" + code);
};

export const updateProgress = (training_session_id, progress_id) => {
  return getSecureAxiosInstance().post(
    `participant/update-progress?training_session_id=${training_session_id}&progress_id=${progress_id}`,
    {}
  );
};
export const saveQuiz = (training_session_id, quiz_id, question_results) => {
  return getSecureAxiosInstance().post(`participant/save-responses`, {
    training_session_id,
    quiz_id,
    question_results
  });
};

export const getQuizResponses = (training_session_id, quiz_id) => {
  return getSecureAxiosInstance().get(
    `participant/quiz-responses?training_session_id=${training_session_id}&quiz_id=${quiz_id}`
  );
};
export const loadProfilePoints = () => {
  return getSecureAxiosInstance().get(`participant/profile`);
};
