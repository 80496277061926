import React, {useEffect, useRef, useState} from "react";
import './index.css'
import icon from './assets/icon-documents.svg';
import arrowUp from './assets/arrow-up.svg';
import arrowDown from './assets/arrow-down.svg';
import DocumentsUploader from "../../../../../components/DocumentsUploader";
import {
    addCourseDocumentsElement,
    deleteDocumentsSuccess,
    editDocumentsElement,
    editItemCourse,
    restElement
} from "../../../../../redux/moderator/configure-course/actions-creators";
import {connect, useDispatch} from "react-redux";
import {
    addAttachedDocument,
    deleteAttachedDocuments,
    deleteDocuments
} from "../../../../../redux/moderator/edit-course/actions";
import DeleteElementsModal from "../../../configure-course/components/delete-elements-modal";
import PublishedElement from "../PublishedElement/PublishedElement";
import useMoveElementCourse from "../../../../../../utils/moveElementCourse";


const DocumentsCard = (props) => {
    const {isOpen = true, editMode} = props;
    const [description, setDescription] = useState("");
    const [is_open, setIs_open] = useState(isOpen);
    const [showButtons, setShowButtons] = useState(true);
    const [is_open_delete, setIs_open_delete] = useState(false);
    const dispatch = useDispatch();
    const [edit_title, setEditTitle] = useState(false);
    const _title = useRef()
    const [moveElement , indexItem , length] = useMoveElementCourse(props)

    const toggleCollapse = () => {
        setIs_open(!is_open);

    };

    useEffect(() => {
        if (_title.current) {
            _title.current.value = props.element?.title;
        }
    }, [_title, edit_title]);


    const onSave = (element) => {
        const {addCourseDocumentsElement} = props;
        const {current_course} = props.moderator_configure_course;

        // Dispatch action to save documents element
        addCourseDocumentsElement(current_course.id, element, current_course.elements.findIndex((e) => e._id === element._id), () => {
            // this.setState({
            //     showButtons: false
            // })
            setShowButtons(false);
        });
    }

    const deleteEvaluationButton = () => {
        const {element} = props;
        setIs_open_delete(false)
        props.deleteDocuments(element.id, element._id)
    }


    const {element} = props;


    let class_name = "NE_collapsable_card_with_icon notion_creation_card ";
    class_name += is_open ? "NE_collapsable_card_with_icon_open" : "";

    const onUpdateAttachedDocuments = (file) => {
        dispatch(addAttachedDocument(file, element))
    }

    const onDeleteAttachedDocument = (element, document) => {
        dispatch(deleteAttachedDocuments(document))
    }

    const onEditDocuments = () => {
        const {element} = props

        dispatch(editDocumentsElement(element.id, element.title, "", element.element_order, description, element.published))
    }

    const editTitle = () => {
        if (edit_title) {
            setEditTitle(false);
            const {element} = props;

            const _item = {
                id: element.id,
                title: _title.current.value,
                description: element.description,
                element_order: element.element_order,
                published: element.published,
                type:element.type
            }

            dispatch(editItemCourse(_item, "document"));
            return;
        }
        setEditTitle(true)
    }



    useEffect(() => {
        setDescription(props?.element?.description)
    }, [props?.element?.id]);


    return <>
        <DeleteElementsModal isOpen={is_open_delete}
                             onCancel={() => setIs_open_delete(false)}
                             onDelete={deleteEvaluationButton}/>

        <div className={class_name}>
            <div className="header">

                <div className="title">

                        <span className={"d-flex"} >
                            {<img src={arrowUp} className={`arrow`} alt={""} onClick={() => indexItem !== 0 ?  moveElement("down") : null}/>}
                            {<img src={arrowDown} className={`arrow `} alt={""} onClick={() => length-1 !== indexItem ? moveElement("up") : null}/>}
                        </span>



                    <img src={icon} alt="Container icon"/>
                    {edit_title ?
                        <div className="form-field">
                            <div className="NE_normal_input">
                                <input
                                    ref={_title}
                                    onChange={(e) => {
                                        _title.current.value = e.target.value;
                                    }}
                                />
                            </div>
                        </div>
                        : <h2>{element?.title}</h2>
                    }
                </div>


                {!props.moderator_configure_course.current_course.publish && <div className="delete_element_content">
                    <PublishedElement element={element} type={"document"}/>

                    <span className={`${!edit_title ? 'btn_edit' : 'btn_save'}`} onClick={editTitle}/>
                    {editMode && <>

                        <span className="btn_delete_element" onClick={() => {
                            setIs_open_delete(true)
                        }}>

                           </span>
                    </>
                    }

                    <span className="btn_collapse" onClick={() => {
                        toggleCollapse();
                    }}/>
                </div>}

            </div>

            <div className="body">
                <form action="#" method="post" encType="multipart/form-data">
                    <div className="form-field">
                        <div className="NE_normal_textarea NE_normal_textarea_success NE_normal_textarea_error ">
                                <textarea
                                    className={"textarea_doc"}
                                    maxLength={1000}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Description"
                                />
                        </div>
                        <DocumentsUploader
                            activeDelete={showButtons}
                            items={element?.attached_documents ? element.attached_documents : []}
                            onDocumentsChange={onUpdateAttachedDocuments}
                            onDocumentDelete={(document) => {
                                onDeleteAttachedDocument(element, document);
                            }}
                        />
                    </div>
                    <div className="actions" style={{marginTop: "45px"}}>
                        <div className="action">
                            <button className="NE_button_large NE_button_outline"
                                    type="reset">Cancel
                            </button>
                        </div>
                        <div className="action">
                            <button
                                className="NE_button_large NE_button_dark"
                                type="button"
                                onClick={onEditDocuments}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>

}


const mapStateToProps = state => {
    const {moderator_courses_list, moderator_configure_course} = state;
    return {moderator_courses_list, moderator_configure_course};
};

const mapActionsToProps = {
    addCourseDocumentsElement,
    restElement,
    deleteDocumentsSuccess,
    deleteDocuments,
    editDocumentsElement
};


export default connect(mapStateToProps, mapActionsToProps)(DocumentsCard);
