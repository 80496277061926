import React, { useEffect, useRef, useState } from "react";

import Group_10104 from "./assets/Group 10104.svg";
import Group_10265 from "./assets/Group 10265.svg";
import Path_4424 from "./assets/Path_4424.svg";
import file_document from "./assets/file-document.svg";
import Icon_Chevron_Bottom from "./assets/Icon-Chevron Bottom.svg";
import file_remove from "./assets/file-remove.svg";
import quizImg from "./assets/quiz.svg";
import "./index.css";
import history from "../../../utils/history";
import * as PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { loadCourseContent } from "../../redux/moderator/configure-course/actions-creators";
import { Translation } from "react-i18next";
import useGetGame from "../../../utils/useGetGame";


function EvaluationItem(props) {
  return <div
    class="NE_notion"
    onClick={props.onClick}
    style={
      props.id === props.evl?.id &&
        props.type === props.evl?.type
        ? {
          background: "rgb(238 111 87 / 8%)",
          borderRadius: 6,
          width: 436,
        }
        : {}
    }
  >
    <div class="NE_notion_button">
      <img class="NE_image" src={Group_10104} alt="" />
      <h3 class="NE_text_orange"> {props.evl?.label}</h3>
    </div>
  </div>;
}

EvaluationItem.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.any,
  evl: PropTypes.any,
  type: PropTypes.any
};

function DocumentItem(props) {
  return <div
    className="NE_notion"
    onClick={props.onClick}
    style={
      props.id === props.doc.id &&
        props.type === props.doc.type
        ? {
          background: "var(--primary-color) 0% 0% no-repeat padding-box",
          borderRadius: 6,
          width: 436,
        }
        : {}
    }
  >
    <div className="NE_notion_button">
      <img className="NE_image" src={Path_4424} alt="" />
      <h3
        className="NE_text_grey"
        style={
          props.id === props.doc.id &&
            props.type === props.doc.type
            ? {
              color: "#fff",
            }
            : {}
        }
      >
        {props.doc.label}
      </h3>
    </div>
  </div>;
}

DocumentItem.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.any,
  doc: PropTypes.any,
  type: PropTypes.any
};

function BusinessGameItem(props) {
  const { game } = useGetGame(props.item);

  return <div
    class="NE_notion"
    onClick={props.onClick}
    style={
      props.id === props.game?.id &&
        props.type === props.game?.type
        ? {
          background: "rgb(238 111 87 / 8%)",
          borderRadius: 6,
          width: 436,
        }
        : {}
    }
  >
    <div class="NE_notion_button">
      {game?.go_path}
      <img class="NE_image" src={game?.go_path ?? Group_10265} alt="" />
      <h3 class="NE_text_orange">{game?.game_name} </h3>
    </div>
  </div>;
}

BusinessGameItem.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.any,
  game: PropTypes.any,
  type: PropTypes.any
};
const CourseSidebar = ({
  isModerator,
  imgCourse,
  descriptionCourse,
  sidebarData,
  onClickItem,
  clickedItem,
  progress,
  titleCourse,
  done_elements,
  disccussion,
  trainingSessionID
}) => {

  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [clickedItem_, setClickedItem] = useState([]);
  const { sessions_list } = useSelector(state => state.moderator_training_sessions);
  useEffect(() => {
    if (sidebarData?.elements?.length > 0) {
      console.log(
        `sidebarData?.elements[0]`,
        sidebarData?.elements[0]?.notions[0]
      );

      if (!disccussion) {
        setTimeout(() => {
          onClickItem(sidebarData?.elements[0]?.notions[0]);
        }, 100);
      }
    }

    const { elements = [], evaluation = [], documents = [], businessGame = [] } = sidebarData;

    setList([...elements, ...evaluation, ...documents, ...businessGame].sort((a, b) => a.element_order - b.element_order))

  }, [sidebarData]);

  useEffect(() => {

    if (clickedItem?.type === "discussion") {
      setClickedItem(clickedItem.course)
    } else {
      setClickedItem(clickedItem)
    }

  }, [clickedItem]);



  const calcProgress = () => {
    const progress_t = (progress / done_elements) * 100;

    if (progress_t % 1 !== 0) {
      return progress_t.toFixed(2);
    }

    return progress_t;
  }

  return (
    <Translation>
      {t =>
        <>

          <div class="NE_CourseSideBar" id="NE_CourseSideBar_course" style={{ paddingBottom: 20 }}>
            <div class="NE_CourseCard">
              <img src={imgCourse} alt="" />
            </div>

            <div class="side-bare-content">
              <h3 className={"title"}>{titleCourse}</h3>
              <p>{descriptionCourse}</p>
              <div
                className="NE_container"
              // style={{
              //   marginTop: isModerator ? 60 : 40,
              // }}
              >
                {!isModerator &&
                  <>
                    <div className="NE_progress_bar_text">
                      <p className="left_sideBar">
                        {progress}/{done_elements}
                      </p>
                      <p className="right_sideBar">{calcProgress()}% </p>
                    </div>
                    <div className="NE_progress_bar">
                      <div
                        className="NE_progress_bar_orange"
                        style={{
                          width: `${(progress / done_elements) * 100}%`,
                        }}
                      />
                    </div>
                  </>

                }
              </div>


              {list.map((item, index) => {

                return (
                  {
                    "documents": <DocumentItem
                      onClick={() => {
                        onClickItem(item);
                      }}
                      id={clickedItem_?.id}
                      doc={item}
                      type={clickedItem_?.type} />,

                    "evaluation": <EvaluationItem onClick={() => {
                      onClickItem(item);
                    }}
                      id={clickedItem_?.id}
                      evl={item}
                      type={clickedItem_?.type} />,
                    "business_game": <BusinessGameItem item={item} onClick={() => {
                      onClickItem(item);
                    }}
                      id={clickedItem_?.id}
                      game={item}
                      type={clickedItem_?.type} />,
                    "element": <ElementItem
                      element={item}
                      key={index}
                      index={index}
                      onClickItem={onClickItem}
                      clickedItem={clickedItem_}
                    />

                  }[item.type]
                )
              })}

            </div>
            {isModerator && (
              <div
                className="NE_Down_button"
                onClick={() => {
                  // if (sessions_list.length === 0) {
                  dispatch(loadCourseContent(trainingSessionID))
                  // this.props.loadCourseContent(course_id);
                  history.push(`/moderator/edit_course/${trainingSessionID}`, { course_id: trainingSessionID })
                  // }
                }}>
                <h3>{t("moderator.course-sidebar.edit_course_text")}</h3>
              </div>
            )}
          </div>
        </>
      }
    </Translation>
  );
};

const ElementItem = ({ element, onClickItem, clickedItem, index, open = true }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(
    open ? undefined : 0
  );

  const ref = useRef(null);

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined;
    // @ts-ignore
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height);
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);

  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);

  return (
    <>
      <div className="NE_CourseSideBarButton_ collapsible-header-edonec ">
        <div className="NE_leftElemt tooltip2">
          <img src={file_document} alt="" />
          <h3>{element.label}</h3>
          <span className="tooltiptext2">{element.label}</span>

        </div>
        <div className="NE_rightElemt collapsible-header-edonec_" onClick={handleFilterOpening}>
          <img className={`${isOpen ? 'down' : 'up'}`} src={Icon_Chevron_Bottom} alt="" />
        </div>
      </div>
      <div className={"collapsible-content-edonec"} style={{ height }}>
        <div ref={ref}>
          {element.notions?.map((n) => (
            <div
              className="NE_notion"
              onClick={() => {
                onClickItem(n);
              }}
            >
              <div
                className="NE_notion_button"
                style={
                  clickedItem?.id === n?.id && clickedItem?.type === n?.type
                    ? { background: "#ebf4f8", borderRadius: 6 }
                    : {}
                }
              >
                <img src={file_remove} alt="" />
                <h3
                  className="NE_grey_text"
                  style={
                    clickedItem?.id === n?.id && clickedItem?.type === n?.type
                      ? { color: "var(--primary-color)" }
                      : {}
                  }
                >
                  {n?.label}
                </h3>
              </div>
            </div>
          ))}
          {element.quizzes?.map((q) => (
            <div
              className="NE_notion"
              onClick={() => {
                onClickItem(q);
              }}
            >
              <div
                className="NE_notion_button"
                style={
                  clickedItem?.id === q?.id && clickedItem?.type === q?.type
                    ? { background: "rgb(238 111 87 / 8%)", borderRadius: 6 }
                    : {}
                }
              >
                <img src={quizImg} alt="" />
                <h3 className="NE_text_orange">Quizz</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default CourseSidebar;
