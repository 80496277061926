import React, {Component, useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import "./index.css";
import Header from "../../../components/Header";
import {
    dispatchClickedItem,
    loadCourse,
    loadTrainingSession,
} from "../../../redux/participant/participant-course-item/action-creators";
import EtudiantQuiz from "../../../components/EtudiantQuiz";
import CourseSidebar from "../../../components/CourseSidebar";
import Loader from "../../../components/Loader";
import EtudiantEvaluation from "../../../components/EtudiantEvaluation";
import EtudiantBusinessGame from "../../../components/EtudiantBusinessGame";
import EtudiantDocument from "../../../components/EtudiantDocument";
import {NotionItem} from "../../../components/NotionItem/NotionItem";
import EtudiantDiscussion from "../../../components/EtudiantDiscussion";
import SwitchButton from "../../../components/SwitchButton";

const TRC = ({onClickItem, discussions, clickedItem, active_btn}) => {

    const [state, setState] = useState(1);


    return (<div className="NE_notionItem_header_button">

        {/*<div className={`  ${clickedItem?.type !== "discussion" ? "active_button" : ""} disabled_button`}*/}
        {/*     onClick={() => {*/}
        {/*         setState(1);*/}
        {/*         onClickItem(clickedItem?.course);*/}
        {/*     }}*/}
        {/*>*/}
        {/*    <span>Course 10101</span>*/}
        {/*</div>*/}
        {/*<div className={`${clickedItem?.type === "discussion" ? "active_button" : ""} disabled_button`} onClick={() => {*/}

        {/*    const disc = discussions?.find((d) => d.reference_object === "module" && d.element_id === clickedItem.parentId);*/}
        {/*    onClickItem({*/}
        {/*        type: "discussion",*/}
        {/*        ...disc,*/}
        {/*        course: clickedItem*/}
        {/*    })*/}
        {/*    setState(2);*/}
        {/*}}>*/}
        {/*    <span>Discussion</span>*/}
        {/*</div>*/}
    </div>)
}



const ModeratorCourseItem = (props) => {


    const dispatch = useDispatch();
    useEffect(() => {
        const trainingSessionID = props.match.params.trainingSessionID;

        dispatch(loadCourse(trainingSessionID));
    }, []);

        const {
            is_loading,
            trainingSession,
            courseSidebar,
            clickedItem,
            discussions,
            progress
        } = useSelector(state => state.participant_course_item);
        const trainingSessionID = props.match.params.trainingSessionID;

        return (
            <div className="participant_item_page">
                <Loader isOpen={is_loading}/>

                <div className="page_header">
                    <Header user="participant"/>

                </div>

                <div className="page_header" >

                    <div className="switch_sections_wrapper" >
                        <SwitchButton trainingSessionID={props.match.params.trainingSessionID} isActiveCourse={true} />
                    </div>

                </div>
                <div className="page_body">
                    <div className="content" style={{paddingBottom: 20}}>
                        <CourseSidebar
                            isModerator
                            clickedItem={clickedItem}
                            titleCourse={trainingSession?.course?.title}
                            imgCourse={trainingSession?.course?.image_path}
                            descriptionCourse={trainingSession?.course?.description}
                            progress={trainingSession?.progress}
                            sidebarData={courseSidebar}
                            done_elements={trainingSession?.done_elements}
                            onClickItem={(item) => {
                                dispatch(dispatchClickedItem(item));
                            }}
                            trainingSessionID={trainingSessionID}

                        />

                        {clickedItem?.type === "discussion" && (
                            <EtudiantDiscussion
                                callbackfn={() => <TRC clickedItem={clickedItem}
                                                       discussions={discussions}
                                                       onClickItem={(item) => {
                                                           dispatch(dispatchClickedItem(item));

                                                       }}/>}

                                clickedItem={clickedItem}
                                onClickItem={(item) => {
                                    dispatch(dispatchClickedItem(item));
                                }}
                            />
                        )}

                        {clickedItem?.type === "notion" && (
                            <NotionItem
                                callbackfn={() => <TRC clickedItem={clickedItem}
                                                       discussions={discussions}
                                                       onClickItem={(item) => {
                                                           dispatch(dispatchClickedItem(item));

                                                       }}/>}
                                clickedItem={clickedItem}


                            />
                        )}
                        {clickedItem?.type === "quiz" && (
                            <EtudiantQuiz
                                callbackfn={() => <TRC clickedItem={clickedItem}
                                                       discussions={discussions}
                                                       onClickItem={(item) => {
                                                           dispatch(dispatchClickedItem(item));
                                                       }}/>}
                                clickedItem={clickedItem}

                            />
                        )}
                        {clickedItem?.type === "business_game" && (
                            <EtudiantBusinessGame
                                callbackfn={() => <TRC clickedItem={clickedItem}
                                                       discussions={discussions}
                                                       onClickItem={(item) => {
                                                           dispatch(dispatchClickedItem(item));
                                                       }}/>}
                                clickedItem={clickedItem}


                            />
                        )}
                        {clickedItem?.type === "evaluation" && (
                            <EtudiantEvaluation
                                callbackfn={() => <TRC clickedItem={clickedItem}
                                                       discussions={discussions}
                                                       onClickItem={(item) => {
                                                            dispatch(dispatchClickedItem(item));
                                                       }}/>}
                                clickedItem={clickedItem}

                            />
                        )}
                        {clickedItem?.type === "documents" && (
                            <EtudiantDocument
                                callbackfn={() => <TRC clickedItem={clickedItem}
                                                       discussions={discussions}
                                                       onClickItem={(item) => {
                                                            dispatch(dispatchClickedItem(item));
                                                       }}/>}
                                clickedItem={clickedItem}



                            />
                        )}
                    </div>
                </div>
            </div>
        );


}




export default  ModeratorCourseItem;
