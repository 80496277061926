import { axiosInstance, getSecureAxiosInstance } from "./axios-instance";

export const loadAllClients = () => {
  return getSecureAxiosInstance().get("admin/clients");
};

export const addClient = (
  image,
  full_name,
  type,
  phone,
  email,
  address,
  expiration_date
) => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}admin/add-client`,
    {
      name: full_name,
      phone: phone,
      email: email,
      address: address,
      description: type,
      logo_path: image,
      expiry_date: expiration_date
    }
  );
};

export const editClient = (
  id,
  image,
  full_name,
  type,
  phone,
  email,
  address,
  expiration_date
) => {
  return getSecureAxiosInstance().post(
    `${process.env.REACT_APP_BACKEND_URL}admin/edit-client`,
    {
      id: id,
      name: full_name,
      phone: phone,
      email: email,
      address: address,
      description: type,
      logo_path: image,
      expiry_date: expiration_date
    }
  );
};

export const loadClientDetails = client_id => {
  return getSecureAxiosInstance().get(
    `admin/client-details?client_id=${client_id}`
  );
};

export const loadAvailableGames = () => {
  return getSecureAxiosInstance().get(`admin/games`);
};

export const loadAvailableModerators = () => {
  return getSecureAxiosInstance().get(`admin/users`);
};

export const affectGameToClient = affectations => {
  return getSecureAxiosInstance().post(`admin/affect-games`, affectations);
};

export const affectModeratorsToClient = affectations => {
  return getSecureAxiosInstance().post(`admin/add-moderators`, affectations);
};

export const removeModeratorFromClient = (client_id, moderator_id) => {
  return getSecureAxiosInstance().post(
    `admin/cancel-moderator?client_id=${client_id}&moderator_id=${moderator_id}`
  );
};
