import React,{Component} from "react";
import {Translation} from "react-i18next";
import './index.css';
import ToggleButton from "../ToggleButton";

class StudentProgressOverview extends Component{

    render() {
        const {element,onActivationChange} = this.props;

        return <Translation>
            {t => {
                return (
                    <div className="NE_student_progress_overview">
                        {element && <>
                            <div className="header">
                                <div className="student_infos_wrapper">
                                    <img src={element?.image_path ? element.image_path : `https://avatars.dicebear.com/api/initials/${element?.full_name}.svg?radius=50&size=42`} alt="Student" />
                                    <div className="student_infos">
                                        <p className="name">{element?.full_name}</p>
                                        <p className="email"><span className="info_title">{t("moderator.training-sessions.statics.session-dashboards.dashboard.email_address_title")}:</span> {element?.email}</p>
                                    </div>
                                </div>
                                <div className="action">
                                    <ToggleButton checked={element?.is_active} onChange={(e) => {onActivationChange(e.target.checked)}} />
                                    <p>{element?.is_active ? t("moderator.training-sessions.statics.session-dashboards.dashboard.active_state_title") : t("moderator.training-sessions.statics.session-dashboards.dashboard.inactive_state_title")}</p>
                                </div>
                            </div>
                            <div className="body">
                                {element?.progresses.map(progress => {
                                    const progress_percentage = (progress.type.toLowerCase() === "quiz" || progress.type.toLowerCase() === "evaluation") ? progress.numerator/progress.denominator * 100 : progress.element_status * 100
                                    return (
                                        <div key={progress.id} className="notion_progress">
                                            <p className="notion_title">{progress.title} </p>
                                            <div className="progress_infos">
                                                <p>{t("moderator.training-sessions.statics.session-dashboards.dashboard.score_title")} {(progress.type.toLowerCase() === "quiz" || progress.type.toLowerCase() === "evaluation") ? `${progress.numerator}/${progress.denominator}` : ""} ({progress_percentage.toFixed(2)}%)</p>
                                                <div className="progress_bar">
                                                    <div className="progress_level" style={{width: `${progress_percentage}%`}} />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </>}
                    </div>
                );
            }}
        </Translation>
    }
}
export default StudentProgressOverview;
