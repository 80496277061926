import React, {Component} from "react";
import {connect} from "react-redux";
import {Translation} from "react-i18next";
import './index.css';
import Header from "../../../components/Header";
import AddClientCard from "../../../components/AddClientCard";
import ClientInfoCard from "../../../components/ClientInfoCard";

import {
    addClient,
    changeCurrentClientPropValue,
    changeCurrentClientValidationPropValue,
    changeCurrentClientValue,
    clearCurrentClient,
    closeAlert,
    editClient,
    loadAllClients
} from "../../../redux/admin/clients-list/action-creators";
import ClientModal from "./components/add-client-modal";

import icon_edit from './assets/icon-pencil.svg';
import {
    address_schema,
    date_schema,
    email_schema,
    full_name_schema,
    image_size_schema,
    phone_schema,
    type_schema
} from "../../../validation/validations";
import history from "../../../../utils/history";
import moment from "moment";

class ClientsList extends Component {

    INITIAL_STATE = {
        is_create_user_modal_open: false,
        current_user_modal_action: "CREATE",
        current_client: null,
        search_string: "",
        currentPage: 1,
        todosPerPage: 9
    }

    state = {...this.INITIAL_STATE}

    componentDidMount() {
        // Dispatch load clients action
        this.props.loadAllClients();
    }

    clearLocalState = () => {
        this.setState({...this.INITIAL_STATE});
    }

    handleClientCardClick = (client) => {
        // Redirect to client details page
        history.push("/admin/client_details", client);
    }

    handleAddClientClick = () => {
        this.setState({
            is_create_user_modal_open: true,
            current_user_modal_action: "CREATE",
        });
    }

    handleAddClientModalCancel = () => {
        this.setState({
            is_create_user_modal_open: false,
        });

        this.props.clearCurrentClient();
    }

    handleAddClientSubmit = () => {
        const {addClient} = this.props;
        const {current_client} = this.props.admin_clients_list;

        // Dispatch action to add client
        addClient(current_client);

        this.handleAddClientModalCancel();
    }

    handleEditClientSubmit = () => {
        const {editClient} = this.props;
        const {current_client} = this.props.admin_clients_list;

        // Dispatch action to edit client
        editClient({...current_client, expiry_date: moment(current_client.expiry_date).format("YYYY-MM-DD") });

        this.handleAddClientModalCancel();
    }

    handleEditClient = (clientData) => {
        // Dispatch event to change currentClient value
        this.props.changeCurrentClientValue(clientData);

        this.validateCurrentClientProp('image', clientData.image);
        this.validateCurrentClientProp('full_name', clientData.full_name);
        this.validateCurrentClientProp('type', clientData.type);
        this.validateCurrentClientProp('phone', clientData.phone);
        this.validateCurrentClientProp('email', clientData.email);
        this.validateCurrentClientProp('address', clientData.address);
        this.validateCurrentClientProp('expiry_date', clientData.expiry_date);

        this.setState({
            ...this.state,
            current_user_modal_action: "EDIT",
            is_create_user_modal_open: true
        });
    }

    validateCurrentClientProp = (propName, propValue) => {
        const {changeCurrentClientValidationPropValue} = this.props;

        // Validate field
        switch (propName) {
            case 'image':
                const image_error = propValue ? "" : "Avatar is required";
                const {error: image_size_error} = image_size_schema.validate((propValue && propValue.size) ?? 0);
                changeCurrentClientValidationPropValue(propName, {
                    is_valid: !image_size_error && image_error === "",
                    errors: [
                        image_error,
                        image_size_error ? image_size_error.message : ""
                    ]
                });
                break;

            case 'full_name':
                const {error: full_name_error} = full_name_schema.validate(propValue);
                changeCurrentClientValidationPropValue(propName, {
                    is_valid: !full_name_error,
                    error: full_name_error ? full_name_error.message : ""
                });
                break;

            case 'type':
                const {error: type_error} = type_schema.validate(propValue);
                changeCurrentClientValidationPropValue(propName, {
                    is_valid: !type_error,
                    error: type_error ? type_error.message : ""
                });
                break;

            case 'phone':
                const {error: phone_error} = phone_schema.validate(propValue);
                changeCurrentClientValidationPropValue(propName, {
                    is_valid: !phone_error,
                    error: phone_error ? phone_error.message : ""
                });
                break;

            case 'email':
                const {error: email_error} = email_schema.validate(propValue);
                changeCurrentClientValidationPropValue(propName, {
                    is_valid: !email_error,
                    error: email_error ? email_error.message : ""
                });
                break;

            case 'address':
                const {error: address_error} = address_schema.validate(propValue);
                changeCurrentClientValidationPropValue(propName, {
                    is_valid: !address_error,
                    error: address_error ? address_error.message : ""
                });
                break;

            case 'expiry_date':
                const {error: expiration_date_error} = date_schema.validate(propValue);
                changeCurrentClientValidationPropValue(propName, {
                    is_valid: !expiration_date_error,
                    error: expiration_date_error ? expiration_date_error.message : ""
                });
                break;

            default:
                break
        }
    };

    isFormValid = () => {
        const {
            image,
            full_name,
            type,
            phone,
            email,
            address,
            expiry_date
        } = this.props.admin_clients_list.current_client_validation;

        return image.is_valid && full_name.is_valid && type.is_valid && phone.is_valid && email.is_valid && address.is_valid && expiry_date.is_valid;
    };

    handleCurrentClientPropChange = (propName, propValue) => {
        const {changeCurrentClientPropValue} = this.props;

        // Dispatch action to change current client prop value
        changeCurrentClientPropValue(propName, propValue);

        this.validateCurrentClientProp(propName, propValue);
    };

    handleCloseAlert = (alert_id) => {
        // Dispatch action to close the targeted alert
        this.props.closeAlert(alert_id);
    }

    handleHeaderSearchChange = (search_string) => {
        this.setState({
            ...this.state,
            search_string: search_string,   currentPage: 1
        });
    }

    handleClick = (number) => {
        this.setState({
            currentPage: Number(number)
        });
    }

    render() {
        // Getting state variables
        const {
            clients_list,
            current_client,
            current_client_validation,
            alerts,
            is_loading
        } = this.props.admin_clients_list;

        const {is_create_user_modal_open, current_user_modal_action, search_string} = this.state;

        // Filter client list based on search string
        const filtered_clients_list = clients_list.filter(client => client.full_name.toLowerCase().includes(search_string.toLowerCase()));


        return <Translation>
            {t => {
                // Prepare header global search data
                const header_search_data = {
                    placeholder: t("admin.clients-list.statics.header.searchbar_placeholder"),
                    search_string: search_string,
                    onChange: this.handleHeaderSearchChange
                }

                const {currentPage, todosPerPage} = this.state;

                const indexOfLastTodo = currentPage * todosPerPage;
                const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
                const currentTodos = filtered_clients_list.slice(indexOfFirstTodo, indexOfLastTodo);

                const renderTodos = currentTodos.map((client, index) => {
                    const client_card_options = [
                        {
                            name: t("admin.clients-list.statics.client-card.edit_option"),
                            icon: icon_edit,
                            data: client,
                            click_handler: this.handleEditClient,
                        }
                    ];

                    return <ClientInfoCard key={client.id} image={client.image} name={client.full_name}
                                           description={client.type} phone={client.phone} address={client.address}
                                           options={client_card_options} onClick={() => {
                        this.handleClientCardClick(client)
                    }}/>
                });

                // Logic for displaying page numbers
                const pageNumbers = [];
                for (let i = 1; i <= Math.ceil(filtered_clients_list.length / todosPerPage); i++) {
                    pageNumbers.push(i);
                }

                const renderPageNumbers = pageNumbers.map(number => {
                    return (
                        <div
                            key={number}
                            id={number}
                            onClick={() => {
                                this.handleClick(number)
                            }}
                            style={number !== this.state.currentPage ? {
                                border: "1px solid var(--secondary-color)",
                                borderRadius: 5,
                                cursor: "pointer",
                                height: 30,
                                width: 30,
                                margin: 4,
                                display: "flex"

                            }: {
                                border: "1px solid var(--primary-color)",
                                borderRadius: 5,
                                cursor: "pointer",
                                height: 30,
                                width: 30,
                                margin: 4,
                                display: "flex",
                                backgroundColor: "var(--primary-color)",
                                color: "#fff"
                            }}>

                            <span style={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                flex: 1,
                            }}>{number}</span>
                        </div>
                    );
                });

                return (
                    <div className="clients_list_page">
                        <div className="page_header">
                            <Header searchData={header_search_data} isLoading={is_loading} alerts={alerts}
                                    onCloseAlert={this.handleCloseAlert}/>
                        </div>

                        <div className="page_body">
                            <div className="content">
                                <AddClientCard onClick={this.handleAddClientClick}/>


                                {renderTodos}

                            </div>
                        </div>
                        <div>

                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flex: 1,
                                marginTop:"10px",
                                height: 60,

                            }}>
                                {renderPageNumbers}
                            </div>
                        </div>

                        <ClientModal isOpen={is_create_user_modal_open} currentAction={current_user_modal_action}
                                     currentClient={current_client} currentClientValidation={current_client_validation}
                                     isFormValid={this.isFormValid()}
                                     onChange={this.handleCurrentClientPropChange}
                                     onSubmit={this.state.current_user_modal_action === "CREATE" ? this.handleAddClientSubmit : this.handleEditClientSubmit}
                                     onCancel={this.handleAddClientModalCancel}/>
                    </div>
                );
            }}
        </Translation>
    }
}

const mapStateToProps = state => {
    const {admin_clients_list} = state;

    return {admin_clients_list};
};

const mapActionsToProps = {
    loadAllClients,
    addClient,
    changeCurrentClientPropValue,
    changeCurrentClientValue,
    changeCurrentClientValidationPropValue,
    clearCurrentClient,
    editClient,
    closeAlert
};

export default connect(mapStateToProps, mapActionsToProps)(ClientsList);
