import React, { Component, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./index.css";

const WonModal = ({ show, close = () => null, onClickOK = () => null }) => {
  let class_name = "modal_wrapper NE_won-modal-container NE_won-modal-container_points ";
  class_name += show ? "modal_open" : "";

  return (
    <div
      className={class_name}
      onClick={(e) => {
        if (e.target.className === class_name) {
          // this.clearLocalState();
          close();
        }
      }}
      style={{
        zIndex: 3,
      }}
    >
      <div className="modal_body_won">
        <div className="NE_reward_modal">
          <h1>Cours importé avec succès 💪</h1>
          <p> Bonne continuation 👏</p>
          <button onClick={onClickOK}>Ok</button>
        </div>
      </div>
    </div>
  );
};

export default WonModal;
