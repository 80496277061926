import React, { useEffect, useState } from "react";
import "./index.css";
import { useTranslation } from 'react-i18next';

const Question = ({
  question,
  index,
  isSubmit,
  onSubmit,
  responseQuestion
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [correctResponse, setCorrectResponse] = useState(false);
  const [responseStatus, setResponseStatus] = useState(false);
  const [selectedValue, setSelectedValue] = useState(false);
  const [showJustif, setShowJustif] = useState(false);

  const {t} = useTranslation()


    useEffect(() => {
       if ( responseQuestion !== undefined){
           setShowJustif(true)
       }
    }, [responseQuestion]);


  useEffect(() => {
    setCorrectResponse(question?.options?.find(o => o.is_correct === 1));
    setIsSubmitted(false);
    setResponseStatus(false);
  }, [question]);

  useEffect(() => {
    if (responseQuestion) {
      setIsSubmitted(true);
      setSelectedValue(responseQuestion.question_option_id);
      if (responseQuestion.question_option_id === correctResponse?.id) {
        setResponseStatus(true);
      } else setResponseStatus(false);
    }
  }, [responseQuestion]);

  return (
    <div class="NE__QUIZ NE__QUIZ_course">
      <div class="NE_body_content">
        <h6>Question {index + 1} </h6>
        <div class="NE_body_paragraph_">
          <p>{question?.title}</p>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        {question?.options?.map(o => (
          <Option
            {...{
              index,
              o,
              isSubmitted,
              responseStatus,
              isSubmit,
              selectedValue,
              setSelectedValue
            }}
          />
        ))}
      </div>


        {showJustif  &&
            <div className={"_justification"}>
                <p>{question?.justification}</p>
            </div>
        }

      {isSubmit   && (
        <div
          className="active_button"
          style={{ alignSelf: "center", marginBottom: 20 }}

          onClick={() => {
              if (!selectedValue) return;

            setIsSubmitted(true);
            setShowJustif(true);
            onSubmit(selectedValue);

            if (selectedValue === correctResponse?.id) {
              setResponseStatus(true);
            } else setResponseStatus(false);
          }}
        >
          <span> {t("btn_submit")}</span>
        </div>
      )}
    </div>
  );
};

const Option = ({
  index,
  o,
  isSubmitted,
  responseStatus,
  isSubmit,
  selectedValue,
  setSelectedValue
}) => {
  return (
    <div
      class="NE_radio_button"
      style={{
        padding: 5,
        margin: 10,
        width: "auto",
        borderRadius: 8,
        backgroundColor:
          isSubmitted &&
          selectedValue === o?.id &&
          (responseStatus ? "rgb(20 163 139 / 16%)" : "rgb(255 113 113 / 16%)"),
        flex: 1,
        flexDirection: "row",
        display: "flex"
      }}
    >
      <input
        type="radio"
        name={"r1" + index}
        id={"r1" + o?.id}
        checked={selectedValue === o?.id}
        onClick={() => {
          setSelectedValue(o?.id);
        }}
        disabled={!isSubmit}
        value={o?.id}
      />
      <label for={"r1" + o?.id} style={{ flex: 1 }}>
        {o?.content}
      </label>
      {isSubmitted && selectedValue === o?.id && (
        <span
          style={{
            alignSelf: "center",
            justifySelf: "flex-end",
            marginRight: 20,
            color: isSubmitted && (responseStatus ? "#14A38B" : "#FF7171")
          }}
        >
          {responseStatus ? "+10 XP" : "+0 XP"}
        </span>
      )}
    </div>
  );
};

export default Question;
