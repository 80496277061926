import React, { Component } from "react";
import { Translation } from "react-i18next";
import './index.css'
import logo from '../images/logo.png';
import NormalInput from "../../components/NormalInput";
import NormalButton from "../../components/NormalButton";
import Checkbox from "../../components/Checkbox";
import { Link } from 'react-router-dom';
import {
    changeRegisterValidationValues,
    changeRegisterValues,
    registerCloseAlert,
    registerUser
} from "../../redux/register/action-creators";
import { connect } from "react-redux";
import { email_schema, full_name_schema, password_schema } from "../../validation/validations";
import Loader from "../../components/Loader";
import Terms from "./terms";
import Alert from "../../components/Alert";
import background from "../images/background1.jpeg";
import background2 from "../images/background2.jpeg";
import background3 from "../images/background3.jpeg";
import background4 from "../images/background4.jpeg";
import background5 from "../images/background5.jpeg";
import background6 from "../images/background6.jpeg";
import background7 from "../images/background7.png";

const imagesBackground = [background, background2, background3, background4, background5, background6, background7]

class Signup extends Component {
    constructor(props) {
        super(props);
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.state = {
            terms_modal: false, checked: false
        }
        this.image = imagesBackground[Math.floor(Math.random() * imagesBackground.length)];

    }

    handleValidSubmit() {
        this.props.registerUser({
            'full_name': this.props.register.full_name,
            'email': this.props.register.email,
            'password': this.props.register.password,
            'repeat_password': this.props.register.repeat_password
        });
    };

    validateRegisterProp = (propName, propValue) => {
        const { changeRegisterValidationValues } = this.props;
        const { password } = this.props.register;

        switch (propName) {
            case 'full_name':
                const { error: full_name_error } = full_name_schema.validate(propValue);
                changeRegisterValidationValues(propName, {
                    is_valid: !full_name_error, error: full_name_error ? full_name_error.message : ""
                });
                break;

            case 'email':
                const { error: email_error } = email_schema.validate(propValue);
                changeRegisterValidationValues(propName, {
                    is_valid: !email_error, error: email_error ? email_error.message : ""
                });
                break;

            case 'password':
                const { error: password_error } = password_schema.validate(propValue);
                changeRegisterValidationValues(propName, {
                    is_valid: !password_error, error: password_error ? password_error.message : ""
                });
                break;

            case 'repeat_password':
                let { error: repeat_password_error } = password_schema.validate(propValue);

                if (!repeat_password_error) {
                    if (propValue !== password) {
                        repeat_password_error = {
                            message: "password and confirmation don't match !"
                        }
                    }
                }
                changeRegisterValidationValues(propName, {
                    is_valid: !repeat_password_error, error: repeat_password_error ? repeat_password_error.message : ""
                });
                break;

            // case 'terms':
            //     const {error: terms} = password_schema.validate(propValue);
            //     changeRegisterValidationValues(propName,{
            //         is_valid: !password_error,
            //         error: password_error ? password_error.message : ""
            //     });
            //     break;

            default:
                break;
        }
    }

    handleChangeCheckBox = () => {
        const { checked } = this.state
        this.setState({
            checked: !checked
        })
    }

    handleRegisterPropChange = (propName, propValue) => {
        const { changeRegisterValues } = this.props;

        // Dispatch action to change register prop value
        changeRegisterValues(propName, propValue);

        this.validateRegisterProp(propName, propValue);
    };

    isFormValid = () => {
        const register_validation = this.props.register.register_validation;
        const { checked } = this.state;

        return register_validation?.full_name?.is_valid && register_validation?.email?.is_valid && register_validation?.password?.is_valid && register_validation?.repeat_password?.is_valid && checked;
    };


    handleCloseAlert = (alert_id) => {
        // Dispatch action to close the targeted alert
        this.props.closeAlert(alert_id);
    }

    handleShowTerms = () => {
        const terms = this.state.terms_modal
        this.setState({
            terms_modal: !terms
        })
    }

    componentDidMount() {

        this.setState(prevState => {
            return {
                image: imagesBackground[Math.floor(Math.random() * imagesBackground.length)]
            };
        });
        const intervalId = setInterval(() => {
            this.setState(prevState => {
                return {
                    image: imagesBackground[Math.floor(Math.random() * imagesBackground.length)]
                };
            });

        }, 20000);

        this.setState({ intervalId: intervalId });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    render() {

        //get Variable from props
        const {
            full_name, email, password, repeat_password, is_loading, register_validation, alerts
        } = this.props.register;

        return <Translation>
            {t => {
                return (<>
                    <Loader isOpen={is_loading} />
                    <div className="signup_split signup_left">
                        <div className="alerts_container">
                            {alerts?.map(alert => {
                                return <Alert key={alert.id} type={alert.type} destination={'login'}
                                    message={alert.message} onClose={() => {
                                        this.props.registerCloseAlert(alert.id);
                                    }} />
                            })}
                        </div>
                        <div className="signup_centered">
                            <div className="signup_NE_contain">
                                <img className="signup_NE_header_logo" src={logo} alt="Logo" />
                                <div>
                                    <h3 className="signup_NE_header_">{t("sign-up.title")}</h3>
                                    <p className="signup_NE_paragraph">{t("sign-up.sign_in_cta")}</p>
                                    <p className="signup_NE_paragraph_underlined">
                                        <Link to="/">{t("sign-up.sign_in_link")}</Link>
                                    </p>
                                    <div className="signup_NE_label">
                                        <NormalInput value={full_name}
                                            isValid={register_validation.full_name.is_valid}
                                            message={register_validation.full_name.error}
                                            label={t("sign-up.fields.full_name_label")}
                                            placeholder={t("sign-up.fields.full_name_placeholder")}
                                            name='full_name' onChange={(event) => {
                                                this.handleRegisterPropChange("full_name", event.target.value)
                                            }} />
                                    </div>
                                    <div className="signup_NE_label">
                                        <NormalInput isValid={register_validation.email.is_valid}
                                            message={register_validation.email.error}
                                            label={t("sign-up.fields.email_label")}
                                            placeholder={t("sign-up.fields.email_placeholder")}
                                            name='email' value={email} onChange={(event) => {
                                                this.handleRegisterPropChange("email", event.target.value)
                                            }} />
                                    </div>
                                    <div className="signup_NE_label">
                                        <NormalInput value={password}
                                            isValid={register_validation.password.is_valid}
                                            message={register_validation.password.error}
                                            label={t("sign-up.fields.password_label")}
                                            placeholder={t("sign-up.fields.password_placeholder")}
                                            name='password' type='password' onChange={(event) => {
                                                this.handleRegisterPropChange("password", event.target.value)
                                            }} />
                                    </div>
                                    <div className="signup_NE_label">
                                        <NormalInput value={repeat_password}
                                            isValid={register_validation.repeat_password.is_valid}
                                            message={register_validation.repeat_password.error}
                                            label={t("sign-up.fields.repeat_password_label")}
                                            placeholder={t("sign-up.fields.repeat_password_placeholder")}
                                            name='repeat_password' type='password' onChange={(event) => {
                                                this.handleRegisterPropChange("repeat_password", event.target.value)
                                            }} />
                                    </div>
                                    <div className="signup_NE_label">
                                        <div className="signup_NE_terms">
                                            <Checkbox id="r1" checked={this.state.checked} onChange={() => {
                                                this.handleChangeCheckBox()
                                            }} />
                                            <p>
                                                <Link onClick={this.handleShowTerms}
                                                    dangerouslySetInnerHTML={{ __html: t("sign-up.sign_up_terms_label", { 'interpolation': { 'escapeValue': false } }) }}
                                                    style={{ textDecoration: 'none' }}></Link>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="signup_NE_label_signup">
                                        <NormalButton button='dark' disabled={!this.isFormValid()}
                                            value={t("sign-up.sign_up_button")}
                                            onClick={this.handleValidSubmit} />
                                    </div>
                                    <div className="signup_NE_Social">
                                        {/* <SocialButton type='facebook'
                                                          value={t("sign-up.sign_up_with_facebook_button")}
                                                          onClick={() => window.location.replace(urlFacebook)}
                                            /> */}
                                        {/* <SocialButton type='google'
                                                          value={t("sign-up.sign_up_with_google_button")}
                                                          onClick={() => window.location.replace(urlGoogle)}
                                            /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="login_split login_right" style={{
                        backgroundImage: `url(${this?.state?.image})`
                    }}>
                        <div className="signup_centered">

                        </div>
                    </div>
                    <Terms isOpen={this.state.terms_modal} onCancel={this.handleShowTerms} />
                </>);
            }}
        </Translation>
    }
}

const mapStateToProps = (state) => {
    const { register } = state;
    return { register };
};

const mapActionsToProps = {
    registerUser, changeRegisterValues, changeRegisterValidationValues, registerCloseAlert
};

export default connect(mapStateToProps, mapActionsToProps)(Signup);
