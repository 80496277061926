import LargeButton from "../../../../../components/LargeButton";
import React from "react";
import * as PropTypes from "prop-types";
import '../../index.css'
import style from "./style.module.scss"
import {useDispatch} from "react-redux";
import { useForm } from "react-hook-form";
import {pushElement} from "../../../../../redux/moderator/configure-course/actions-creators";
import {uid} from "uid";

function Create_element({onCancel = ()=>null , onSave = ()=>null}) {

    const dispatch =  useDispatch();

    const { register, handleSubmit, watch, formState: { errors }  , reset} = useForm();

    const onSubmit = data => {
        onSave(data);
       dispatch(pushElement({_id:uid(),...data,description:""}));

        reset({
            title:"",
            description:""
        })
    };

    return <div className={`create_element_form_wrapper ${style.create_element_form_wrapper}`}>
        <form className={style.wrapper} >
                   <div className="form-field">

                       <div className={"NE_normal_input"}>
                           <label htmlFor={"1"}>Titre</label>
                           <input id={"1"} {...register("title" , { required: "le titre est obligatoire " })}/>
                           {/*{(isValid !== undefined) && <p>{message}</p>}*/}
                           {errors.title && <span className="error_msg" >{errors.title.message}</span>}

                       </div>
                    </div>
                    {/*<div className="form-field">*/}

                    {/*    <div className="NE_normal_textarea NE_normal_textarea_success NE_normal_textarea_error">*/}
                    {/*        <label htmlFor={"2!"}>Description</label>*/}
                    {/*        <textarea id={"2!"} placeholder={"Description about the course"}  {...register("description")} maxLength="1000"/>*/}
                    {/*        /!*{(isValid !== undefined) && <p>{message}</p>}*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className={` ${style.form_actions}`} >
                        <div className="form-action">
                            <LargeButton value={"Annuler"}  onClick={onCancel}/>
                        </div>
                        <div className="form-action">
                            <LargeButton  button={"dark"} value={"Créer un élément"} onClick={handleSubmit(onSubmit)}/>
                        </div>
                    </div>
        </form>
    </div>;
}


Create_element.propTypes = {
    currentElement: PropTypes.any,
    onChange: PropTypes.func,
    onChange1: PropTypes.func,
    onClick: PropTypes.func
};

export default Create_element;
