import React, {createContext, useContext, useEffect, useState} from "react";
import iconQuestion from "../ModuleCard/assets/icon-question.svg";
import {uid} from "uid";
import style from "./style.module.scss"
import {ModuleItemContext} from "../ModuleCard";
import {useDispatch} from "react-redux";
import {addQuiz, deleteNotion, deleteQuiz} from "../../../../../redux/moderator/configure-course/actions-creators";
import RadioButton2 from "../../../../../components/RadioButton2";
import DeleteElementsModal from "../delete-elements-modal";
import Textarea from "../../../../../components/Textarea";
import Loader from "../../../../../components/Loader";

const ItemContext = createContext({
    items: [],
    _id: null,
    question: "",
    justification: "",
    addItem: () => {
    },
    deleteItem: () => {
    },
    editItem: () => {
    },
    editQuestion: () => {
    },
    changeQuestionOption: () => {
    }
});

const initialState = [
    // {
    //     text: "option",
    //     question_option:true,
    //     name_group:1,
    //     _id: uid()
    // }
];

const ItemProvider = (props) => {

    const [items, setItems] = useState(initialState);
    const [question, setQuestion] = useState("");
    const [justification, setJustification] = useState("");


    const addItem = (item) => setItems((items) => [...items, item]);
    const deleteItem = (id) =>
        setItems((items) => items.filter((item) => item._id !== id));

    const editItem = (id, text) => {
        const list = items.map((elem) => {
            if (elem._id === id) return {...elem, text: text};
            return elem;
        });
        setItems(list);
    };

    const editQuestion = (text) => {
        setQuestion(text);
    };

    const editJustification = (text) => {
        setJustification(text);
    };
    const changeQuestionOption = (id) => {
        const list = items.map((elem) => {
            if (elem._id === id) return {...elem, question_option: true};
            return {...elem, question_option: false};
        });

        setItems(list);
    };

    useEffect(() => {

        const list = props.ListQuiz.map((elem, index) => {
            if (elem._id === props.quiz._id) return {...elem, items, question,justification}
            return elem;
        });

        props.setList(list);


    }, [items, question,justification]);


    const value = {
        items,
        question,
        justification,
        addItem,
        deleteItem,
        editItem,
        editQuestion,
        editJustification,
        changeQuestionOption
    };

    return (
        <ItemContext.Provider value={value}>{props.children}</ItemContext.Provider>
    );
};


function ItemQuestionComponent({elem ,is_save}) {

    const [isEdit, setIsEdit] = useState(false);
    const {deleteItem, editItem, changeQuestionOption} = useContext(ItemContext);


    return <li className={`question_option  ${style.question_option} ${elem.question_option ? 'question_option_selected' : ''} `}>
                <div className={`NE_radio_button ${style.NE_radio_button}`}>
                    <input type="radio" name={elem.name_group} defaultChecked={elem.question_option} id={elem._id}
                           onClick={() => changeQuestionOption(elem._id)}/>
                    {isEdit ?
                        <label htmlFor={elem._id}>
                            <div className={style.block_}>
                                <input className={style.input} onChange={(e) => editItem(elem._id, e.target.value)}
                                       value={elem.text}/>
                            </div>
                        </label>
                        :
                        <label htmlFor={elem._id}>
                            {elem.text}
                        </label>
                    }
                </div>
        {!is_save && <>
        <span className={`question_option_action ${isEdit ? style.btn_save : style.btn_edit}`}
              onClick={() => setIsEdit(!isEdit)}/>
            <span className={`${style.question_option_action_trash}`} onClick={() => deleteItem(elem._id)}/>
        </>}
               { elem.question_option &&
                   <span className={`${style.question_option_action_valid}`}/>
               }
                {/*<span className="question_option_action" onClick={onDelete(elem._id)} />*/}
            </li>;
}

function ListQuestionComponent({name_group,is_save}) {
    const [list, setList] = useState([]);
    const {items, addItem} = useContext(ItemContext);

    useEffect(() => {
        if (items.length === 0){
            addItem({
                text: "option",
                question_option: false,
                name_group: name_group,
                _id: uid()
            });
            addItem({
                text: "option",
                question_option: false,
                name_group: name_group,
                _id: uid()
            });
            addItem({
                text: "option",
                question_option: false,
                name_group: name_group,
                _id: uid()
            });
            addItem({
                text: "option",
                question_option: false,
                name_group: name_group,
                _id: uid()
            });
        }
    }, []);

    const addElement = () => {
        addItem({
            text: "option",
            question_option: false,
            name_group: name_group,
            _id: uid()
        });
    }
    const onDelete = (id) => (e) => {
        setList(list.filter(elm => elm._id !== id))
    }
    return <ul className="question_options">
        {items.map((elem, index) => <ItemQuestionComponent is_save={is_save} elem={elem} key={elem.id} onDelete={onDelete}/>)}

        {!is_save && <li className="add_question_option" onClick={addElement}>
            Add Option
        </li>}
    </ul>;
}

function QuizFormComponent({element , deleteElement , is_save}) {
    const {editQuestion, question ,justification,editJustification} = useContext(ItemContext);

    return <div className={`form-field ${style.form_field}`}>
                <div className="question">
                    <div className="NE_normal_input">
                       <div  className={`${style.block_elemnt}`}>
                        <label htmlFor="question1">Question</label>
                           {!is_save && <span className={`${style.question_option_action_trash}`}
                                  onClick={() => deleteElement(element._id)}/>
                           }                    </div>
                        <input type="text"
                               id="question1"
                               value={question}
                               onChange={(e) => editQuestion(e.target.value)}
                               placeholder="Write your question"/>
                        <p>This is a message</p>
                    </div>
                    <ListQuestionComponent is_save={is_save} name_group={element._id}/>
                    <div className="NE_normal_input">
                        <Textarea
                               value={justification}
                               onChange={(e) => editJustification(e.target.value)}
                               placeholder="Justificatif de la réponse"/>
                        <p>This is a message</p>
                    </div>
                </div>
            </div>
}

function QuizFormProvider({element, quiz_id, setList, ListQuiz , deleteElement , is_save}) {

    return <ItemProvider quiz={element} ListQuiz={ListQuiz} quiz_id={quiz_id} setList={setList}>
             <QuizFormComponent is_save={is_save} deleteElement={deleteElement} element={element}/>
           </ItemProvider>
        ;
}

export default function QuizComponent({
                                          quiz_id, module,
                                          index,elem,
                                          current_course
                                      }) {
    const [isCollapse, setIsCollapse] = useState(false);
    const [showButtons, setShowButtons] = useState(true);
    const [List, setList] = useState([]);
    const {editItem, deleteItem} = useContext(ModuleItemContext);
    const dispatch = useDispatch();
    const [is_open_delete, setIsOpenDelete] = useState(false);
    const [is_save, setIsSave] = useState(false);
    const [loading, setLoading] = useState(false);

    const addElement = () => {
        setList([...List, {_id: uid()}])
    };

    const deleteElement = (id) => {
        setList((prev)=> prev.filter((elm)=>elm._id!== id))
    };


    useEffect(() => {

        editItem({
            _id: quiz_id,
            List
        })
    }, [List]);

    const deleteQuizButton =
        () => {

            const quiz = module?.quizzes?.find((n) => n.element_order === index);
            if (quiz?.id) {
                dispatch(deleteQuiz(module.id, quiz.id, () => {
                    deleteItem(elem._id)
                }))
            } else {
                deleteItem(elem._id)
            }
        }

        const isValid = ()=>{

                if(List.length<=0) return false;

                return List.reduce((previousValue, currentValue) =>
                    {
                        let found = false;
                        if (currentValue?.items?.length > 0){
                            found  = !!currentValue.items.find(element => element.question_option === true);
                        }


                       return  currentValue.question !== "" && currentValue.items?.length > 0 && found && previousValue;
                    }, true);

        }


    return <div className={`body_element_notion ${isCollapse ? "isCollapse" : ""}`}>
        <Loader isOpen={loading} />
        <DeleteElementsModal  isOpen={is_open_delete} onCancel={()=> setIsOpenDelete(false)} onDelete={deleteQuizButton} />

        <div
            className="NE_collapsable_card_with_icon NE_collapsable_card_with_icon_open quiz_creation_card">
            <div className="header">
                <div className="title">
                    <img src={iconQuestion}
                         alt="Container icon"/>
                    <h2>Quiz</h2>
                </div>

                {!current_course.publish && <div className="delete_element_content">
                   <span className="btn_delete_element" onClick={() => {
                       setIsOpenDelete(true)
                       //
                   }}>

                   </span>
                    <span className="btn_collapse" onClick={() => {
                        setIsCollapse(!isCollapse)
                    }}/>
                </div>}
            </div>
            <div className={`body ${style.body}`}>
                <form>
                    {
                        List.map((element, index) => <QuizFormProvider
                                                                        is_save={is_save}
                                                                        deleteElement={deleteElement}
                                                                        ListQuiz={List}
                                                                        setList={setList}
                                                                        quiz_id={quiz_id}
                                                                        element={element}
                                                                        key={index}/>)
                    }
                 {showButtons &&    <div className="add_question" onClick={addElement}/>   }

            {showButtons &&
            <div className="actions" style={{marginTop:"45px"}} >
                <div className="action">
                    <button className="NE_button_large NE_button_outline"
                            type="reset">Cancel
                    </button>
                </div>

                <div className="action">
                    <button disabled={!isValid()} className="NE_button_large NE_button_dark" type="button" onClick={() => {
                       setIsSave(true);
                        setLoading(()=>true)

                        dispatch(addQuiz(
                            List, module, index
                            , () => {
                                setShowButtons(false)
                                setLoading(()=>false)
                            }))
                    }
                    }>Save
                    </button>
                </div>
            </div>
            }
                </form>
            </div>
        </div>
    </div>;
}
