import React, { useEffect, useRef } from "react";
import "./index.css";
import profilePic from "./assets/profile-picture-2.png";
import { useSelector } from "react-redux";
import { useState } from "react"
import nextIcon from "./assets/icon-chevron-right.svg";
import { Translation } from "react-i18next";

const ProfilePoints = () => {
  const { current_user } = useSelector((state) => state.login);
  const { profilePoints } = useSelector((state) => state.participant_points);
  const [hasScrollBar, setHasScrollBar] = useState(false);
  const score = profilePoints?.user_scores?.total_score;
  const slider = useRef();



  const compare = (a, b) => {
    if (a.total_score < b.total_score) {
      return 1;
    }
    if (a.total_score > b.total_score) {
      return -1;
    }
    return 0;
  }

  const sorted_user_community = profilePoints?.user_community?.sort(compare);
  //
  // const goLeftPercent = () => {
  //   return {
  //     transform: `translatex(${left}px)`,
  //     transition: "0.3s"
  //   };
  // };

  const goLeft = () => {
    slider.current.scrollLeft -= 176;

  };
  const goRight = () => {
    // if (left === 0) {
    //   return;
    // }

    // console.log("---->>>--slider.current.hasScrollBar()----",slider.current.scrollWidth,1950);

    slider.current.scrollLeft += 176;
    // const left_  = left - 326;
    // setLeft(left_);
  };


  return (
    <Translation>
      { t =>

        <>

          <div className="user_infos_score" style={{ margin: 16 }}>
            <div className="user_infos" style={{ marginRight: 16 }}>
              <img
                src={current_user?.avatar_path || profilePic}
                alt="user profile"
              />
              <h1>{t("participant.user-metrics.welcome-text")} {current_user?.full_name.toLowerCase()}</h1>
              <p>{t("participant.user-metrics.welcome-msg")}</p>
            </div>

            <div className="user_scores">
              <h1>Score</h1>

              <div className="NE_score_gage">
                <p style={{ width: 15, height: 40 }}>{` `}</p>
                <div className="progress_bar">
                  <div
                    className="progress_level"
                    style={{ width: `${score * 0.33}%` }}>


                  </div>

                  <div className="progress_points_wrapper">
                    <div className="progress_points">

                      <div
                        className={
                          `
                    tooltip   
                    ${score >= 0
                            ? "progress_point progress_point_small"
                            : " progress_point progress_point_small progress_point_disabled"}
                        `}
                        // style={{ display: "none"}}
                        style={{ marginLeft: -20 }}

                        data-text="Level 1"
                      >
                        0
                    <span className={`tooltiptext tooltiptext_normal `}>{t("participant.user-metrics.level1")}</span>

                      </div>
                      <div
                        className={
                          `
                    tooltip   
                    ${score >= 100
                            ? "progress_point progress_point_small"
                            : " progress_point progress_point_small progress_point_disabled"}
                        `}
                        style={{ marginLeft: `${90 * 0.33}%` }}
                        data-text="Level 2"
                      >
                        100
                    <span className={`tooltiptext ${score <= 100 ? "tooltiptext_active" : "tooltiptext_normal"}`}>{t("participant.user-metrics.level2")}</span>
                      </div>

                      <div
                        data-text="Level 3"
                        className={
                          `tooltip  
                      ${score >= 200
                            ? "progress_point"
                            : " progress_point progress_point_disabled"}`
                        }
                        style={{ marginLeft: `${90 * 0.33}%` }}
                      >
                        200
                    <span className={`tooltiptext ${score <= 200 ? "tooltiptext_active" : "tooltiptext_normal"}`}>{t("participant.user-metrics.level3")}</span>


                      </div>

                      <div
                        data-text="Level 4"

                        className={
                          score >= 300
                            ? "progress_point next_level_progress_point tooltip"
                            : "progress_point next_level_progress_point progress_point_disabled tooltip"
                        }
                      >
                        300
                    <span className={`tooltiptext ${score < 300 ? "tooltiptext_active" : "tooltiptext_normal"}`}>{t("participant.user-metrics.level4")}</span>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="user_metrics" style={{ margin: 16 }}>
            <h1>{t("participant.user-metrics.your_metrics_text")}</h1>
            <div className={"user_metrics_contant"} >
              <div className="slider_action action_prev" onClick={goLeft} >
                <img alt={""} src={nextIcon} width={20} height={20} />
              </div>
              <div className="metrics_slider" ref={slider} >
                <div className="metric_card" >
                  <div className="metric_points">
                    <p>{profilePoints?.user_scores?.avatar_xp}</p>
                    <p>xp</p>
                  </div>
                  <p className="description">{t("participant.user-metrics.profile_avatar")}</p>
                  {/* <p className="long_description">avatar XP</p> */}
                </div>
                <div className="metric_card">
                  <div className="metric_points">
                    <p>{profilePoints?.user_scores?.description_xp}</p>
                    <p>xp</p>
                  </div>
                  <p className="description">{t("participant.user-metrics.profile_desc")}</p>
                </div>

                <div className="metric_card">
                  <div className="metric_points">
                    <p>{profilePoints?.user_scores?.join_courses_xp}</p>
                    <p>xp</p>
                  </div>
                  <p className="description">{t("participant.user-metrics.joined_courses")}</p>
                </div>

                <div className="metric_card">
                  <div className="metric_points">
                    <p>{profilePoints?.user_scores?.notions_xp}</p>
                    <p>xp</p>
                  </div>
                  <p className="description">{t("participant.user-metrics.notions_seen")}</p>
                </div>

                <div className="metric_card">
                  <div className="metric_points">
                    <p>{profilePoints?.user_scores?.quizzes_xp}</p>
                    <p>xp</p>
                  </div>
                  <p className="description">Quizzes</p>
                </div>

                <div className="metric_card">
                  <div className="metric_points">
                    <p>{profilePoints?.user_scores?.evaluations_xp}</p>
                    <p>xp</p>
                  </div>
                  <p className="description">{t("participant.user-metrics.course_evaluations")}</p>
                </div>

                <div className="metric_card">
                  <div className="metric_points">
                    <p>{profilePoints?.user_scores?.discussions_xp}</p>
                    <p>xp</p>
                  </div>
                  <p className="description">{t("participant.user-metrics.course_disc")}</p>
                </div>


                <div className="metric_card">
                  <div className="metric_points">
                    {/* <p>{profilePoints?.user_scores?.businessgame_xp}</p> */}
                    <p>0</p>
                    <p>xp</p>
                  </div>
                  <p className="description">serious games</p>
                </div>
                {/* <div className="metric_card">
              <div className="metric_points">
                <p>{profilePoints?.user_scores?.number_of_courses}</p>
                <p> </p>
              </div>
              <p className="description">number of courses</p>
            </div> */}
                {/* <div className="metric_card">
              <div className="metric_points">
                <p>{profilePoints?.user_scores?.number_of_questions}</p>
                <p></p>
              </div>
              <p className="description">number of questions</p>
            </div> */}
                {/* <div className="metric_card mr-2">
              <div className="metric_points">
                <p>{profilePoints?.user_scores?.number_of_quizzes}</p>
                <p></p>
              </div>
              <p className="description">number of quizzes</p>
            </div> */}
              </div>

              <div className="slider_action action_next" onClick={goRight} >
                <img alt={""} src={nextIcon} width={20} height={20} />
              </div>

            </div>
          </div>

          <div className="community_progress" style={{ margin: 16 }}>
            <div className="header">
              <div className="title">
                <h1>{t("participant.user-metrics.community progress")}</h1>
              </div>
              <div className="metrics_titles">
                <div className="metric_title">{t("participant.user-metrics.nb_course")}</div>
                <div className="metric_title">{t("participant.user-metrics.nb_quizzz")}</div>
                <div className="metric_title">{t("participant.user-metrics.nb_questions")}</div>
              </div>
            </div>
            <div className="body">
              <div className="users_progress_wrapper">
                <div className="user_progress_row">
                  <div className="progress">
                    <p className="user_rank">1</p>
                    <img src={current_user?.avatar_path || 'https://i.pravatar.cc/64'} alt="user profile" />
                    <div className="user_score_progress">
                      <h1>{current_user?.full_name}</h1>
                      <div className="NE_score_gage">
                        {/*                                    <p>0</p>*/}
                        <div className="progress_bar">
                          <div className="progress_level" style={{ width: `${score}` * 1.53 }} />
                          <div className="progress_points_wrapper">
                            <div className="progress_points">
                              {/*                                                <div class="progress_point progress_point_small" style="margin-left: 242px;">*/}
                              {/*                                                    130*/}
                              {/*                                                </div>*/}
                              <div className="progress_point" style={{ marginLeft: `calc(${score} * 0.31% - 22px)` }}>
                                {score}
                              </div>
                              {/*                                                <div class="progress_point next_level_progress_point">*/}
                              {/*                                                    300*/}
                              {/*                                                </div>*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="metrics">
                    <div className="metric">{profilePoints?.user_scores?.number_of_courses}</div>
                    <div className="metric">{profilePoints?.user_scores?.number_of_quizzes}</div>
                    <div className="metric">{profilePoints?.user_scores?.number_of_questions}</div>
                  </div>
                </div>
                {sorted_user_community?.map((user, key) => {
                  return (
                    <div className="user_progress_row" key={key}>
                      <div className="progress">
                        <p className="user_rank">{key + 2}</p>
                        <img src={"https://i.pravatar.cc/64" || user.avatar_path} alt="user profile" />
                        <div className="user_score_progress">
                          <h1>{user.full_name}</h1>
                          <div className="NE_score_gage">
                            {/*                                    <p>0</p>*/}
                            <div className="progress_bar">
                              <div className="progress_level" style={{ width: `${user.total_score}` * 1.53 }} />
                              <div className="progress_points_wrapper">
                                <div className="progress_points">
                                  {/*                                                <div class="progress_point progress_point_small" style="margin-left: 242px;">*/}
                                  {/*                                                    130*/}
                                  {/*                                                </div>*/}
                                  <div className="progress_point" style={{ marginLeft: `calc(${user.total_score} * 0.31% - 22px)` }}>
                                    {user.total_score}
                                  </div>
                                  {/*                                                <div class="progress_point next_level_progress_point">*/}
                                  {/*                                                    300*/}
                                  {/*                                                </div>*/}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="metrics">
                        <div className="metric">{user.number_of_courses}</div>
                        <div className="metric">{user.number_of_quizzes}</div>
                        <div className="metric">{user.number_of_questions}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </>
      }
    </Translation>
  );
};

export default ProfilePoints;
