import React, {Component} from "react";
import './index.css'
import IconLens from './assets/Icon-Lens.svg'

class IconInput extends Component{
    render() {
        const {label,message,onChange = ()=>null,isValid,id,...rest} = this.props;

        let class_name = "NE_icon_input ";
        class_name+= isValid === undefined ? "" : isValid ? "NE_icon_input_success" : "NE_icon_input_error";

        return (
            <div className={class_name}>
                {label && <label htmlFor={id} >{label}</label>}
                <div className="input_wrapper">
                  <form autoComplete="off">
                    <input id={id} onChange={(e) => {onChange(e.target.value);}} {...rest}  />
                  </form>
                    <img src={IconLens} alt="Input icon"/>
                </div>
                <p>{message}</p>
            </div>
        );
    }
}
export default IconInput
