import React, { Component } from "react";
import { connect } from "react-redux";
import './index.css'
import logo from '../images/logo.png';
import NormalInput from "../../components/NormalInput";
import NormalButton from "../../components/NormalButton";
import { changeResetValues, forgetPassword } from "../../redux/reset/action-creators";
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import Alert from "../../components/Alert";
import background from "../images/background1.jpeg";
import background2 from "../images/background2.jpeg";
import background3 from "../images/background3.jpeg";
import background4 from "../images/background4.jpeg";
import background5 from "../images/background5.jpeg";
import background6 from "../images/background6.jpeg";
import background7 from "../images/background7.png";

const imagesBackground = [background, background2, background3, background4, background5, background6, background7]

class Reset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.image = imagesBackground[Math.floor(Math.random() * imagesBackground.length)];

    }

    handleValidSubmit() {
        this.props.forgetPassword({ 'email': this.props.reset.email });
    };


    componentDidMount() {

        this.setState(prevState => {
            return {
                image: imagesBackground[Math.floor(Math.random() * imagesBackground.length)]
            };
        });
        const intervalId = setInterval(() => {
            this.setState(prevState => {
                return {
                    image: imagesBackground[Math.floor(Math.random() * imagesBackground.length)]
                };
            });

        }, 20000);

        this.setState({ intervalId: intervalId });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    render() {
        //Get action creators from props
        const { changeResetValues } = this.props;
        //get Varibale from props
        const { email, alerts } = this.props.reset;
        return (
            <Translation>
                {t =>
                    <>
                        <div className="Reset_split Reset_left">
                            <div className="alerts_container">
                                {alerts?.map(alert => {
                                    return <Alert key={alert.id} type={alert.type} destination={'login'}
                                        message={alert.message} onClose={() => {
                                        }} />
                                })}
                            </div>
                            <div className="Reset_centered">
                                <div className="Reset_NE_contain">
                                    <img className="Reset_NE_header_logo" src={logo} alt="Logo" />
                                    <div>
                                        <h3 className="Reset_NE_header_">{t("reset.title")}</h3>
                                        <p className="Reset_NE_paragraph">{t("reset.fields.dont_have_account_text")}</p>
                                        <p className="Reset_NE_paragraph_underlined">
                                            <Link to="/signup">{t("reset.fields.sign_up_text")}</Link>
                                        </p>
                                        <div className="Reset_NE_label">
                                            <NormalInput isValid={true} message={"test"} label={"Email"}
                                                placeholder={t("reset.fields.email_placeholder")} name='email'
                                                value={email}
                                                onChange={(event) => {
                                                    changeResetValues("email", event.target.value)
                                                }} />
                                        </div>
                                        <div className="Reset_NE_label_login">
                                            <NormalButton button='dark' value='Reset'
                                                onClick={this.handleValidSubmit} />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="Reset_split Reset_right" style={{
                            backgroundImage: `url(${this?.state?.image})`
                        }}>
                            <div className="Reset_centered">

                            </div>
                        </div>
                    </>
                }
            </Translation>
        );
    }
}

const mapStateToProps = (state) => {
    const { reset } = state;
    return { reset };
};

const mapActionsToProps = {
    forgetPassword,
    changeResetValues
};
// export default Login;
export default connect(mapStateToProps, mapActionsToProps)(Reset);

